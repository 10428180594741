import { Label, tr } from '@/i18n'
import { useMatchRoute } from '@tanstack/react-router'

// Components
import { CustomLink } from '@/components/CustomLink'
import { SidebarSettings } from '@/components/SidebarSettings'
import { Avatar, AvatarFallback } from '@/components/base/avatar'
import { Icon, type IconTagName } from '@/components/common/icons/Icon'

// Types
import type { CustomLinkProps } from '@/components/CustomLink'
import type { PropsWithChildren } from 'react'
import { Feature } from './Feature'
import { FEATURES } from '@/stores/features'

type NavItemProps = PropsWithChildren<
  CustomLinkProps & {
    tag: IconTagName
  }
>

const NavItem = ({ children, tag, to = '/recent' }: NavItemProps) => {
  return (
    <CustomLink
      className="mb-4 mt-3 flex items-center justify-center gap-y-1 px-2 text-xs font-semibold tracking-wide"
      to={to}
    >
      <div className="group-[.active]:gradient rounded-3xl px-5 py-2">
        <Icon tag={tag} size="xl" variant="neutral" />
      </div>
      {children}
    </CustomLink>
  )
}

export const Sidebar = () => {
  // TODO: hide sidebar when in a room, but maybe decide on a better way to do this
  const matchRoute = useMatchRoute()
  const params = Boolean(matchRoute({ to: '/room/$context/$name' }))

  if (params) {
    return null
  }

  return (
    <div className="z-60 flex border-r border-ring bg-primary">
      <nav className="flex flex-col">
        <SidebarSettings>
          <div className="flex flex-col items-center justify-center gap-2 pt-3 text-neutral-light">
            <Avatar>
              <AvatarFallback className="bg-background">
                <Icon tag="sw-logo" type="light" size="2xl" />
              </AvatarFallback>
            </Avatar>
            {/* TODO: a better name? */}
            <span className="sr-only">Settings</span>
          </div>
        </SidebarSettings>
        <NavItem to="/recent" tag="history">
          {tr(Label.RECENT)}
        </NavItem>
        <NavItem to="/rooms" tag="rooms">
          {tr(Label.ROOMS)}
        </NavItem>
        <Feature name={FEATURES.CALLS_ROUTE}>
          <NavItem to="/calls" tag="call">
            {tr(Label.CALLS)}
          </NavItem>
        </Feature>
        <Feature name={FEATURES.CHATS_ROUTE}>
          <NavItem to="/chats" tag="chat">
            {tr(Label.CHATS)}
          </NavItem>
        </Feature>
        <NavItem to="/directory" tag="contacts">
          {tr(Label.DIRECTORY)}
        </NavItem>
      </nav>
    </div>
  )
}
