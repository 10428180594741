import { useRef } from 'react'
import { Button } from '@/components/base/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/base/card'
import { ImageWithSkeleton } from '@/components/common/ImageWithSkeleton'
import { Icon } from '@/components/common/icons/Icon'
import { CopyRoomLink } from '@/components/CopyRoomLink'
import { RoomLink } from '@/components/RoomLink'
import { useResourcesStoreActions } from '@/stores/resources'
import { useIsVisible } from '@/hooks/useIsVisible'
import { usePolling } from '@/hooks/usePolling'
import { cn } from '@/helpers/utils'
import type { Resource } from '@/helpers/types'
import { ENV_VARS } from '@/helpers/constants'

interface RoomCardProps {
  imgLoad?: 'eager' | 'lazy'
  onTransfer?: () => void
  room: Resource
}

export const RoomCard = (props: RoomCardProps) => {
  const { imgLoad = 'lazy', onTransfer, room } = props

  const { fetchResource } = useResourcesStoreActions()
  const roomCardRef = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(roomCardRef)
  const { data: updatedRoom } = usePolling<Resource | undefined>({
    initialData: room,
    pollingFn: () => fetchResource({ id: room.id }),
    shouldPoll: isVisible,
  })

  const {
    channels: { video: roomPath },
    displayName,
    previewUrl,
    name,
    locked,
  } = updatedRoom ?? room

  // const hasDescription = 'description' in room && trim(room.description)
  const hasTransfer = Boolean(onTransfer)

  return (
    <Card className="border-border bg-neutral-light" ref={roomCardRef}>
      <CardHeader className="flex flex-row py-3 pl-6 pr-3">
        <div className="flex-grow">
          <CardTitle className="line-clamp-1 text-base font-medium tracking-normal text-primary">
            {displayName}
          </CardTitle>
          {/* TODO: actual number of participants, remember to use i18n for plural label */}
          <CardDescription className="font-montserrat text-sm font-normal tracking-wide text-primary">
            0 Participants
          </CardDescription>
        </div>
        <div className="flex items-center justify-center pl-2 text-primary">
          <Button size="icon" variant="icon" disabled>
            <span className="sr-only">Favorite</span>
            <Icon size="xl" tag="favorite-border" variant="foreground" />
          </Button>
          {roomPath && (
            <CopyRoomLink
              address={roomPath}
              clickedContent="Copied!"
              initialContent="Copy Room Link"
              redirect="/rooms"
            />
          )}
        </div>
      </CardHeader>
      <CardContent className="relative p-0 pb-3">
        <ImageWithSkeleton
          alt={displayName}
          className="pb-1"
          // TODO: needs lazy loading only when outside of viewport
          loading={imgLoad}
          fallbackSrc={`${ENV_VARS.VITE_ROOM_PROMO_URL}?fqdn=hq.sw.work&room=${name}`}
          src={previewUrl}
        />
        {/* TODO replace with description of room when available from API */}
        {/* {hasDescription ? (
          <p className="line-clamp-3 text-pretty px-4 pt-3 font-montserrat text-sm font-normal tracking-wide">
            {room.description}
          </p>
        ) : null} */}
        {locked && (
          <span className="absolute right-2 top-2 rounded-full bg-negative-light p-1">
            <Icon
              size="lg"
              tag="lock"
              variant="neutral"
              aria-label="Room locked"
              aria-hidden={false}
            />
          </span>
        )}
      </CardContent>
      <CardFooter
        className={cn('flex space-x-6 px-3 pb-3', { 'justify-end': true })}
      >
        {hasTransfer ? (
          <Button variant="link" onClick={onTransfer}>
            Transfer
          </Button>
        ) : null}
        {roomPath && (
          <RoomLink
            className={cn('w-full', { 'max-w-36': hasTransfer })}
            address={roomPath}
            disabled={locked}
          >
            <Button variant="gradient" disabled={locked}>
              Join
            </Button>
          </RoomLink>
        )}
      </CardFooter>
    </Card>
  )
}
