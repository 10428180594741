import { useCapabilitiesStore } from '@/stores/capabilities'
import { FEATURES, useFeaturesStoreActions } from '@/stores/features'
import { useUserStore } from '@/stores/user'

/**
 * returns whether the corresponding button should be visible and enabled
 * - button `visible` if one of the `capabilities` is enabled and the FF is enabled
 * - button `enabled` if the current state matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useScreenShareButtonState = () => {
  const isMobile = useUserStore(state => state.isMobile)
  const hasScreenShareCapability = useCapabilitiesStore(
    state => state.hasScreenShareCapability,
  )
  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  // button visible if one of the capabilities is enabled and FF is enabled
  const isScreenShareButtonVisible =
    !getFeatureFlagIsDisabled(FEATURES.ROOM_SHARE_SCREEN) &&
    !isMobile &&
    hasScreenShareCapability

  // NOTE: screen share is special, in that it only depends on the capability
  const isScreenShareButtonEnabled = hasScreenShareCapability

  return { isScreenShareButtonEnabled, isScreenShareButtonVisible }
}
