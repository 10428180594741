import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonShareScreenProps = OmittedProps

const ButtonShareScreen = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonShareScreenProps) => {
  const sharingScreen = useRoomStore(state => state.sharingScreen)
  const { screenShareHandler } = useRoomStoreActions()

  return (
    <ButtonRoomControl
      disabled={disabled}
      isActive={sharingScreen}
      isVisible={isVisible}
      onClick={screenShareHandler}
      // TODO: add disabled tag if needed
      tag={disabled ? 'screen-share' : 'screen-share'}
      {...props}
    >
      Share Screen
    </ButtonRoomControl>
  )
}

export { ButtonShareScreen }
