import { ButtonMicrophone } from '@/components/ButtonMicrophone'
import { ButtonVideo } from '@/components/ButtonVideo'
import { LinkLeave } from '@/components/LinkLeave'
import { TEST_IDS } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import { logger } from '@/logger/createLogger'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

interface AudioCallControlsProps {
  redirect?: RedirectPath | undefined
}

export const AudioCallControls = ({ redirect }: AudioCallControlsProps) => {
  const audioMuted = useRoomStore(state => state.audioMuted)
  const memberState = useRoomStore(state => state.memberState)

  const { audioMuteHandler } = useRoomStoreActions()

  // TODO: Implement switch to video
  const handleSwitchToVideo = () => {
    logger.info('Switch to video')
  }

  const memberJoined = memberState === 'joined'

  return (
    <menu
      aria-label="Room toolbar"
      className="flex flex-col"
      data-testid={TEST_IDS.ROOM_CONTROLS}
      role="toolbar"
    >
      <li>
        <ul
          aria-label="Audio and video controls"
          className="flex gap-2 p-2.5"
          role="group"
        >
          <li className="flex min-w-[170px] items-center justify-center pb-4 pt-3">
            <ButtonMicrophone
              disabled={!memberJoined}
              isActive={!audioMuted}
              onClick={audioMuteHandler}
            />
          </li>
          <li className="flex min-w-[170px] items-center justify-center pb-4 pt-3">
            <ButtonVideo
              // TODO: Implement switch to video and update active, disabled props accordingly
              disabled
              isActive={false}
              onClick={handleSwitchToVideo}
            />
          </li>
        </ul>
      </li>
      <li className="flex items-center justify-center py-20">
        <LinkLeave to={redirect} />
      </li>
    </menu>
  )
}
