import { ENV_VARS } from '@/helpers/constants'
import type { ViteMode } from '@/helpers/types'
import type { LogLevelValue } from '@/logger/loggerConstants'
import { LOG_LEVEL_TO_VALUE_MAP } from '@/logger/loggerConstants'
import type { TransportSettings } from '@/logger/transports'
import { Logger } from 'tslog'

type ConfigRecord<K extends string> = Record<K, TransportSettings>
type LoggerConfigOptions<K extends string> = Record<ViteMode, ConfigRecord<K>>

type LoggerConfigConsole = LoggerConfigOptions<'console'>

interface LoggerMeta {
  browser: string
  date: string
  logLevelId: LogLevelValue
  logLevelName: string
  name: LoggerName
  runtime: string
}
// NOTE: these types may not be guaranteed depending on environment
interface LogObj {
  _meta: LoggerMeta
  error: Error
  message: string
}

const LOGGER_NAMES = {
  DEFAULT: 'default-logger',
} as const
type LoggerName = (typeof LOGGER_NAMES)[keyof typeof LOGGER_NAMES]

/* eslint-disable sort-keys */
const createLogger = (viteMode: ViteMode) => {
  const loggerConfig = {
    development: {
      console: { minLogLevel: LOG_LEVEL_TO_VALUE_MAP.DEBUG },
    },
    testing: {
      console: { minLogLevel: LOG_LEVEL_TO_VALUE_MAP.DEBUG },
    },
    staging: {
      console: { minLogLevel: LOG_LEVEL_TO_VALUE_MAP.DEBUG },
    },
    production: {
      console: { minLogLevel: LOG_LEVEL_TO_VALUE_MAP.INFO },
    },
  } as const satisfies LoggerConfigConsole
  /* eslint-enable sort-keys */

  const loggerConfigForEnv = loggerConfig[viteMode]

  // create a logger instance to be used in the app
  return new Logger<LogObj>({
    hideLogPositionForProduction: viteMode === 'production',
    minLevel: loggerConfigForEnv.console.minLogLevel,
    name: LOGGER_NAMES.DEFAULT,
  })
}

// create a logger instance to be used in the app
const logger = createLogger(ENV_VARS.VITE_MODE)
export { logger }

export type { LoggerConfigOptions, LogObj }
