import type { PropsWithChildren } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/base/avatar'
import { CustomLink } from './CustomLink'
import type { CustomLinkProps } from './CustomLink'
import { Button } from '@/components/base/button'
import { RoomLink } from '@/components/RoomLink'
import { useResourcesStoreActions } from '@/stores/resources'
import { useUiStore, useUiStoreActions } from '@/stores/ui'
import { useRoomStore } from '@/stores/room'
import type { IconTagName } from './common/icons/Icon'
import { Icon } from './common/icons/Icon'
import { cn } from '@/helpers/utils'

type ResourceItemProps = PropsWithChildren<
  CustomLinkProps & {
    path?: string
    tag: IconTagName
  }
>

const ResourceItem = ({
  children,
  disabled = false,
  params = {},
  tag,
  path,
  ...props
}: ResourceItemProps) => {
  const classNameWrapper = cn(`
      flex flex-col items-center justify-center gap-2
      group rounded-[.625rem] bg-background
      px-2 py-1.5 text-sm text-primary-light hover:bg-neutral-light
      aria-disabled:bg-neutral-light aria-disabled:text-ring
    `)

  const iconAndChildren = (
    <>
      <Icon
        className={cn('fill-primary-light group-aria-disabled:fill-ring')}
        size="xl"
        tag={tag}
      />
      <span className="w-12 text-center">{children}</span>
    </>
  )

  let itemToRender: JSX.Element | undefined
  if (props.to === '/room/$context/$name' && path) {
    itemToRender = (
      <RoomLink className={classNameWrapper} address={path} disabled={disabled}>
        {iconAndChildren}
      </RoomLink>
    )
  } else {
    itemToRender = (
      <CustomLink
        className={classNameWrapper}
        params={params}
        disabled={disabled}
        {...props}
      >
        {iconAndChildren}
      </CustomLink>
    )
  }

  return <li>{itemToRender}</li>
}

interface ContactProps {
  onClose?: () => void
  resourceId: string
}

export const Contact = ({ onClose, resourceId }: ContactProps) => {
  const displayContact = useUiStore(state => state.displayContact)
  const memberState = useRoomStore(state => state.memberState)
  const { hideContact } = useUiStoreActions()
  const { getResourceById } = useResourcesStoreActions()
  const resource = getResourceById(resourceId)

  if (!displayContact) {
    return null
  }

  if (!resourceId) {
    return <div>Choose a resource</div>
  }

  if (!resource) {
    return <div>Resource not found</div>
  }

  const { channels, displayName, locked, type } = resource
  // TODO: use first and last name from resource when available
  const [first, ...rest] = displayName.split(' ')
  const firstLastInitials = `${first?.charAt(0) ?? ''}${rest?.at(-1)?.charAt(0) ?? ''}`
  const isJoinDisabled =
    memberState === 'joining' || memberState === 'joined' || locked

  const handleClose = () => {
    hideContact()
    onClose?.()
  }

  return (
    <div
      className="flex w-full flex-col items-center justify-center px-6 pt-2.5"
      role="group"
      aria-label={`Contact card for ${displayName}`}
    >
      <Button
        className="my-1 self-end p-2"
        onClick={handleClose}
        variant="icon"
      >
        <Icon size="xl" tag="close" variant="foreground" />
      </Button>
      <section className="flex flex-col items-center justify-center gap-y-2 pb-3">
        <Avatar className="mb-1 h-[6.25rem] w-[6.25rem]">
          <AvatarImage src={resource.coverUrl} alt={displayName} />
          <AvatarFallback className="bg-negative-light text-3xl font-semibold uppercase">
            {firstLastInitials}
          </AvatarFallback>
        </Avatar>
        <div className="flex items-center gap-1">
          <h2
            className="line-clamp-1 text-center text-3xl font-semibold"
            title={displayName}
          >
            {displayName}
          </h2>
          {locked && (
            <Icon
              size="xl"
              tag="lock"
              aria-label="Room locked"
              aria-hidden={false}
            />
          )}
        </div>
        <h3 className="text-center text-sm capitalize text-secondary">
          {type}
        </h3>
      </section>
      <ul aria-label="Contact Actions" className="flex gap-x-2 pb-8">
        <ResourceItem
          to="/room/$context/$name"
          path={channels.audio || ''}
          disabled={!channels.audio || isJoinDisabled}
          tag="phone-callback"
        >
          Audio
        </ResourceItem>
        <ResourceItem
          to="/room/$context/$name"
          path={channels.video || ''}
          disabled={!channels.video || isJoinDisabled}
          tag="camera-alt"
        >
          Video
        </ResourceItem>
        <ResourceItem disabled tag="sofa-outline-1">
          Room
        </ResourceItem>
        <ResourceItem disabled tag="chat-bubble-outline">
          Chat
        </ResourceItem>
        <ResourceItem disabled tag="favorite-border">
          Favorite
        </ResourceItem>
      </ul>
    </div>
  )
}
