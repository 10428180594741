import type {
  ChannelType,
  DeviceErrorType,
  EnumLikeRecord,
  ErrorToMessageRecord,
  ErrorType,
  LoadingState,
  RedirectPaths,
  SortBy,
  SortOrder,
  ToastType,
  ViteMode,
} from '@/helpers/types'
import type { ResourceType } from '@signalwire/js'

// ROOT_ELEMENT_ID must match the ID in /index.html for dev
export const ROOT_ELEMENT_ID = 'pucRoot' as const
export const ROOT_ELEMENT_ID_MCU = 'mcuContentWrapper' as const

export const AUDIO_MAX_VOLUME = 50 as const
export const AUDIO_MIN_VOLUME = -50 as const
export const DEFAULT_VIDEO_CONSTRAINTS = {
  aspectRatio: 16 / 9,
  height: { ideal: 720 },
  width: { ideal: 1280 },
} as const
export const RESIZE_DEBOUNCE_MS = 100 as const
export const TIMESTAMP_MULTIPLIER = 1000 as const

export const MIN_LOADING_INTERVAL_MS = 300 as const

// Environment Variables
const PKG_VERSION = process.env['PKG_VERSION']
const IS_DEVELOPMENT =
  import.meta.env.DEV ?? process.env['NODE_ENV'] === 'development'
const IS_PRODUCTION =
  import.meta.env.PROD ?? process.env['NODE_ENV'] === 'production'

const VITE_DISABLED_FEATURES = import.meta.env.VITE_DISABLED_FEATURES ?? ''
const VITE_MODE = import.meta.env.MODE as ViteMode
const VITE_OAUTH_AUTH_URI = import.meta.env.VITE_OAUTH_AUTH_URI ?? ''
const VITE_OAUTH_CLIENT_ID = import.meta.env.VITE_OAUTH_CLIENT_ID ?? ''
const VITE_OAUTH_TOKEN_URI = import.meta.env.VITE_OAUTH_TOKEN_URI ?? ''
const VITE_RELAY_HOST = import.meta.env.VITE_RELAY_HOST ?? ''
const VITE_ROOM_PROMO_URL = import.meta.env.VITE_ROOM_PROMO_URL ?? ''
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN ?? ''
const DEFAULT_NAV_ROUTE = import.meta.env.DEFAULT_NAV_ROUTE ?? '/'

export const ENV_VARS = {
  DEFAULT_NAV_ROUTE,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  PKG_VERSION,
  VITE_DISABLED_FEATURES,
  VITE_MODE,
  VITE_OAUTH_AUTH_URI,
  VITE_OAUTH_CLIENT_ID,
  VITE_OAUTH_TOKEN_URI,
  VITE_RELAY_HOST,
  VITE_ROOM_PROMO_URL,
  VITE_SENTRY_DSN,
} as const

export const LOADING_STATE = {
  ERROR: 'error',
  IDLE: 'idle',
  LOADING: 'loading',
} as const satisfies EnumLikeRecord<LoadingState>

export const SORT_BY = {
  CREATED_AT: 'created_at',
  NAME: 'name',
} as const satisfies EnumLikeRecord<SortBy>

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
} as const satisfies EnumLikeRecord<SortOrder>

export const CHANNEL_TYPE = {
  AUDIO: 'audio',
  VIDEO: 'video',
} as const satisfies EnumLikeRecord<ChannelType>

export const RESOURCE_TYPE = {
  APP: 'app',
  CALL: 'call',
  ROOM: 'room',
  SUBSCRIBER: 'subscriber',
} as const satisfies EnumLikeRecord<ResourceType>

export const ERROR_TYPE = {
  NOT_FOUND: 'not_found',
  UNKNOWN: 'unknown',
} as const satisfies EnumLikeRecord<ErrorType>

export const DEVICE_ERROR_TYPE = {
  DENIED: 'denied',
  NOT_FOUND: 'not_found',
  UNKNOWN: 'unknown',
} as const satisfies EnumLikeRecord<DeviceErrorType>

/* eslint-disable camelcase */
export const ERROR_TO_MESSAGE = {
  not_found: {
    message: 'Please ensure that the page exists and the URL is correct.',
    status: '404',
    title: 'Page not found',
  },
  unknown: {
    message: 'An unknown error occurred. Please try again.',
    status: '',
    title: 'Unknown error',
  },
} as const satisfies ErrorToMessageRecord
/* eslint-enable camelcase */

// The following are used in the chat messages (user colors)

export const USER_COLOR = {
  BREEZE: 'bg-breeze',
  EMERALD: 'bg-warning',
  GREEN: 'bg-positive',
  MIST: 'bg-mist',
  PINK: 'bg-negative-light',
  PURPLE: 'bg-sw-purple',
  RED: 'bg-destructive',
  YELLOW: 'bg-warning',
} as const

export const CURRENT_USER_COLOR = USER_COLOR.MIST

export const OTHER_USER_COLORS = [
  USER_COLOR.BREEZE,
  USER_COLOR.EMERALD,
  USER_COLOR.GREEN,
  USER_COLOR.PINK,
  USER_COLOR.PURPLE,
  USER_COLOR.RED,
  USER_COLOR.YELLOW,
] as const

// TODO: use this for the localstorage names in auth, chat, roomSession, and userSettings
export const LOCAL_STORAGE_KEY = {
  SW_PUC_ATTACHED: 'sw_puc_attached',
  SW_PUC_SAT: 'sw_puc_sat',
  SW_PUC_USER_SETTINGS: 'sw_puc_user_settings',
} as const

// The SDK doesn't have an API to force a de-attach
// To support it in the app we need to clean up the SDK session attached state
export const SDK_ATTACHED_SESSION_KEYS = ['ci-SAT', 'pt-SAT', 'as-SAT']

export const PSTN_PREFIX = '+'
export const SIP_PREFIX = 'sip'
export const DEFAULT_CONTEXT = 'private'
export const INCOMING_CALL_CONTEXT = 'incoming'

// For the toast messages
export const ERROR_TOAST_LIMIT = 2
export const TOAST_REMOVE_DELAY_MS = 3000

export const TOAST_TYPE = {
  ERROR: 'ERROR',
  INCOMING_CALL: 'INCOMING_CALL',
} as const satisfies EnumLikeRecord<ToastType>

// for test ids when needed
export const TEST_IDS = {
  DEVELOPER_TOOLS: 'developer-tools',
  ROOM_CHAT_PANEL: 'room-chat-panel',
  ROOM_CHAT_SENTINEL: 'room-chat-sentinel',
  ROOM_CONTROLS: 'room-controls',
  ROOM_HEADER: 'room-header',
  ROOM_HEADER_CONTROLS: 'room-header-controls',
  ROOM_NAME: 'room-name',
  ROOM_PANEL: 'room-panel',
  ROOM_PANEL_LAYOUT: 'room-panel-layout',
  ROOM_SETTINGS_PANEL: 'room-settings-panel',
  SIDEBAR_TRIGGER: 'sidebar-trigger',
} as const satisfies Record<
  Uppercase<`${string}_${string}`>,
  `${string}-${string}`
>

// valid redirect paths for routing
export const REDIRECT_PATHS = {
  BASE: '/',
  CALLS: '/calls',
  CHATS: '/chats',
  DIRECTORY: '/directory',
  RECENT: '/recent',
  ROOMS: '/rooms',
} as const satisfies RedirectPaths

// error messages for device permissions

export const DEVICE_ERROR_MESSAGE_MAP = {
  denied: {
    description: 'Please allow access to your camera and or microphone.',
    instructions:
      'Open your browser settings and allow access to camera and microphone.',
    title: 'Permissions Required',
  },
  // eslint-disable-next-line camelcase
  not_found: {
    description:
      "We couldn't detect a camera or microphone on your device. Please ensure your devices are properly connected.",
    instructions: 'Try connecting a camera or microphone and refresh the page.',
    title: 'No Camera or Microphone Detected',
  },
  unknown: {
    description: 'There was an error connecting to your camera and microphone.',
    instructions: 'Please refresh the page and try again.',
    title: 'Connection Error',
  },
} as const satisfies Record<
  DeviceErrorType,
  { description: string; instructions: string; title: string }
>

// Application Breakpoints
export const BREAKPOINTS = {
  MEDIUM: 768,
  SMALL: 360,
}
