import { useCapabilitiesStore } from '@/stores/capabilities'
import { FEATURES, useFeaturesStoreActions } from '@/stores/features'

/**
 * returns whether the corresponding button should be visible and enabled
 * based on the capabilities, feature flag, and the current state
 * - button `visible` if one of the `capabilities` is enabled and the FF is enabled
 * - button `enabled` if the current state matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useDNDButtonState = () => {
  // FIXME: get the current state from the store once DND is implemented
  const dndActive = false
  const hasDNDCapabilityOff = useCapabilitiesStore(
    state => state.hasDNDCapabilityOff,
  )
  const hasDNDCapabilityOn = useCapabilitiesStore(
    state => state.hasDNDCapabilityOn,
  )
  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  // button visible if one of the capabilities is enabled and FF is enabled
  const isDNDButtonVisible =
    !getFeatureFlagIsDisabled(FEATURES.ROOM_DO_NOT_DISTURB) &&
    (hasDNDCapabilityOn || hasDNDCapabilityOff)

  const canEnterDND = !dndActive && hasDNDCapabilityOn
  const canExitDND = dndActive && hasDNDCapabilityOff

  // check if button can perform the necessary next action
  const isDNDButtonEnabled = canEnterDND || canExitDND

  return { isDNDButtonEnabled, isDNDButtonVisible }
}
