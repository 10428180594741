import { Icon } from '@/components/common/icons/Icon'
import { REDIRECT_PATHS } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import { cn } from '@/helpers/utils'
import { useRouter } from '@tanstack/react-router'
import type { PropsWithChildren } from 'react'
import { Button } from './base/button'

type LinkLeaveProps = PropsWithChildren<{
  className?: string
  to?: RedirectPath | undefined
}>

export const LinkLeave = ({
  className,
  to = REDIRECT_PATHS.RECENT,
}: LinkLeaveProps) => {
  const { navigate } = useRouter()
  const leaveHandler = async () => {
    await navigate({ to })
  }

  return (
    // CSS delay adds hover intent
    <Button
      className={cn(
        'm-0 flex h-20 w-20 shrink-0 flex-col items-center justify-center gap-y-1 rounded-full bg-background p-0 text-destructive transition-colors hover:bg-destructive hover:text-background hover:delay-button',
        className,
      )}
      onClick={leaveHandler}
      // aria attributes required for "link" if not using <a> tag
      tabIndex={0}
      role="link"
    >
      <Icon size="xl" tag="phone-disabled" />
      <span className="text-xs">Leave</span>
    </Button>
  )
}
