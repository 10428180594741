import type { CallCapabilities } from '@/helpers/types'
import type { StateCreator } from 'zustand'
import { create } from 'zustand'

interface Actions {
  actions: {
    setCapabilities: (capabilities: CallCapabilities) => void
  }
}

interface CapabilitiesState {
  hasDNDCapabilityOff: boolean
  hasDNDCapabilityOn: boolean
  hasHandRaiseCapabilityOff: boolean
  hasHandRaiseCapabilityOn: boolean
  hasMicrophoneCapabilityOff: boolean
  hasMicrophoneCapabilityOn: boolean
  hasRoomLockCapabilityOff: boolean
  hasRoomLockCapabilityOn: boolean
  hasScreenShareCapability: boolean
  hasVideoCapabilityOff: boolean
  hasVideoCapabilityOn: boolean
}

interface State extends CapabilitiesState {
  capabilities: CallCapabilities | undefined
}

type Store = Actions & State

const initialState: State = {
  capabilities: undefined,
  hasDNDCapabilityOff: false,
  hasDNDCapabilityOn: false,
  hasHandRaiseCapabilityOff: false,
  hasHandRaiseCapabilityOn: false,
  hasMicrophoneCapabilityOff: false,
  hasMicrophoneCapabilityOn: false,
  hasRoomLockCapabilityOff: false,
  hasRoomLockCapabilityOn: false,
  hasScreenShareCapability: false,
  hasVideoCapabilityOff: false,
  hasVideoCapabilityOn: false,
}

const mapCapabilitiesToState = (capabilities: CallCapabilities) => {
  const { lock, self, screenshare } = capabilities

  return {
    // TODO: remove unnecessary Boolean() calls once types are updated
    hasDNDCapabilityOff: Boolean(self?.deaf?.off),
    hasDNDCapabilityOn: Boolean(self?.deaf?.on),
    hasHandRaiseCapabilityOff: Boolean(self?.raisehand?.off),
    hasHandRaiseCapabilityOn: Boolean(self?.raisehand?.on),
    hasMicrophoneCapabilityOff: Boolean(self?.muteAudio?.off),
    hasMicrophoneCapabilityOn: Boolean(self?.muteAudio?.on),
    hasRoomLockCapabilityOff: Boolean(lock?.off),
    hasRoomLockCapabilityOn: Boolean(lock?.on),
    hasScreenShareCapability: Boolean(screenshare),
    hasVideoCapabilityOff: Boolean(self?.muteVideo?.off),
    hasVideoCapabilityOn: Boolean(self?.muteVideo?.on),
  } satisfies CapabilitiesState
}

const stateCreatorFn: StateCreator<Store> = (set, _get) => ({
  ...initialState,
  actions: {
    setCapabilities: (capabilities: CallCapabilities) => {
      // TODO: do we need to save all the capabilities? or just the ones we use in the app?
      set({
        capabilities,
        ...mapCapabilitiesToState(capabilities),
      })
    },
  },
})

export const useCapabilitiesStore = create<Store>()(stateCreatorFn)
export const useCapabilitiesStoreActions = () =>
  useCapabilitiesStore.getState().actions

// Expose the store to be used from the console
window.__capabilitiesStore = useCapabilitiesStore
