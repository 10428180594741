import { useCapabilitiesStore } from '@/stores/capabilities'
import { useRoomStore } from '@/stores/room'

/**
 * returns whether the button should be visible and enabled
 * based on the capabilities and the current state
 * - button `visible` if one of the `capabilities` is enabled
 * - button `enabled` if the current matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useMicrophoneButtonState = () => {
  const audioMuted = useRoomStore(state => state.audioMuted)
  const hasMicrophoneCapabilityOff = useCapabilitiesStore(
    state => state.hasMicrophoneCapabilityOff,
  )
  const hasMicrophoneCapabilityOn = useCapabilitiesStore(
    state => state.hasMicrophoneCapabilityOn,
  )

  // button visible if one of the capabilities is enabled
  const isMicrophoneButtonVisible =
    hasMicrophoneCapabilityOn || hasMicrophoneCapabilityOff

  // muted and can unmute
  const canUnmute = audioMuted && hasMicrophoneCapabilityOn

  // unmuted and can mute
  const canMute = !audioMuted && hasMicrophoneCapabilityOff

  // check if button can perform the necessary next action
  const isMicrophoneEnabled = canMute || canUnmute

  return {
    isMicrophoneButtonVisible,
    isMicrophoneEnabled,
  }
}
