import { ERROR_TO_MESSAGE, ERROR_TYPE } from '@/helpers/constants'

interface DefaultProps {
  error?: Error
  errorType?: (typeof ERROR_TYPE)['UNKNOWN']
}

interface PageNotFoundProps {
  error?: never
  errorType: (typeof ERROR_TYPE)['NOT_FOUND']
}

export type ErrorViewProps = DefaultProps | PageNotFoundProps

export const ErrorView = ({
  error,
  errorType = ERROR_TYPE.UNKNOWN,
}: ErrorViewProps) => {
  const { message, status, title } = ERROR_TO_MESSAGE[errorType]

  // fallback from any error object
  const titleLabel = error?.name || title
  const messageLabel = error?.message || message

  return (
    <section className="flex min-h-screen w-full items-center justify-center border bg-background px-6">
      <div className="text-center">
        {status && (
          <p className="text-2xl font-semibold text-foreground/50">{status}</p>
        )}
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground">
          {titleLabel}
        </h1>
        <p className="mt-6 leading-7 text-foreground">{messageLabel}</p>
        <div className="mt-8 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md bg-foreground px-3.5 py-2.5 text-sm font-semibold text-background shadow-sm transition-colors hover:bg-foreground/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-foreground"
          >
            Home
          </a>
        </div>
      </div>
    </section>
  )
}
