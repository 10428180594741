import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/base/dialog'
import { ButtonDnD } from '@/components/ButtonDnD'
import { ButtonMicrophone } from '@/components/ButtonMicrophone'
import { ButtonRaiseHand } from '@/components/ButtonRaiseHand'
import { ButtonShareScreen } from '@/components/ButtonShareScreen'
import { ButtonVideo } from '@/components/ButtonVideo'
import { useMicrophoneButtonState } from '@/hooks/useMicrophoneButtonState'
import { useRoomStore } from '@/stores/room'
import { useUiStore } from '@/stores/ui'
import { DialogDescription, DialogTrigger } from '@radix-ui/react-dialog'
import type { PropsWithChildren } from 'react'

type DialogInCallOptionsProps = PropsWithChildren

export const DialogInCallOptions = ({ children }: DialogInCallOptionsProps) => {
  const memberState = useRoomStore(state => state.memberState)

  const memberJoined = memberState === 'joined'

  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body

  const { isMicrophoneEnabled, isMicrophoneButtonVisible } =
    useMicrophoneButtonState()

  return (
    // TODO: should this be a modal?
    <Dialog modal={false}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        // NOTE: using ! because cn() merge does not replace the custom TW classes max-w-dialog or min-h-dialog
        className="!min-h-0 !max-w-[270px] rounded-lg border-transparent bg-primary py-20 text-background"
        container={rootElementRef}
      >
        <DialogHeader className="sr-only">
          <DialogTitle aria-label="Room toolbar">
            <span className="sr-only">In Call Options</span>
          </DialogTitle>
          {/* FIXME: there is an warning for aria-describedby if missing description */}
          <DialogDescription className="sr-only">
            Additional options for the current call
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full flex-col items-center justify-center gap-y-2.5">
          <div
            aria-label="Audio and video controls"
            className="inline-flex w-full justify-around gap-x-2.5 py-2.5"
            role="group"
          >
            <ButtonMicrophone
              disabled={!memberJoined || !isMicrophoneEnabled}
              isVisible={isMicrophoneButtonVisible}
            />
            <ButtonVideo disabled={!memberJoined} />
          </div>

          <div className="hidden py-2.5 has-[button]:block">
            <ButtonDnD disabled />
          </div>

          <div
            aria-label="Additional controls"
            className="inline-flex w-full justify-around gap-x-2.5 py-2.5"
            role="group"
          >
            <ButtonRaiseHand disabled={!memberJoined} />
            <ButtonShareScreen disabled={!memberJoined} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
