// Store for UI element state (open drawers/modals, etc.)
import { create, type StateCreator } from 'zustand'
import { WebRTC } from '@signalwire/js'
import { DEFAULT_VIDEO_CONSTRAINTS } from '@/helpers/constants'
import type { Device } from '@/helpers/types'
import { cleanDeviceList } from '@/helpers/utils'
import { logger } from '@/logger/createLogger'

export type DeviceStatus = 'disabled' | 'error' | 'loading' | 'playing'
interface Actions {
  actions: {
    getCameraStream: (
      deviceId: ConstrainDOMString,
    ) => Promise<MediaStream | undefined>
    getDeviceIdFromCameraStream: (stream: MediaStream) => string
    getSelectedCamera: () => Promise<Device | undefined>
    getSelectedMicrophone: () => Promise<Device | undefined>
    getSelectedSpeaker: () => Promise<Device | undefined>
    refreshCameraList: () => Promise<void>
    refreshMicrophoneList: () => Promise<void>
    refreshSpeakerList: () => Promise<void>
    releaseDevices: () => void
    setCameraId: (deviceId: string) => void
    setMicrophoneId: (deviceId: string) => void
    setSpeakerId: (deviceId: string) => void
    stopCameraStream: () => void
  }
}

interface State {
  cameraDisabled: boolean
  cameraId: string | undefined
  cameraIdDefault: string
  cameraList: Device[]
  cameraStatus: DeviceStatus
  cameraStream: MediaStream | undefined
  microphoneDisabled: boolean
  microphoneId: string | undefined
  microphoneIdDefault: string
  microphoneList: Device[]
  speakerDisabled: boolean
  speakerId: string | undefined
  speakerIdDefault: string
  speakerList: Device[]
}

type Store = Actions & State

const initialState: State = {
  cameraDisabled: false,
  cameraId: '',
  cameraIdDefault: '',
  cameraList: [],
  cameraStatus: 'loading',
  cameraStream: undefined,
  microphoneDisabled: false,
  microphoneId: '',
  microphoneIdDefault: '',
  microphoneList: [],
  speakerDisabled: false,
  speakerId: '',
  speakerIdDefault: '',
  speakerList: [],
}

const findSelectedDeviceById = (
  deviceId: string,
  deviceList: Device[],
): Device | undefined => {
  return deviceList.find(device => device.deviceId === deviceId)
}

const getSelectedDevice = (
  deviceId: string | undefined,
  deviceList: Device[] | undefined,
  deviceIdDefault: string | undefined = undefined,
): Device | undefined => {
  if (!deviceList?.length) {
    return undefined
  }

  let device: Device | undefined
  if (deviceId) {
    device = findSelectedDeviceById(deviceId, deviceList)
  }
  if (!device && deviceIdDefault) {
    device = findSelectedDeviceById(deviceIdDefault, deviceList)
  }
  if (!device) {
    device = undefined
  }

  return device
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    getCameraStream: async (
      deviceId: ConstrainDOMString,
    ): Promise<MediaStream | undefined> => {
      const constraints: MediaStreamConstraints = {
        video: {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          deviceId: deviceId,
        },
      }

      // Stop the current stream if any
      get().actions.stopCameraStream()

      try {
        const stream = await WebRTC.getUserMedia(constraints)
        // Preview this stream
        // status='playing' will be set by the videoElement's onCanPlay()
        set({ cameraStream: stream })
        return stream
      } catch (error) {
        set({ cameraStatus: 'error' })
        console.error('Video preview', error)
        throw error
      }
    },
    getDeviceIdFromCameraStream: (stream: MediaStream) => {
      let deviceId = ''
      if (stream) {
        stream?.getVideoTracks().forEach(track => {
          const value = track.getSettings().deviceId
          deviceId = value ?? deviceId
        })
      } else {
        console.error('No camera stream')
      }

      return deviceId
    },
    getSelectedCamera: async (): Promise<Device | undefined> => {
      const { cameraId, cameraIdDefault, cameraList, actions } = get()
      if (!cameraList.length) {
        await actions.refreshCameraList()
      }
      return getSelectedDevice(cameraId, cameraList, cameraIdDefault)
    },
    getSelectedMicrophone: async (): Promise<Device | undefined> => {
      const { microphoneId, microphoneIdDefault, microphoneList, actions } =
        get()
      if (!microphoneList.length) {
        await actions.refreshMicrophoneList()
      }
      return getSelectedDevice(
        microphoneId,
        microphoneList,
        microphoneIdDefault,
      )
    },
    getSelectedSpeaker: async (): Promise<Device | undefined> => {
      const { speakerId, speakerIdDefault, speakerList, actions } = get()
      if (!speakerList.length) {
        await actions.refreshSpeakerList()
      }
      return getSelectedDevice(speakerId, speakerList, speakerIdDefault)
    },
    refreshCameraList: async () => {
      // Get the stream for the initial preview
      const streamPromise = get()
        .actions.getCameraStream('')
        .then(stream => {
          if (stream) {
            set({
              cameraIdDefault:
                get().actions.getDeviceIdFromCameraStream(stream),
            })
          }
        })
        .finally(() => {
          WebRTC.stopStream(get().cameraStream)
        })

      // Get the list of cameras
      const devicesPromise = WebRTC.getCameraDevices().then(list => {
        const cameraList = cleanDeviceList(list)
        if (!cameraList.length) {
          set({ cameraDisabled: true, cameraId: undefined })
        }
        set({ cameraList })
      })

      const settledPromises = await Promise.allSettled([
        streamPromise,
        devicesPromise,
      ])
      settledPromises.forEach(promise => {
        if (promise.status === 'rejected') {
          logger.error(`Could not get camera list: ${promise.reason}`)
          throw promise.reason
        }
      })
    },
    refreshMicrophoneList: () => {
      // Get the list of mics
      return WebRTC.getMicrophoneDevices()
        .then(list => {
          const microphoneList = cleanDeviceList(list)
          if (!microphoneList.length) {
            set({ microphoneDisabled: true, microphoneId: undefined })
          } else {
            set({ microphoneList })
            if (!get().microphoneIdDefault) {
              set({ microphoneIdDefault: microphoneList[0]?.deviceId ?? '' })
            }
          }
        })
        .catch(error => {
          logger.error(`Could not get microphone list: ${error}`)
          throw error
        })
    },
    refreshSpeakerList: () => {
      // Get the list of speakers
      return WebRTC.getSpeakerDevices()
        .then(list => {
          const speakerList = cleanDeviceList(list)
          if (!speakerList.length) {
            set({ speakerDisabled: true, speakerId: undefined })
          } else {
            set({ speakerList })
            if (!get().speakerIdDefault) {
              set({ speakerIdDefault: speakerList[0]?.deviceId ?? '' })
            }
          }
        })
        .catch(error => {
          logger.error(`Could not get speaker list: ${error}`)
          throw error
        })
    },
    releaseDevices: () => {
      get().actions.stopCameraStream()
    },
    setCameraId: (deviceId: string) => {
      set({ cameraId: deviceId })
    },
    setMicrophoneId: (deviceId: string) => {
      set({ microphoneId: deviceId })
    },
    setSpeakerId: (deviceId: string) => {
      set({ speakerId: deviceId })
    },
    stopCameraStream: () => {
      // Stop the current stream
      const cameraStream = get().cameraStream
      if (cameraStream) {
        WebRTC.stopStream(cameraStream)

        // Reset the camera stream and status
        set({
          cameraStatus: initialState.cameraStatus,
          cameraStream: initialState.cameraStream,
        })
      }
    },
  },
})

export const useDevicesStore = create<Store>()(stateCreatorFn)
export const useDevicesStoreActions = () => useDevicesStore.getState().actions

// Expose the store to be used from the console
window.__devicesStore = useDevicesStore
