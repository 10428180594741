import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonVideoProps = OmittedProps

const ButtonVideo = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonVideoProps) => {
  const videoMuted = useRoomStore(state => state.videoMuted)
  const { videoMuteHandler } = useRoomStoreActions()

  return (
    <ButtonRoomControl
      disabled={disabled}
      isActive={!videoMuted}
      isVisible={isVisible}
      onClick={videoMuteHandler}
      tag={disabled ? 'videocam-off' : 'videocam'}
      {...props}
    >
      Video
    </ButtonRoomControl>
  )
}

export { ButtonVideo }
