import { useRef } from 'react'
import type { PropsWithChildren } from 'react'
import { Route } from '@/routes/_auth.room.$context.$name'
import { PanelRoom } from '@/components/PanelRoom'
import { RoomFooter } from '@/components/RoomFooter'
import { RoomHeader } from '@/components/RoomHeader'
import { useRoomStore } from '@/stores/room'
import { useUiStore } from '@/stores/ui'
import { RoomSessionTimer } from '@/components/RoomSessionTimer'

type LayoutRoomProps = PropsWithChildren

export const LayoutRoom = ({ children }: LayoutRoomProps) => {
  const { redirect } = Route.useSearch()
  const callViewRef = useRef<HTMLDivElement>(null)
  const displayRoomPanel = useUiStore(state => state.displayRoomPanel)
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  return (
    <>
      {displayRoomPanel ? (
        <PanelRoom containerElement={callViewRef.current} />
      ) : null}
      <div className="flex h-svh flex-col overflow-y-auto overflow-x-hidden bg-black">
        <RoomHeader />
        {/* In Call Timer (visible only for Video Call) */}
        {!isAudioOnly && (
          <RoomSessionTimer
            className="gradient flex w-full justify-center text-sm font-medium"
            startTimer
          />
        )}
        <div
          className="relative h-full w-full flex-1 overflow-y-hidden"
          ref={callViewRef}
        >
          {children}
        </div>
        {!isAudioOnly && <RoomFooter redirect={redirect} />}
      </div>
    </>
  )
}
