import type { ButtonProps } from '@/components/base/button'
import { Button } from '@/components/base/button'
import type { IconTagName } from '@/components/common/icons/Icon'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import type { PropsWithChildren } from 'react'

interface ButtonRoomControlProps extends ButtonProps {
  isActive?: boolean
  isVisible?: boolean
  tag: IconTagName
}

const ButtonRoomControl = ({
  children,
  className,
  disabled = false,
  isActive = false,
  isVisible = true,
  tag,
  ...props
}: PropsWithChildren<ButtonRoomControlProps>) => {
  if (!isVisible) return null

  // aria pressed is undefined if disabled (meaning cannot be pressed)
  const isPressed = disabled ? undefined : isActive

  return (
    <Button
      aria-pressed={isPressed}
      disabled={disabled}
      className={cn(
        'm-0 flex h-auto flex-col items-center gap-y-1 rounded-none bg-none p-0 px-2 pb-4 pt-3 text-neutral-light',
        isActive && 'gradient',
        className,
      )}
      {...props}
    >
      <div className={cn('rounded-2xl px-5 py-1', isActive && 'gradient')}>
        <Icon tag={tag} size="xl" />
      </div>
      <div className="text-xs font-medium">
        <span>{children}</span>
        <span className="sr-only">
          &nbsp;{isActive ? 'active' : 'inactive'}
        </span>
      </div>
    </Button>
  )
}

export { ButtonRoomControl }
export type { ButtonRoomControlProps }
