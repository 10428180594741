// Store for conversations (Recent/History)
import { useMainStore } from '@/stores/main'
import { useRoomStore } from '@/stores/room'
import type { IncomingCallNotification } from '@signalwire/js'
import { useCallback, useEffect } from 'react'
import { create, type StateCreator } from 'zustand'

interface Actions {
  actions: {
    setCurrentNotification: (
      notification: IncomingCallNotification | undefined,
    ) => void
    subscribeToIncomingCalls: () => void
    unsubscribeFromIncomingCalls: () => void
  }
}

interface State {
  canReceiveCalls: boolean
  currentNotification: IncomingCallNotification | undefined
  // TODO: for handling multiple calls, or history and notifications list (settings view)?
  notificationsMap: Map<string, IncomingCallNotification>
}

type Store = Actions & State

const initialState: State = {
  canReceiveCalls: false,
  currentNotification: undefined,
  notificationsMap: new Map(),
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    setCurrentNotification: (
      notification: IncomingCallNotification | undefined,
    ) => {
      set({ currentNotification: notification })
    },
    subscribeToIncomingCalls: async () => {
      const { canReceiveCalls } = get()
      if (canReceiveCalls) {
        console.info(
          'XXXX already subscribed to incoming calls',
          canReceiveCalls,
        )
        return
      }

      const { client } = useMainStore.getState()
      if (!client) {
        console.error('XXXX no client found')
        return
      }

      const handleCalls = (notification: IncomingCallNotification) => {
        const { currentNotification, notificationsMap } = get()
        console.info('Incoming call notification:', notification)
        // TODO: Handle multiple incoming calls at the same time? Use a queue or Map/Set()?
        if (
          currentNotification?.invite.details.callID ===
          notification.invite.details.callID
        ) {
          console.info('Ignoring incoming call notification for the same call')
          return
        }

        const copyNotificationsMap = new Map(notificationsMap)
        copyNotificationsMap.set(
          notification.invite.details.callID,
          notification,
        )

        set({
          currentNotification: notification,
          notificationsMap: copyNotificationsMap,
        })
      }

      await client?.online({
        incomingCallHandlers: {
          // TODO: handle push notifications once implemented in the SDK?
          websocket: handleCalls,
        },
      })

      console.log('XXXX online & subscribed to incoming calls')
      set({ canReceiveCalls: true })
    },
    unsubscribeFromIncomingCalls: async () => {
      const { client } = useMainStore.getState()
      if (!client) {
        console.error('XXXX no client found')
        return
      }

      await client.offline()

      set({ canReceiveCalls: false })
    },
  },
})

export const useCallStore = create<Store>()(stateCreatorFn)
export const useCallStoreActions = () => useCallStore.getState().actions

export type CallStoreType = typeof useCallStore

export const useNotifyCall = () => {
  const client = useMainStore(state => state.client)
  const currentNotification = useCallStore(state => state.currentNotification)

  const {
    setCurrentNotification,
    subscribeToIncomingCalls,
    unsubscribeFromIncomingCalls,
  } = useCallStoreActions()

  const dismissCurrentNotification = useCallback(() => {
    setCurrentNotification(undefined)
  }, [setCurrentNotification])

  const incomingCall = currentNotification?.invite

  const handleAcceptCall = useCallback(
    async (options: { rootElement?: HTMLElement }) => {
      if (!incomingCall?.accept) {
        console.error('No accept function found')
        return
      }
      const rootElement =
        options.rootElement ?? useRoomStore.getState().rootElement

      const acceptHandler = <T>(value: T) => {
        console.log('Accept value:', value)
        dismissCurrentNotification()
        return value
      }

      // TODO: handle accept error?
      if (!rootElement) {
        // @ts-expect-error - rootElement is optional and can be undefined
        return await incomingCall?.accept().then(acceptHandler)
      } else {
        return await incomingCall?.accept({ rootElement }).then(acceptHandler)
      }
    },
    [incomingCall, dismissCurrentNotification],
  )

  const handleRejectCall = useCallback(async () => {
    if (!incomingCall?.reject) {
      console.error('No reject function found')
      return
    }
    // TODO: handle reject error?
    await incomingCall?.reject()
    dismissCurrentNotification()
  }, [incomingCall, dismissCurrentNotification])

  useEffect(() => {
    if (client) {
      subscribeToIncomingCalls()
    }

    return () => {
      unsubscribeFromIncomingCalls()
    }
  }, [client, subscribeToIncomingCalls, unsubscribeFromIncomingCalls])

  return {
    acceptCall: handleAcceptCall,
    incomingCallDetails: incomingCall?.details,
    rejectCall: handleRejectCall,
  }
}

// Expose the store to be used from the console
window.__callStore = useCallStore
