import { DEVICE_ERROR_MESSAGE_MAP } from '@/helpers/constants'
import type { DeviceErrorType } from '@/helpers/types'
import { useUiStore } from '@/stores/ui'
import type { ReactNode } from 'react'

const ERROR_DIALOG_MESSAGE = {
  JOIN_ROOM_ERROR: {
    DESCRIPTION: 'There was a problem when joining the room. Please try again.',
    TITLE: 'Error',
  },
  MISSING_CONTEXT_ERROR: {
    DESCRIPTION: 'The room context is missing. Please try again.',
    TITLE: 'Error',
  },
  MISSING_NAME_ERROR: {
    DESCRIPTION: 'The room name is missing. Please try again.',
    TITLE: 'Error',
  },
} as const

interface DispatchErrorDialogParams {
  confirmLabel?: ReactNode
  description: ReactNode
  onConfirm?: () => void
  title: ReactNode
}

// Generic error dialog for various error messages
const dispatchErrorDialog = ({
  confirmLabel,
  description,
  onConfirm,
  title,
}: DispatchErrorDialogParams) => {
  const { setErrorDialogContent, showErrorDialog, hideErrorDialog } =
    useUiStore.getState().actions

  const handleConfirm = () => {
    onConfirm?.()
    hideErrorDialog()
  }

  setErrorDialogContent({
    confirmLabel: confirmLabel || 'OK',
    description,
    onConfirm: handleConfirm,
    title,
  })
  showErrorDialog()
}

interface DispatchJoinRoomErrorDialogParams {
  onConfirm: () => void
}

// Generic error dialog when joining a room fails
const dispatchHandleRoomJoinErrorDialog = ({
  onConfirm,
}: DispatchJoinRoomErrorDialogParams) => {
  dispatchErrorDialog({
    description: ERROR_DIALOG_MESSAGE.JOIN_ROOM_ERROR.DESCRIPTION,
    onConfirm,
    title: ERROR_DIALOG_MESSAGE.JOIN_ROOM_ERROR.TITLE,
  })
}

interface DispatchMissingContextErrorDialogParams {
  onConfirm: () => void
  type: 'context' | 'name'
}

// missing context or name parameters error dialog when joining a room
const dispatchMissingContextErrorDialog = ({
  onConfirm,
  type,
}: DispatchMissingContextErrorDialogParams) => {
  const errorDetails =
    type === 'context'
      ? ERROR_DIALOG_MESSAGE.MISSING_CONTEXT_ERROR
      : ERROR_DIALOG_MESSAGE.MISSING_NAME_ERROR

  dispatchErrorDialog({
    description: errorDetails.DESCRIPTION,
    onConfirm,
    title: errorDetails.TITLE,
  })
}

interface DispatchDeviceErrorParams {
  errorType: DeviceErrorType
}
// Error dialog for device permissions issues
const dispatchDeviceErrorDialog = ({
  errorType,
}: DispatchDeviceErrorParams) => {
  const errorDetails = DEVICE_ERROR_MESSAGE_MAP[errorType]

  const titleNode = (
    <div className="space-y-2">
      <h2>{errorDetails.title}</h2>
      <p className="text-sm">{errorDetails.description}</p>
    </div>
  )
  const descriptionNode = (
    <div className="space-y-4">
      <div>
        <h3 className="mb-0.5 text-sm font-semibold">How to fix:</h3>
        <p className="text-xs">{errorDetails.instructions}</p>
      </div>
    </div>
  )
  dispatchErrorDialog({
    description: descriptionNode,
    title: titleNode,
  })
}

export {
  dispatchDeviceErrorDialog,
  dispatchHandleRoomJoinErrorDialog,
  dispatchMissingContextErrorDialog,
}
