import { memo } from 'react'
import { PageTitle } from '@/components/PageTitle'
import { TanStackRouterDevtools } from '@/components/TanStackRouterDevTools.lazy'
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'

export const Route = createRootRouteWithContext<{
  title: string
}>()({
  // Memoize the root component to prevent re-renders and title updates
  component: memo(function RootComponent() {
    return (
      <>
        <PageTitle />
        <Outlet />
        <TanStackRouterDevtools />
      </>
    )
  }),
})
