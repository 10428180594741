import { TOAST_TYPE } from '@/helpers/constants'
import { createToast } from '@/hooks/use-toast'

const TOAST_MESSAGE = {
  GLOBAL: {
    ERROR: {
      TITLE: 'Error',
      UNKNOWN: 'Something went wrong. Please try again.',
    },
  },
} as const

interface DispatchErrorToastParams {
  errorMessage: string
  title: string
}

const dispatchErrorToast = ({
  errorMessage,
  title,
}: DispatchErrorToastParams) => {
  createToast({
    description: errorMessage,
    duration: Infinity,
    titleLabel: title,
    toastType: TOAST_TYPE.ERROR,
    variant: 'destructive',
  })
}

export const dispatchUnhandledErrorToast = () => {
  dispatchErrorToast({
    errorMessage: TOAST_MESSAGE.GLOBAL.ERROR.UNKNOWN,
    title: TOAST_MESSAGE.GLOBAL.ERROR.TITLE,
  })
}
