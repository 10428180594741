import { CardLoading } from '@/components/CardLoading'

interface CardLoadingSpinnerProps {
  open: boolean
}

export const Loading = ({ open }: CardLoadingSpinnerProps) => {
  if (!open) {
    return null
  }

  return (
    <div className="flex min-h-svh w-full flex-col items-center justify-center bg-primary">
      <CardLoading cardTitle="Loading" open />
    </div>
  )
}
