import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'

type ButtonMicrophoneProps = Omit<ButtonRoomControlProps, 'tag'>

const ButtonMicrophone = ({ disabled, ...props }: ButtonMicrophoneProps) => {
  return (
    <ButtonRoomControl
      disabled={disabled}
      tag={disabled ? 'mic-off-2' : 'mic'}
      {...props}
    >
      Microphone
    </ButtonRoomControl>
  )
}

export { ButtonMicrophone }
