import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonRaiseHandProps = OmittedProps

const ButtonRaiseHand = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonRaiseHandProps) => {
  const handRaised = useRoomStore(state => state.handRaised)
  const { handRaiseHandler } = useRoomStoreActions()

  return (
    <ButtonRoomControl
      isActive={handRaised}
      disabled={disabled}
      isVisible={isVisible}
      onClick={handRaiseHandler}
      // TODO: add disabled tag if needed
      tag={disabled ? 'back-hand' : 'back-hand'}
      {...props}
    >
      Raise Hand
    </ButtonRoomControl>
  )
}

export { ButtonRaiseHand }
