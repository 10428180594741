import { create } from 'zustand'

// Stores
import { useUserStore } from '@/stores/user'
import { useDevicesStoreActions } from '@/stores/devices'
import { useMainStore } from '@/stores/main'
import { useFeaturesStore, FEATURES } from '@/stores/features'
import { useUserSettingsStore } from '@/stores/userSettings'
import { useUiStoreActions } from '@/stores/ui'
import { useRoomSessionStore } from '@/stores/roomSession'

// Utils
import { buildRoomPath } from '@/helpers/utils'
import { LAYOUT_MAP, mapLayoutPositions } from '@/helpers/layouts'

// Types
import type {
  CallJoinedEventParams,
  CallUpdatedEventParams,
  FabricLayoutChangedEventParams,
  FabricMemberJoinedEventParams,
  FabricMemberLeftEventParams,
  FabricMemberTalkingEventParams,
  FabricMemberUpdatedEventParams,
  FabricRoomSession,
  OverlayMap,
  VideoPosition,
  RoomSessionScreenShare,
  InternalFabricMemberEntity,
  DialParams,
} from '@signalwire/js'
import type { StateCreator } from 'zustand'
import type {
  CallCapabilities,
  ChannelType,
  RedirectPath,
  VideoMemberEntity,
} from '@/helpers/types'
import type { Layout, LayoutName, LayoutPosition } from '@/helpers/layouts'

interface CallJoinOptions {
  channel?: ChannelType | undefined
  context: string
  name: string
  redirect?: RedirectPath | undefined
  rootElement?: HTMLElement
}

interface Actions {
  actions: {
    _cleanUp: () => boolean
    _onDestroy: (
      roomSession: FabricRoomSession,
      redirect?: RedirectPath,
    ) => void
    _onJoined: (event: CallJoinedEventParams) => void
    _onLayoutChanged: (event: FabricLayoutChangedEventParams) => void
    _onMemberJoined: (event: FabricMemberJoinedEventParams) => void
    _onMemberLeft: (event: FabricMemberLeftEventParams) => void
    _onMemberTalking: (event: FabricMemberTalkingEventParams) => void
    _onMemberUpdated: (event: FabricMemberUpdatedEventParams) => void
    // _onStart: () => void
    _onUpdated: (event: CallUpdatedEventParams) => void
    _upsertMemberInStore: (member: VideoMemberEntity) => void
    _upsertMembersInStore: (members: VideoMemberEntity[]) => void
    audioMuteHandler: () => boolean
    getLayouts: () => Promise<string[]>
    handRaiseHandler: () => void
    hasLockRoomCapability: () => boolean
    incrementElapsedTime: () => void
    joinRoom: (options: CallJoinOptions) => Promise<boolean>
    leaveRoom: () => Promise<void>
    lockRoomHandler: () => boolean
    resetElapsedTime: () => void
    screenShareHandler: () => Promise<void>
    setAddressId: (addressId: string) => void
    setCapabilities: (capabilities: CallCapabilities) => void
    setLayout: (layout: LayoutName) => void
    setLayoutPosition: (positions: VideoPosition) => void
    setMemberState: (memberState: MemberState) => void
    setRoomName: (roomName: string) => void
    setRootElementById: (elementId: string) => HTMLElement | null
    startRoomSession: (roomSession: FabricRoomSession) => Promise<void>
    updateCameraHandler: (deviceId: string) => Promise<void>
    updateMicrophoneHandler: (deviceId: string) => Promise<void>
    updateSpeakerHandler: (deviceId: string) => Promise<void>
    videoMuteHandler: () => boolean
  }
}
type MemberState = 'joined' | 'joining' | 'ready'

interface State {
  addressId: string
  audioMuted: boolean
  cameraId: string | null
  capabilities: CallCapabilities | undefined
  elapsedTimeSecs: number
  handRaised: boolean
  hasCamera: boolean
  hasMicrophone: boolean
  hasSpeaker: boolean
  isAudioOnly: boolean
  layout: LayoutName | ''
  layoutPosition: string
  layoutPositions: LayoutPosition[]
  layouts: Layout[]
  memberId: string
  memberState: MemberState
  membersMap: Map<string, VideoMemberEntity>
  microphoneId: string | null
  overlayMap: OverlayMap | undefined
  path: string
  roomLocked: boolean
  roomName: string
  rootElement: HTMLElement | null
  screenShareStream: RoomSessionScreenShare | null
  sharingScreen: boolean
  speakerId: string | null
  videoMuted: boolean
}

type Store = Actions & State

const initialState: State = {
  addressId: '',
  audioMuted: false,
  cameraId: '',
  capabilities: undefined,
  elapsedTimeSecs: 0,
  handRaised: false,
  hasCamera: true,
  hasMicrophone: true,
  hasSpeaker: true,
  isAudioOnly: true,
  layout: '',
  layoutPosition: '',
  layoutPositions: [],
  layouts: [],
  memberId: '',
  memberState: 'ready',
  membersMap: new Map(),
  microphoneId: '',
  overlayMap: new Map(),
  path: '',
  roomLocked: false,
  roomName: '',
  rootElement: null,
  screenShareStream: null,
  sharingScreen: false,
  speakerId: '',
  videoMuted: false,
}

const mapMembers = (
  members: InternalFabricMemberEntity[],
): VideoMemberEntity[] => {
  return members.map(mapMember)
}

const mapMember = (member: InternalFabricMemberEntity): VideoMemberEntity => ({
  audioMuted: member.audio_muted,
  callId: member.call_id,
  currentPosition: member.current_position,
  deaf: member.deaf,
  handRaised: member.handraised,
  id: member.member_id,
  inputSensitivity: member.input_sensitivity,
  inputVolume: member.input_volume,
  memberId: member.member_id,
  meta: member.meta,
  name: member.name,
  nodeId: member.node_id,
  outputVolume: member.output_volume,
  parentId: member.parent_id,
  requestedPosition: member.requested_position,
  roomId: member.room_id,
  roomSessionId: member.room_session_id,
  talking: member.talking ?? false,
  type: member.type,
  videoMuted: member.video_muted,
  visible: member.visible,
})

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    _cleanUp: () => {
      const { releaseDevices } = useDevicesStoreActions()
      const { hideRoomPanel } = useUiStoreActions()
      releaseDevices()
      hideRoomPanel()

      // This will clear the `memberState`, which means we're ready to join another room
      set(initialState)
      return true
    },
    _onDestroy: (roomSession, _redirect) => {
      const {
        _cleanUp,
        _onMemberJoined,
        _onMemberUpdated,
        _onMemberLeft,
        _onMemberTalking,
      } = get().actions

      // FIXME: The SDK emits the room object on `destroy` event which contains
      // properties including `cause` and `leaveReason`.
      // The `leaveReason` is undefined at the moment and `cause` does not exist
      // on the TS interface.
      // The SDK is missing the `room.left` event as well. This needs to be
      // discussed with the team and fixed.
      console.debug('>> destroy', roomSession.leaveReason)

      // TODO: Inform user about the room left reason

      roomSession.off('member.joined', _onMemberJoined)
      roomSession.off('member.updated', _onMemberUpdated)
      roomSession.off('member.left', _onMemberLeft)
      roomSession.off('member.talking', _onMemberTalking)

      _cleanUp()
    },
    _onJoined: event => {
      const { roomSession } = useRoomSessionStore.getState()
      const { _upsertMembersInStore } = get().actions
      const { getPreferredSpeakerDeviceId } =
        useUserSettingsStore.getState().actions

      const roomState = {
        memberId: event.member_id,
        roomLocked: event.room_session.locked,
      }

      let memberRoomState: Pick<
        State,
        'audioMuted' | 'handRaised' | 'videoMuted'
      > = {
        audioMuted: get().audioMuted,
        handRaised: get().handRaised,
        videoMuted: get().videoMuted,
      }

      const selfMember = event.room_session.members.find(
        member => member.member_id === event.member_id,
      )
      if (selfMember) {
        memberRoomState = {
          audioMuted: selfMember.audio_muted,
          handRaised: selfMember.handraised,
          videoMuted: selfMember.video_muted,
        }
      }

      set({ ...roomState, ...memberRoomState })
      _upsertMembersInStore(mapMembers(event.room_session.members))

      const deviceId = getPreferredSpeakerDeviceId()
      if (deviceId && roomSession) {
        roomSession
          .updateSpeaker({ deviceId })
          .then(() => console.log('speaker updated'))
          .catch(console.error)
      }
    },
    _onLayoutChanged: event => {
      const { memberId } = get()
      set({
        layout: event.layout.id as LayoutName,
        layoutPosition:
          event.layout.layers.find(layer => layer.member_id === memberId)
            ?.position || '',
        layoutPositions: mapLayoutPositions(event.layout.layers),
      })
    },
    _onMemberJoined: event => {
      const { _upsertMemberInStore } = get().actions
      _upsertMemberInStore(mapMember(event.member))
    },
    _onMemberLeft: event => {
      const { membersMap } = get()
      const updatedMembersMap = new Map(membersMap)
      updatedMembersMap.delete(event.member.member_id!)
      set({ membersMap: updatedMembersMap })
    },
    _onMemberTalking: event => {
      const { membersMap } = get()
      const { _upsertMemberInStore } = get().actions
      if (!membersMap.has(event.member.member_id!)) return
      const member: VideoMemberEntity = {
        ...membersMap.get(event.member.member_id!)!,
        talking: event.member.talking,
      }
      _upsertMemberInStore(member)
    },
    _onMemberUpdated: event => {
      const { _upsertMemberInStore } = get().actions
      _upsertMemberInStore(mapMember(event.member))
    },
    // TODO: This is not being used, should we remove it?
    // _onStart: () => {
    //   const { getPreferredSpeakerDeviceId } =
    //     useUserSettingsStore.getState().actions
    //   const { roomSession } = useRoomSessionStore.getState()

    //   const deviceId = getPreferredSpeakerDeviceId()
    //   if (deviceId && roomSession) {
    //     roomSession
    //       .updateSpeaker({ deviceId })
    //       .then(() => console.log('speaker updated'))
    //       .catch(console.error)
    //   }
    // },
    _onUpdated: event => {
      const roomState = {
        roomLocked: event.room_session.locked,
      }

      let memberState: Pick<State, 'audioMuted' | 'handRaised' | 'videoMuted'> =
        {
          audioMuted: get().audioMuted,
          handRaised: get().handRaised,
          videoMuted: get().videoMuted,
        }

      const selfMember = event.room_session.members?.find(
        member => member.member_id === get().memberId,
      )
      if (selfMember) {
        memberState = {
          audioMuted: selfMember.audio_muted,
          handRaised: selfMember.handraised,
          videoMuted: selfMember.video_muted,
        }
      }

      set({ ...roomState, ...memberState })
    },
    _upsertMemberInStore: member => {
      const { _upsertMembersInStore } = get().actions
      _upsertMembersInStore([member])
    },
    _upsertMembersInStore: members => {
      const { membersMap } = get()
      const updatedMembersMap = new Map(membersMap)
      members.forEach(member => {
        if (updatedMembersMap.has(member.memberId)) {
          updatedMembersMap.set(member.memberId, {
            ...updatedMembersMap.get(member.memberId),
            ...member,
          })
        } else {
          updatedMembersMap.set(member.memberId, member)
        }
      })
      set({ membersMap: updatedMembersMap })
    },
    audioMuteHandler: () => {
      const { audioMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (roomSession) {
        if (audioMuted) {
          void roomSession.audioUnmute()
        } else {
          void roomSession.audioMute()
        }
        set({ audioMuted: !audioMuted })
      }
      return !audioMuted
    },
    getLayouts: async () => {
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) return []

      const { layouts } = await roomSession.getLayouts()
      set({
        layouts: layouts
          .map(layout => {
            return LAYOUT_MAP[layout as LayoutName]
          })
          .filter(Boolean),
      })
      return layouts
    },
    handRaiseHandler: () => {
      const { handRaised } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      if (handRaised) {
        void roomSession.setRaisedHand({ raised: false })
      } else {
        void roomSession.setRaisedHand({ raised: true })
      }
      set({ handRaised: !handRaised })
    },
    hasLockRoomCapability: () => {
      const { capabilities } = get()
      return Boolean(capabilities?.lock?.on ?? capabilities?.lock?.off)
    },
    incrementElapsedTime: () => {
      const { elapsedTimeSecs } = get()
      set({ elapsedTimeSecs: elapsedTimeSecs + 1 })
    },
    joinRoom: async (options: CallJoinOptions) => {
      const { context, name, channel, redirect, rootElement } = options
      const { client } = useMainStore.getState()
      const { join } = useRoomSessionStore.getState().actions
      const {
        _onDestroy,
        _onJoined,
        _onLayoutChanged,
        _onMemberJoined,
        _onMemberLeft,
        _onMemberTalking,
        _onMemberUpdated,
        // _onStart,
        _onUpdated,
        getLayouts,
      } = get().actions

      const {
        getPreferredMicrophoneDeviceForDialing,
        getPreferredVideoDeviceForDialing,
      } = useUserSettingsStore.getState().actions
      const path = buildRoomPath(context, name, channel, redirect)

      set({ memberState: 'joining', path })

      if (!path) {
        console.error('Cannot join using an empty address')
        return false
      }

      if (!client) {
        throw new Error('Client is not defined')
      }

      // TODO: Set a node_id for steering?
      const steeringId = ''
      const { getUserVariables } = useUserStore.getState().actions

      const joinParams: DialParams = {
        audio: getPreferredMicrophoneDeviceForDialing(path),
        nodeId: steeringId,
        rootElement: rootElement!,
        to: path,
        video: getPreferredVideoDeviceForDialing(path),
      }

      const { getFeatureFlagIsDisabled } = useFeaturesStore.getState().actions
      const userVariablesIsDisabled = getFeatureFlagIsDisabled(
        FEATURES.USER_VARIABLES,
      )
      if (!userVariablesIsDisabled) {
        joinParams.userVariables = await getUserVariables({ channel })
      }

      const call = await join(
        { ...joinParams },
        {
          onDestroy: room => {
            _onDestroy(room, redirect)
          },
          onJoined: _onJoined,
          onLayoutChanged: _onLayoutChanged,
          onUpdated: _onUpdated,
        },
      )
      if (!call) {
        throw new Error('Call is not defined')
      }

      call.on('member.joined', _onMemberJoined)
      call.on('member.updated', _onMemberUpdated)
      call.on('member.left', _onMemberLeft)
      call.on('member.talking', _onMemberTalking)

      set({
        isAudioOnly: !call.withVideo,
        memberState: 'joined',
        overlayMap: call.overlayMap,
      })

      // Fetch and store room layouts
      void getLayouts()

      // Expose the roomSession to be used from the console
      window.__roomSession = call

      return true
    },
    leaveRoom: async () => {
      console.log('XXXX: leaveRoom()')
      const { memberState } = get()
      const { _cleanUp } = get().actions
      const { leave } = useRoomSessionStore.getState().actions

      if (memberState === 'ready') {
        return
      }

      await leave()
      console.log('XXXX: hangup success')
      _cleanUp()
      return
    },
    lockRoomHandler: () => {
      const { roomLocked } = get()
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) {
        throw new Error('Room session not defined')
      }

      if (roomLocked) {
        void roomSession.unlock()
      } else {
        void roomSession.lock()
      }
      set({ roomLocked: !roomLocked })

      return !roomLocked
    },
    resetElapsedTime: () => {
      set({ elapsedTimeSecs: 0 })
    },
    screenShareHandler: async (): Promise<void> => {
      const { sharingScreen, screenShareStream } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return

      try {
        if (sharingScreen) {
          // If a local screenshare exists, then stop it
          set({ sharingScreen: false })
          if (screenShareStream) {
            await screenShareStream.leave()
          }
          set({ screenShareStream: null })
        } else {
          // Initialize a screenshare media stream
          set({ sharingScreen: true })
          const screenShare = await roomSession.startScreenShare()
          set({ screenShareStream: screenShare })

          screenShare.once('destroy', () => {
            set({ screenShareStream: null, sharingScreen: false })
          })
        }
      } catch (error) {
        console.error('Screenshare Error: ', error)
        set({ screenShareStream: null, sharingScreen: false })
      }
    },
    setAddressId: (addressId: string) => {
      set({
        addressId,
      })
    },
    setCapabilities: (capabilities: CallCapabilities) => {
      set({
        capabilities,
      })
    },
    setLayout: (layout: LayoutName) => {
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) return

      void roomSession.setLayout({ name: layout })
      set({ layout })
    },
    setLayoutPosition: position => {
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) return

      void roomSession.setPositions({ positions: { self: position } })
      set({ layoutPosition: position })
    },
    setMemberState: (memberState: MemberState) => {
      set({ memberState })
    },
    setRoomName: (roomName: string) => {
      set({
        roomName,
      })
    },
    setRootElementById: (elementId: string) => {
      let rootElement: HTMLElement | null = null

      if (elementId) {
        rootElement = document.getElementById(elementId)
      }
      set({ rootElement })
      return rootElement
    },
    startRoomSession: async (roomSession: FabricRoomSession) => {
      const { startSession } = useRoomSessionStore.getState().actions
      const { _onDestroy, _onJoined } = get().actions

      await startSession(roomSession, {
        onDestroy: _onDestroy,
        onJoined: _onJoined,
      })
      set({ memberState: 'joined' })
    },
    updateCameraHandler: async (deviceId: string) => {
      // TODO: Handle errors?
      const { videoMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      await roomSession.updateCamera({ deviceId })
      if (videoMuted) {
        roomSession.stopOutboundVideo()
      }
    },
    updateMicrophoneHandler: async (deviceId: string) => {
      const { audioMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      await roomSession.updateMicrophone({ deviceId })
      if (audioMuted) {
        roomSession.stopOutboundAudio()
      }
    },
    updateSpeakerHandler: async (deviceId: string) => {
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      await roomSession.updateSpeaker({ deviceId })
    },
    videoMuteHandler: () => {
      const { videoMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (roomSession) {
        if (videoMuted) {
          void roomSession.videoUnmute()
        } else {
          void roomSession.videoMute()
        }
        set({ videoMuted: !videoMuted })
      }
      return !videoMuted
    },
  },
})

export const useRoomStore = create<Store>()(stateCreatorFn)
export const useRoomStoreActions = () => useRoomStore.getState().actions

// Expose the store to be used from the console
window.__roomStore = useRoomStore
