import type { IconProps } from '@/components/common/icons/Icon'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import type { HTMLAttributes } from 'react'

interface FieldErrorMessageProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<IconProps, 'size'> {
  visible?: boolean
}

export const FieldErrorMessage = ({
  children,
  className,
  size = 'xl',
  visible = true,
}: FieldErrorMessageProps) => {
  // TODO: add a11y attributes
  return (
    <div
      className={cn(
        'text-error flex items-center gap-x-3 text-destructive',
        visible ? 'visible' : 'invisible',
        className,
      )}
    >
      <Icon tag="error" size={size} />
      <p className="">{children}</p>
    </div>
  )
}
