import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'
import { useState } from 'react'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonInCallOptionsProps = OmittedProps

const ButtonInCallOptions = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonInCallOptionsProps) => {
  const [isActive, setIsActive] = useState(false)

  // note the data-state attribute is added by Dialog
  const handleDataStateChange = (ref: HTMLButtonElement | null) => {
    setIsActive(ref?.dataset['state'] === 'open')
  }

  return (
    <ButtonRoomControl
      // FIXME: Button should toggle modal open/close. Currently, clicking after closing reopens the modal immediately.
      disabled={isActive || disabled}
      isActive={isActive}
      isVisible={isVisible}
      ref={handleDataStateChange}
      tag="more-horiz"
      {...props}
    >
      Options
    </ButtonRoomControl>
  )
}

export { ButtonInCallOptions }
