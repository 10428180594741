import { createPortal } from 'react-dom'
import { useEffect, useRef, useState } from 'react'
import { useRoomStore } from '@/stores/room'
import { useRoomSessionStore } from '@/stores/roomSession'
import { ControlledTooltip } from '@/components/CustomTooltip'
import { Icon } from '@/components/common/icons/Icon'
import { cn, isElementTruncated } from '@/helpers/utils'
import type { VideoMember } from '@/helpers/types'
import { useUiStore } from '@/stores/ui'

export const MemberOverlays = () => {
  const membersMap = useRoomStore(state => state.membersMap)

  return Array.from(membersMap.values()).map(member => (
    <MemberOverlay
      audioMuted={member.audioMuted}
      handRaised={member.handRaised}
      key={member.memberId}
      memberId={member.memberId}
      name={member.name}
      talking={member.talking}
    />
  ))
}

type MemberOverlayProps = Pick<
  VideoMember,
  'audioMuted' | 'handRaised' | 'memberId' | 'name' | 'talking'
>

const MemberOverlay = (props: MemberOverlayProps) => {
  const { audioMuted, handRaised, memberId } = props
  const audioMutedSelf = useRoomStore(state => state.audioMuted)
  const handRaisedSelf = useRoomStore(state => state.handRaised)
  const memberIdSelf = useRoomStore(state => state.memberId)
  const overlayMap = useRoomStore(state => state.overlayMap)
  const roomSession = useRoomSessionStore(state => state.roomSession)
  const [overlayElement, setOverlayElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!roomSession || !memberId) return

    const element = roomSession.getMemberOverlay(memberId)?.domElement
    if (element) {
      setOverlayElement(element)
    }

    return () => {
      setOverlayElement(null)
    }

    /**
     * There is a possibility that the members list is updated before the DOM element is injected.
     * To address this, we rely on the overlayMap.size to trigger re-renders.
     * The SDK updates this Map when adding or removing a DOM element.
     */
  }, [roomSession, memberId, overlayMap?.size])

  // If the overlay element is not available, render nothing
  if (!overlayElement) {
    return null
  }

  const isSelfMember = memberId === memberIdSelf

  // Render overlay UI into the overlay element with React Portals
  // @https://react.dev/reference/react-dom/createPortal#rendering-react-components-into-non-react-dom-nodes
  return createPortal(
    <MemberOverlayUI
      {...props}
      audioMuted={isSelfMember ? audioMutedSelf : audioMuted}
      handRaised={isSelfMember ? handRaisedSelf : handRaised}
    />,
    overlayElement,
    memberId,
  )
}

type MemberOverlayUIProps = MemberOverlayProps

const MemberOverlayUI = (props: MemberOverlayUIProps) => {
  const { audioMuted, handRaised, name, talking } = props
  const [isPointerOverName, setIsPointerOverName] = useState(false)
  const nameRef = useRef<HTMLDivElement | null>(null)
  const rootElementRef = useUiStore(state => state.rootElementRef)

  return (
    <article className="relative h-full w-full contain-layout @container">
      <h1 className="sr-only">Overlay for {name}</h1>
      <div
        className={cn(
          'relative z-10 flex h-full w-full items-center justify-around border-2 border-transparent transition-all duration-300 ease-in-out @3xs:items-start @3xs:justify-between @3xs:border-4',
          {
            'border-negative-light': talking,
          },
        )}
      >
        <Icon
          tag="mic-off"
          size="xl"
          className={cn(
            'h-auto min-w-6 max-w-10 @4xs:w-8 @sm:w-10 @md:mr-auto',
            {
              hidden: !audioMuted,
              visible: audioMuted,
            },
          )}
          aria-hidden={!audioMuted}
          aria-label={audioMuted ? 'Microphone off' : undefined}
        />
        <Icon
          tag="raised-hand"
          size="xl"
          className={cn(
            'h-auto min-w-6 max-w-10 @4xs:w-8 @sm:w-10 @md:ml-auto',
            {
              hidden: !handRaised,
              visible: handRaised,
            },
          )}
          aria-hidden={!handRaised}
          aria-label={handRaised ? 'Hand raised' : undefined}
        />
      </div>
      <footer
        className={cn(
          'absolute inset-x-0 bottom-0 flex items-center bg-gradient-to-t from-black to-transparent py-[0.125rem] @3xs:py-1',
        )}
      >
        <div
          className={cn(
            'relative z-20 truncate px-1 text-left text-xs text-background @3xs:px-2 @3xs:text-sm @md:text-base',
          )}
          ref={nameRef}
        >
          <ControlledTooltip
            onEscapeKeyDown={() => setIsPointerOverName(false)}
            onPointerDownOutside={() => setIsPointerOverName(false)}
            onPointerEnter={() => {
              if (isElementTruncated(nameRef.current)) {
                setIsPointerOverName(true)
              }
            }}
            onPointerLeave={() => setIsPointerOverName(false)}
            onOpenChange={open => {
              if (open && isElementTruncated(nameRef.current)) {
                setIsPointerOverName(true)
              } else {
                setIsPointerOverName(false)
              }
            }}
            open={isPointerOverName}
            container={rootElementRef}
            trigger={<span>{name}</span>}
          >
            <span>{name}</span>
          </ControlledTooltip>
        </div>
      </footer>
    </article>
  )
}
