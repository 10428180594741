import { forwardRef } from 'react'

// Stores
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUiStoreActions } from '@/stores/ui'
import { FEATURES } from '@/stores/features'
import { CopyRoomLink } from './CopyRoomLink'
import { TEST_IDS } from '@/helpers/constants'

// Components
import { Icon } from '@/components/common/icons/Icon'
import { ScrollArea } from '@/components/base/scroll-area'
import { Button } from '@/components/base/button'
import { DialogDevices } from '@/components/DialogDevices'
import { Feature } from '@/components/Feature'

// Types
import type { RoomPanelType } from '@/stores/ui'
import type { ButtonProps } from '@/components/base/button'
import type { IconTagName } from '@/components/common/icons/Icon'

export const PanelRoomOptions = () => {
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)
  const memberState = useRoomStore(state => state.memberState)
  const path = useRoomStore(state => state.path)
  const roomLocked = useRoomStore(state => state.roomLocked)
  const { hasLockRoomCapability, lockRoomHandler } = useRoomStoreActions()

  const { setRoomPanelType } = useUiStoreActions()

  const changePanelContentHandler = (panelType: RoomPanelType) => {
    setRoomPanelType(panelType)
  }

  const memberJoined = memberState === 'joined'

  const isRoomLockVisible = hasLockRoomCapability() && !isAudioOnly
  const isCreateSidebarVisible = !isAudioOnly
  const isChangeRoomsVisible = !isAudioOnly

  return (
    <ScrollArea data-testid={TEST_IDS.ROOM_SETTINGS_PANEL}>
      <div className="flex flex-col gap-y-3">
        <PanelOptionButton
          disabled={!memberJoined}
          iconTag={roomLocked ? 'unlock' : 'lock'}
          onClick={lockRoomHandler}
          text={roomLocked ? 'Unlock Room' : 'Lock Room'}
          visible={isRoomLockVisible}
        />
        <Feature name={FEATURES.CREATE_SIDEBAR}>
          <PanelOptionButton
            disabled
            iconTag="view-sidebar"
            text="Create Sidebar"
            visible={isCreateSidebarVisible}
          />
        </Feature>
        {!isAudioOnly && (
          <CopyRoomLink
            address={path}
            clickedContent="Copied!"
            initialContent="Copy Room Link"
            withArrow={false}
          >
            {({ trigger }) => (
              <PanelOptionButton
                iconTag="share"
                text="Share Room"
                onClick={trigger}
              />
            )}
          </CopyRoomLink>
        )}
        <Feature name={FEATURES.CHANGE_ROOMS}>
          <PanelOptionButton
            iconTag="sofa-outline-1"
            text="Change Rooms"
            onClick={() => changePanelContentHandler('navigator')}
            disabled={!memberJoined}
            visible={isChangeRoomsVisible}
          />
        </Feature>
        <DialogDevices>
          <PanelOptionButton iconTag="headphones" text="Change Devices" />
        </DialogDevices>
        <PanelOptionButton
          iconTag="grid-on"
          text="Layout Options"
          onClick={() => changePanelContentHandler('layout')}
          disabled={!memberJoined}
        />
      </div>
    </ScrollArea>
  )
}

interface PanelOptionButtonProps extends ButtonProps {
  iconTag: IconTagName
  text: string
  visible?: boolean
}

const PanelOptionButton = forwardRef<HTMLButtonElement, PanelOptionButtonProps>(
  ({ iconTag, text, visible = true, ...props }, ref) => {
    if (!visible) {
      return null
    }

    return (
      <Button
        className="justify-start gap-x-3 text-sm text-foreground"
        variant="link"
        ref={ref}
        {...props}
      >
        <Icon size="xl" tag={iconTag} />
        <span className="truncate">{text}</span>
      </Button>
    )
  },
)
PanelOptionButton.displayName = 'PanelOptionButton'
