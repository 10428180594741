import { useMemo } from 'react'
import { FEATURES, useFeaturesStoreActions } from '@/stores/features'
import { useUiStore } from '@/stores/ui'
import { useUserStore } from '@/stores/user'

export type FooterControlIDs =
  | 'dnd'
  | 'microphone'
  | 'options'
  | 'raisehand'
  | 'screenshare'
  | 'video'

export type FooterControlsConfig = Record<FooterControlIDs, boolean>

// Mock Capabilities
const capabilities = {
  screenshare: true,
  self: {
    mute: {
      deaf: {
        off: true,
        on: true,
      },
      microphone: {
        off: true,
        on: true,
      },
      video: {
        off: true,
        on: true,
      },
    },
    raisehand: true,
  },
}

export const GUTTER_SPACE = 80

// Min width for each control button is considered 90px
const BREAKPOINTS: { maxCount: number; minWidth: number }[] = [
  { maxCount: 3, minWidth: 290 },
  { maxCount: 2, minWidth: 190 },
  { maxCount: 1, minWidth: 90 },
]

export const useRoomFooterControls = () => {
  const isMobile = useUserStore(state => state.isMobile)
  const { rootElementWidth } = useUiStore(state => state)

  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  const controlsConfig: FooterControlsConfig = useMemo(
    () => ({
      dnd:
        !getFeatureFlagIsDisabled(FEATURES.ROOM_DO_NOT_DISTURB) &&
        capabilities.self.mute.deaf.on &&
        capabilities.self.mute.deaf.off,
      microphone:
        capabilities.self.mute.microphone.on &&
        capabilities.self.mute.microphone.off,
      options: false,
      raisehand:
        !getFeatureFlagIsDisabled(FEATURES.ROOM_RAISE_HAND) &&
        capabilities.self.raisehand,
      screenshare:
        !getFeatureFlagIsDisabled(FEATURES.ROOM_SHARE_SCREEN) &&
        !isMobile &&
        capabilities.screenshare,
      video:
        capabilities.self.mute.video.on && capabilities.self.mute.video.off,
    }),
    [getFeatureFlagIsDisabled, isMobile],
  )

  const activeControls = useMemo(
    () =>
      new Set<FooterControlIDs>(
        (Object.keys(controlsConfig) as FooterControlIDs[]).filter(
          key => controlsConfig[key],
        ),
      ),
    [controlsConfig],
  )

  return useMemo(() => {
    const leftControls = new Set<FooterControlIDs>()
    const rightControls = new Set<FooterControlIDs>()

    const hasMicControl = activeControls.has('microphone')
    const hasVideoControl = activeControls.has('video')
    const hasRaiseHandControl = activeControls.has('raisehand')
    const hasScreenShareControl = activeControls.has('screenshare')
    const hasDndControl = activeControls.has('dnd')

    /**
     * The layout needs to be different when there are exactly two buttons
     * and the buttons are the microphone and video buttons.
     * The "Options" button is active but hidden.
     */
    const hasExactlyMicAndVideo =
      activeControls.size === 2 && hasMicControl && hasVideoControl

    if (hasMicControl) leftControls.add('microphone')
    if (hasExactlyMicAndVideo) {
      if (hasVideoControl) rightControls.add('video')
    } else {
      if (hasVideoControl) leftControls.add('video')
      if (hasRaiseHandControl) rightControls.add('raisehand')
      if (hasScreenShareControl) rightControls.add('screenshare')
      if (hasDndControl) rightControls.add('dnd')
    }

    if (!rootElementWidth) {
      return { leftControls, rightControls }
    }

    // Calculate available space for each control group
    const availableSpace = (rootElementWidth - GUTTER_SPACE) / 2

    // If the available space is not enough for each side; hide the controls
    const matchingBreakpoint =
      BREAKPOINTS.find(bp => availableSpace >= bp.minWidth) || null
    const maxAllowedControls = matchingBreakpoint
      ? matchingBreakpoint.maxCount
      : 0

    if (
      leftControls.size > maxAllowedControls ||
      rightControls.size > maxAllowedControls
    ) {
      leftControls.clear()
      rightControls.clear()
      leftControls.add('options')
    }

    return { leftControls, rightControls }
  }, [activeControls, rootElementWidth])
}
