import { MATERIAL_ICON_MAP, type IconName } from '@/assets/material-icons/index'
import { cn } from '@/helpers/utils'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { forwardRef, type FunctionComponent } from 'react'
import { SWIcon, type SWIconStyle } from './SWIcon'

export type IconTagName = IconName

export type Variant = 'destructive' | 'foreground' | 'link' | 'neutral'
export type IconSize = '2xl' | '2xs' | '3xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs'

export type IconProps = VariantProps<typeof iconVariants> & {
  className?: string
  tag: IconName
  type?: never
}

export interface SWLogoIconProps {
  className?: string
  size?: IconSize
  tag: 'sw-logo'
  type?: SWIconStyle
  variant?: never
}

export type CombinedIconProps = React.SVGProps<SVGSVGElement> &
  (IconProps | SWLogoIconProps)

const iconMap = {
  'sw-logo': SWIcon,
  ...MATERIAL_ICON_MAP,
} as const satisfies Record<IconTagName | 'sw-logo', FunctionComponent>

/* eslint-disable sort-keys */
const iconVariants = cva('fill-current', {
  variants: {
    variant: {
      destructive: 'fill-destructive',
      foreground: 'fill-foreground',
      link: '', // no fill, allow for hover and disabled states
      neutral: 'fill-neutral-light',
    } satisfies Record<Variant, string>,
    size: {
      // based on the FA classes @see https://fontawesome.com/docs/web/style/size
      '2xs': 'w-[0.625rem] h-[0.625rem]',
      'xs': 'w-[0.75rem] h-[0.75rem]',
      'sm': 'w-[0.875rem] h-[0.875rem]',
      'md': 'w-[1rem] h-[1rem]',
      'lg': 'w-[1.25rem] h-[1.25rem]',
      'xl': 'w-[1.5rem] h-[1.5rem]',
      '2xl': 'w-[2rem] h-[2rem]',
      '3xl': 'w-[2.5rem] h-[2.5rem]',
    } satisfies Record<IconSize, string>,
  },
  defaultVariants: {
    size: 'md',
  },
})
/* eslint-enable sort-keys */

export const Icon = forwardRef<SVGSVGElement, CombinedIconProps>(function inner(
  { className = '', size, tag, type = 'light', variant, ...props },
  forwardedRef,
) {
  const SVGIcon = iconMap[tag]

  if (!SVGIcon) {
    console.debug(`Unknown icon: ${tag}`)
    return null
  }

  // className is last in the list to allow it to override defaults
  const classes = cn(iconVariants({ className, size, variant }))

  return (
    <SVGIcon
      aria-hidden="true"
      className={classes}
      focusable="false"
      ref={forwardedRef}
      role="img"
      type={type}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  )
})
