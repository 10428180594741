import { useCapabilitiesStore } from '@/stores/capabilities'
import { FEATURES, useFeaturesStoreActions } from '@/stores/features'
import { useRoomStore } from '@/stores/room'

/**
 * returns whether the corresponding button should be visible and enabled
 * based on the capabilities, feature flag, and the current state
 * - button `visible` if one of the `capabilities` is enabled and the FF is enabled
 * - button `enabled` if the current state matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useHandRaiseButtonState = () => {
  const handRaised = useRoomStore(state => state.handRaised)
  const hasHandRaiseCapabilityOff = useCapabilitiesStore(
    state => state.hasHandRaiseCapabilityOff,
  )
  const hasHandRaiseCapabilityOn = useCapabilitiesStore(
    state => state.hasHandRaiseCapabilityOn,
  )
  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  // button visible if one of the capabilities is enabled and FF is enabled
  const isRaiseHandButtonVisible =
    !getFeatureFlagIsDisabled(FEATURES.ROOM_RAISE_HAND) &&
    (hasHandRaiseCapabilityOn || hasHandRaiseCapabilityOff)

  const canRaiseHand = !handRaised && hasHandRaiseCapabilityOn
  const canExitRaiseHand = handRaised && hasHandRaiseCapabilityOff

  // check if button can perform the necessary next action
  const isRaiseHandButtonEnabled = canRaiseHand || canExitRaiseHand

  return { isRaiseHandButtonEnabled, isRaiseHandButtonVisible }
}
