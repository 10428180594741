import { LinkLeave } from '@/components/LinkLeave'
import { ButtonRoomControl } from '@/components/ButtonRoomControl'
import type { RedirectPath } from '@/helpers/types'
import { TEST_IDS } from '@/helpers/constants'
import { useRoomFooterControls } from '@/hooks/useRoomFooterControls'
import { ButtonMicrophone } from './ButtonMicrophone'
import { ButtonVideo } from './ButtonVideo'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useState } from 'react'

export interface RoomFooterProps {
  redirect?: RedirectPath | undefined
}

export const RoomFooter = ({ redirect }: RoomFooterProps) => {
  const [isControlsActive, setIsControlsActive] = useState(false)

  const audioMuted = useRoomStore(state => state.audioMuted)
  const handRaised = useRoomStore(state => state.handRaised)
  const memberState = useRoomStore(state => state.memberState)
  const sharingScreen = useRoomStore(state => state.sharingScreen)
  const videoMuted = useRoomStore(state => state.videoMuted)

  const {
    audioMuteHandler,
    handRaiseHandler,
    screenShareHandler,
    videoMuteHandler,
  } = useRoomStoreActions()

  const { leftControls, rightControls } = useRoomFooterControls()

  const memberJoined = memberState === 'joined'

  const optionsHandler = () => setIsControlsActive(!isControlsActive)

  return (
    <div
      aria-label="Room toolbar"
      className="relative flex justify-center gap-x-24 bg-primary pt-1.5"
      data-testid={TEST_IDS.ROOM_CONTROLS}
      role="toolbar"
    >
      <div
        aria-label="Call controls"
        className="flex w-full shrink-0 items-center justify-end gap-x-2"
        role="group"
      >
        <ButtonRoomControl
          disabled={!memberJoined}
          isActive={isControlsActive}
          isVisible={leftControls.has('options')}
          onClick={optionsHandler}
          tag="more-horiz"
        >
          Options
        </ButtonRoomControl>
        <ButtonMicrophone
          disabled={!memberJoined}
          isActive={!audioMuted}
          isVisible={leftControls.has('microphone')}
          onClick={audioMuteHandler}
        />
        <ButtonVideo
          disabled={!memberJoined}
          isActive={!videoMuted}
          isVisible={leftControls.has('video')}
          onClick={videoMuteHandler}
        />
      </div>
      <LinkLeave
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full"
        to={redirect}
      >
        Leave
      </LinkLeave>
      <div
        aria-label="Additional call controls"
        className="flex w-full shrink-0 items-center justify-start gap-x-2"
        role="group"
      >
        <ButtonVideo
          disabled={!memberJoined}
          isActive={!videoMuted}
          isVisible={rightControls.has('video')}
          onClick={videoMuteHandler}
        />
        <ButtonRoomControl
          disabled={!memberJoined}
          isActive={handRaised}
          isVisible={rightControls.has('raisehand')}
          onClick={handRaiseHandler}
          tag="back-hand"
        >
          Raise Hand
        </ButtonRoomControl>
        <ButtonRoomControl
          disabled={!memberJoined}
          isActive={sharingScreen}
          isVisible={rightControls.has('screenshare')}
          onClick={screenShareHandler}
          tag="screen-share"
        >
          Share Screen
        </ButtonRoomControl>
        <ButtonRoomControl
          disabled
          isVisible={rightControls.has('dnd')}
          tag="block"
        >
          DND
        </ButtonRoomControl>
      </div>
    </div>
  )
}
