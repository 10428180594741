/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as CallbackImport } from './routes/callback'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth.index'
import { Route as AuthRoomsImport } from './routes/_auth.rooms'
import { Route as AuthRecentImport } from './routes/_auth.recent'
import { Route as AuthDirectoryImport } from './routes/_auth.directory'
import { Route as AuthChatsImport } from './routes/_auth.chats'
import { Route as AuthCallsImport } from './routes/_auth.calls'
import { Route as AuthRoomContextNameImport } from './routes/_auth.room.$context.$name'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const CallbackRoute = CallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRoomsRoute = AuthRoomsImport.update({
  id: '/rooms',
  path: '/rooms',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRecentRoute = AuthRecentImport.update({
  id: '/recent',
  path: '/recent',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDirectoryRoute = AuthDirectoryImport.update({
  id: '/directory',
  path: '/directory',
  getParentRoute: () => AuthRoute,
} as any)

const AuthChatsRoute = AuthChatsImport.update({
  id: '/chats',
  path: '/chats',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCallsRoute = AuthCallsImport.update({
  id: '/calls',
  path: '/calls',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRoomContextNameRoute = AuthRoomContextNameImport.update({
  id: '/room/$context/$name',
  path: '/room/$context/$name',
  getParentRoute: () => AuthRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/calls': {
      id: '/_auth/calls'
      path: '/calls'
      fullPath: '/calls'
      preLoaderRoute: typeof AuthCallsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/chats': {
      id: '/_auth/chats'
      path: '/chats'
      fullPath: '/chats'
      preLoaderRoute: typeof AuthChatsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/directory': {
      id: '/_auth/directory'
      path: '/directory'
      fullPath: '/directory'
      preLoaderRoute: typeof AuthDirectoryImport
      parentRoute: typeof AuthImport
    }
    '/_auth/recent': {
      id: '/_auth/recent'
      path: '/recent'
      fullPath: '/recent'
      preLoaderRoute: typeof AuthRecentImport
      parentRoute: typeof AuthImport
    }
    '/_auth/rooms': {
      id: '/_auth/rooms'
      path: '/rooms'
      fullPath: '/rooms'
      preLoaderRoute: typeof AuthRoomsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/room/$context/$name': {
      id: '/_auth/room/$context/$name'
      path: '/room/$context/$name'
      fullPath: '/room/$context/$name'
      preLoaderRoute: typeof AuthRoomContextNameImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthCallsRoute: typeof AuthCallsRoute
  AuthChatsRoute: typeof AuthChatsRoute
  AuthDirectoryRoute: typeof AuthDirectoryRoute
  AuthRecentRoute: typeof AuthRecentRoute
  AuthRoomsRoute: typeof AuthRoomsRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthRoomContextNameRoute: typeof AuthRoomContextNameRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthCallsRoute: AuthCallsRoute,
  AuthChatsRoute: AuthChatsRoute,
  AuthDirectoryRoute: AuthDirectoryRoute,
  AuthRecentRoute: AuthRecentRoute,
  AuthRoomsRoute: AuthRoomsRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthRoomContextNameRoute: AuthRoomContextNameRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/callback': typeof CallbackRoute
  '/login': typeof LoginRoute
  '/calls': typeof AuthCallsRoute
  '/chats': typeof AuthChatsRoute
  '/directory': typeof AuthDirectoryRoute
  '/recent': typeof AuthRecentRoute
  '/rooms': typeof AuthRoomsRoute
  '/': typeof AuthIndexRoute
  '/room/$context/$name': typeof AuthRoomContextNameRoute
}

export interface FileRoutesByTo {
  '/callback': typeof CallbackRoute
  '/login': typeof LoginRoute
  '/calls': typeof AuthCallsRoute
  '/chats': typeof AuthChatsRoute
  '/directory': typeof AuthDirectoryRoute
  '/recent': typeof AuthRecentRoute
  '/rooms': typeof AuthRoomsRoute
  '/': typeof AuthIndexRoute
  '/room/$context/$name': typeof AuthRoomContextNameRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/callback': typeof CallbackRoute
  '/login': typeof LoginRoute
  '/_auth/calls': typeof AuthCallsRoute
  '/_auth/chats': typeof AuthChatsRoute
  '/_auth/directory': typeof AuthDirectoryRoute
  '/_auth/recent': typeof AuthRecentRoute
  '/_auth/rooms': typeof AuthRoomsRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/room/$context/$name': typeof AuthRoomContextNameRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/callback'
    | '/login'
    | '/calls'
    | '/chats'
    | '/directory'
    | '/recent'
    | '/rooms'
    | '/'
    | '/room/$context/$name'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/callback'
    | '/login'
    | '/calls'
    | '/chats'
    | '/directory'
    | '/recent'
    | '/rooms'
    | '/'
    | '/room/$context/$name'
  id:
    | '__root__'
    | '/_auth'
    | '/callback'
    | '/login'
    | '/_auth/calls'
    | '/_auth/chats'
    | '/_auth/directory'
    | '/_auth/recent'
    | '/_auth/rooms'
    | '/_auth/'
    | '/_auth/room/$context/$name'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  CallbackRoute: typeof CallbackRoute
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  CallbackRoute: CallbackRoute,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/callback",
        "/login"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/calls",
        "/_auth/chats",
        "/_auth/directory",
        "/_auth/recent",
        "/_auth/rooms",
        "/_auth/",
        "/_auth/room/$context/$name"
      ]
    },
    "/callback": {
      "filePath": "callback.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/calls": {
      "filePath": "_auth.calls.tsx",
      "parent": "/_auth"
    },
    "/_auth/chats": {
      "filePath": "_auth.chats.tsx",
      "parent": "/_auth"
    },
    "/_auth/directory": {
      "filePath": "_auth.directory.tsx",
      "parent": "/_auth"
    },
    "/_auth/recent": {
      "filePath": "_auth.recent.tsx",
      "parent": "/_auth"
    },
    "/_auth/rooms": {
      "filePath": "_auth.rooms.tsx",
      "parent": "/_auth"
    },
    "/_auth/": {
      "filePath": "_auth.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/room/$context/$name": {
      "filePath": "_auth.room.$context.$name.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
