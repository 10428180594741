import { Feature } from '@/components/Feature'
import { LoadingWrapper } from '@/components/LoadingWrapper'
import { PanelRoom } from '@/components/PanelRoom'
import { RoomFooter } from '@/components/RoomFooter'
import { RoomHeader } from '@/components/RoomHeader'
import { INCOMING_CALL_CONTEXT } from '@/helpers/constants'
import { Route } from '@/routes/_auth.room.$context.$name'
import { useCallStore } from '@/stores/call'
import { FEATURES } from '@/stores/features'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUiStoreActions } from '@/stores/ui'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { useRouter } from '@tanstack/react-router'
import { useCallback, useEffect, useRef } from 'react'
import { MemberOverlays } from '../MemberOverlays'

export const RoomView = () => {
  const { context, name } = Route.useParams()
  const { channel, redirect } = Route.useSearch()
  const router = useRouter()
  const memberState = useRoomStore(state => state.memberState)
  const initialized = useRef(false)
  const rootMcuRef = useRef<HTMLDivElement>(null)
  const roomViewRef = useRef<HTMLDivElement>(null)
  const { join, startSession } = useRoomStoreActions()
  const { hideErrorDialog, setErrorDialogContent, showErrorDialog } =
    useUiStoreActions()
  const currentNotification = useCallStore(state => state.currentNotification)
  const {
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
  } = useUserSettingsStoreActions()

  const handleErrorDialog = useCallback(() => {
    const closeAndNavigate = () => {
      hideErrorDialog()
      router?.navigate({ to: redirect || '/recent' }).catch(console.error)
    }

    // set the error dialog content
    setErrorDialogContent({
      confirmLabel: 'OK',
      description:
        'There was a problem when joining the room. Please try again.',
      onConfirm: closeAndNavigate,
      title: 'Error',
    })

    // show the error dialog
    showErrorDialog()
  }, [
    setErrorDialogContent,
    hideErrorDialog,
    showErrorDialog,
    router,
    redirect,
  ])

  useEffect(() => {
    if (initialized.current) return
    if (!rootMcuRef.current) return

    if (!context || !name) {
      console.error('Error: A parameter was missing when entering the Room')
      handleErrorDialog()
      return
    }

    if (context !== INCOMING_CALL_CONTEXT) {
      void join({
        channel: channel,
        context: context,
        name: name,
        redirect: redirect,
        rootElement: rootMcuRef.current,
      })
        .then(res => {
          console.log('XXXX: join res', res)
        })
        .catch(error => {
          handleErrorDialog()
          console.log('XXXX: join error', error)
        })
    } else {
      currentNotification?.invite
        .accept({
          audio: getPreferredMicrophoneDeviceForDialing(),
          rootElement: rootMcuRef.current,
          video: getPreferredVideoDeviceForDialing(),
        })
        .then(roomSession => {
          console.log('XXXX: accepted res', roomSession)
          startSession(roomSession).then(console.log).catch(console.log)
        })
        .catch(error => {
          handleErrorDialog()
          console.log('XXXX: accept error', error)
        })
    }

    initialized.current = true
  }, [
    handleErrorDialog,
    join,
    startSession,
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
    context,
    name,
    channel,
    redirect,
    currentNotification,
  ])

  if (!context?.trim() || !name?.trim()) {
    // TODO: error message displayed in video area?
    return null
  }

  // TODO: move panel into root if/when possible
  return (
    <>
      <Feature name={FEATURES.ROOM_LOADING_UI}>
        <LoadingWrapper
          className="rounded-xl border border-border/30 drop-shadow-md"
          loading={memberState !== 'joined'}
          loadingLabel={
            <div className="flex flex-col gap-y-1 text-center text-2xl text-secondary">
              <h2 className="font-sans text-2xl text-foreground">Joining...</h2>
              <h3 className="text-sm text-secondary">
                {context}/{name}
              </h3>
            </div>
          }
        />
      </Feature>
      <PanelRoom containerElement={roomViewRef.current} />
      <div className="flex h-svh w-svw flex-col bg-black" ref={roomViewRef}>
        <div className="h-room-header w-full shrink-0 bg-primary px-9">
          <RoomHeader />
        </div>
        <div className="h-room-video w-full overflow-hidden">
          <MemberOverlays />
          <div ref={rootMcuRef} />
        </div>
        <div className="z-10 flex h-room-footer w-full shrink-0 items-center justify-center bg-primary">
          <RoomFooter />
        </div>
      </div>
    </>
  )
}
