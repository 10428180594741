import { cn, formatTimerFromSeconds } from '@/helpers/utils'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useEffect } from 'react'

interface TimerProps {
  className?: string
  startTimer: boolean
}

export const RoomSessionTimer = ({ className, startTimer }: TimerProps) => {
  const elapsedTimeSecs = useRoomStore(state => state.elapsedTimeSecs)
  const { incrementElapsedTime } = useRoomStoreActions()

  // start the timer
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (startTimer) {
      interval = setInterval(() => {
        incrementElapsedTime()
      }, 1000)
    }

    // cleanup
    return () => {
      clearInterval(interval)
    }
  }, [incrementElapsedTime, startTimer])

  return (
    <div
      role="timer"
      className={cn('text-2xl font-semibold text-background', className)}
    >
      {formatTimerFromSeconds(elapsedTimeSecs)}
    </div>
  )
}
