import type { RecursiveSnakeToCamelCase } from '@/helpers/types'
import { createHttpClient, type CreateHttpClientParams } from './httpClient'
import { ENV_VARS } from '@/helpers/constants'
import { logger } from '@/logger/createLogger'

export interface HttpService {
  postAccessToken(
    params: PostAccessTokenParams,
  ): Promise<PostAccessTokenResponse>
  postRefreshToken(
    params: PostRefreshTokenParams,
  ): Promise<PostRefreshTokenResponse>
}

export type AuthorizedHttpService = AuthorizedMethodsToBody & {
  setAccessToken: (accessToken: string | undefined) => void
}
interface AuthorizedHttpServiceMethods {
  getUserInfo: () => Promise<{ body: GetUserInfoResponse; status: number }>
}

// converts the methods to return the body type is i.e getUserInfo: () => Promise<GetUserInfoResponse>
type AuthorizedMethodsToBody = {
  [K in keyof AuthorizedHttpServiceMethods]: AuthorizedHttpServiceMethods[K] extends (
    ...args: unknown[]
  ) => Promise<{ body: infer TBody; status: number }>
    ? (...args: Parameters<AuthorizedHttpServiceMethods[K]>) => Promise<TBody>
    : never
}

interface PostAccessTokenParams {
  client_id: string
  code: string // authorization_code from redirect if grant_type is 'authorization_code'
  code_verifier: string
  grant_type: 'authorization_code'
  redirect_uri: string
}

interface PostAccessTokenResponse {
  access_token: string
  created_at: number
  expires_in: number
  refresh_token: string
  scope: 'read write' | 'read' | 'write'
  token_type: 'Bearer'
}

export type PostAccessTokenResponseCamelCase =
  RecursiveSnakeToCamelCase<PostAccessTokenResponse>

// TODO: for step 1 of the OAuth flow
// interface GetOAuthParams {
//   client_id: string
//   code_challenge: string
//   code_challenge_method: 'S256'
//   redirect_uri: string
//   response_type: 'code'
// }

interface PostRefreshTokenParams {
  client_id: string
  grant_type: 'refresh_token'
  refresh_token: string
}

type PostRefreshTokenResponse = PostAccessTokenResponse

// TODO is this needed or just for demonstration of using the access token?
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type GetUserInfoResponse = {
  app_settings: {
    display_name: string | null
    scopes: ['read', 'write'] | ['read'] | ['write']
  }
  country: string | null
  display_name: string | null
  email: string
  first_name: string | null
  id: string
  job_title: string | null
  last_name: string | null
  region: string | null
  time_zone: string | null
}

interface ParamURLs {
  getUserInfoURL: string
  postAccessTokenURL: string
}

type HttpServiceParams = {
  [K in keyof ParamURLs]?: ParamURLs[K]
}

type CreateHttpServiceParams = CreateHttpClientParams & HttpServiceParams

export const createHttpService = (params: CreateHttpServiceParams) => {
  const httpClient = createHttpClient(params)

  const service: HttpService = {
    postAccessToken: async (postTokenParams: PostAccessTokenParams) => {
      if (!params.postAccessTokenURL) {
        throw new Error('getAccessTokenURL is not defined')
      }
      const response = await httpClient<PostAccessTokenResponse>(
        params.postAccessTokenURL,
        {
          method: 'POST',
          searchParams: { ...postTokenParams },
        },
      )
      logger.info('getAccessToken', response.body)
      return response.body
    },
    postRefreshToken: async (
      postRefreshTokenParams: PostRefreshTokenParams,
    ) => {
      if (!params.postAccessTokenURL) {
        throw new Error('getAccessTokenURL is not defined')
      }
      const response = await httpClient<PostRefreshTokenResponse>(
        params.postAccessTokenURL,
        {
          method: 'POST',
          searchParams: { ...postRefreshTokenParams },
        },
      )
      logger.info('getRefreshToken', response.body)
      return response.body
    },
  }

  return service
}

export const httpService = createHttpService({
  postAccessTokenURL: ENV_VARS.VITE_OAUTH_TOKEN_URI,
})

type CreateAuthenticatedHttpServiceParams = CreateHttpServiceParams & {
  accessToken: string
  clientId: string
  fetchRefreshToken: () => Promise<PostAccessTokenResponseCamelCase>
  refreshToken: string
}
interface CallbackParams {
  onFailedRefresh: () => void
  onNoAccessToken: () => void
}

// TODO: will we need this to refresh the access token? Also are any API calls needed outside of SDK?
export const createAuthenticatedHttpService = (
  params: CreateAuthenticatedHttpServiceParams,
  { onFailedRefresh, onNoAccessToken }: CallbackParams,
) => {
  const stateMap = new Map<'accessToken', string | undefined>()

  const createClient = (accessToken: string | undefined) => {
    stateMap.set('accessToken', accessToken)
    return createHttpClient({
      ...params,
      headers: {
        ...params.headers,
        Authorization: `Bearer ${stateMap.get('accessToken')}`,
      },
    })
  }

  // new http client with the access token
  let httpClientWithAuth = createClient(params.accessToken)

  // update access token in the http client and reuse the same initial params
  const setAuthHeader = (accessToken: string | undefined) => {
    return createClient(accessToken)
  }

  // callback to handle unauthorized responses and refresh the access token then retry the request
  const unauthorizedHandler = async <
    TResponse extends { body: Record<string, unknown>; status: number },
  >(
    request: () => Promise<TResponse>,
    retryCount = 0,
  ): Promise<TResponse['body']> => {
    try {
      if (typeof request !== 'function') {
        throw new Error('Request must be a function')
      }
      if (!stateMap.get('accessToken')) {
        onNoAccessToken() // maybe use to redirect to login page
        throw new Error('Access token is not set')
      }

      // retry the original request
      if (retryCount > 1) {
        throw new Error('Max retries reached')
      }
      const response = await request()

      if (response.status === 401) {
        logger.error('Unauthorized')
        const refreshRes = await params.fetchRefreshToken()
        if (refreshRes.accessToken) {
          logger.info('Access token refreshed')
          // update the http client with the new access token
          httpClientWithAuth = setAuthHeader(refreshRes.accessToken)

          // retry the request with the new access token
          return unauthorizedHandler(request, retryCount + 1)
        } else {
          // maybe use to redirect to login page
          const errorRefresh = 'Error refreshing access token' as const
          onFailedRefresh()
          throw new Error(errorRefresh)
        }
      } else {
        // return the response body if the request authorized
        return response.body
      }
    } catch (error) {
      logger.error('Error handling unauthorized request', error)
      throw error
    }
  }

  const authorizedService: AuthorizedHttpServiceMethods = {
    getUserInfo: async () => {
      try {
        if (!params.getUserInfoURL) {
          throw new Error('getUserInfoURL is not defined')
        }
        const response = await httpClientWithAuth<GetUserInfoResponse>(
          params.getUserInfoURL,
          {
            method: 'GET',
          },
        )
        logger.info('getUserInfo', response.body)
        return response
      } catch (error) {
        logger.error('Error getting user info', error)
        throw error
      }
    },
  }

  // wrap the authorized service calls with the unauthorized handler
  const service = {
    getUserInfo: async () => {
      return unauthorizedHandler(() => authorizedService.getUserInfo())
    },
    setAccessToken: (accessToken: string | undefined) => {
      httpClientWithAuth = setAuthHeader(accessToken)
    },
  } satisfies AuthorizedHttpService

  return service
}
