import { z } from 'zod'
import { useResourcesStore } from '@/stores/resources'
import { useChatStore } from '@/stores/chat'
import { useRoomStore } from '@/stores/room'
import { CHANNEL_TYPE } from '@/helpers/constants'
import type { ChannelType, RedirectPath, RedirectPaths } from '@/helpers/types'
import { redirect } from '@tanstack/react-router'
import { dispatchDeviceErrorDialog } from '@/helpers/errorDialogMessages'
import { refreshDevices, mapRejectedDevicesToErrorType } from '@/helpers/utils'
import { logger } from '@/logger/createLogger'
import { useRoomSessionStore } from '@/stores/roomSession'

const ATTEMPT_COUNT_MAX = 6
const ATTEMPT_DELAY_MS = 500

const REDIRECT_PATHS = {
  BASE: '/',
  CALLS: '/calls',
  CHATS: '/chats',
  DIRECTORY: '/directory',
  RECENT: '/recent',
  ROOMS: '/rooms',
} satisfies RedirectPaths

// TODO: does zod have a way to access the default values from the schema?
export const ROOM_SEARCH_SCHEMA_DEFAULTS = {
  CHANNEL: CHANNEL_TYPE.AUDIO,
  REDIRECT: REDIRECT_PATHS.RECENT,
} as const

export const roomSearchSchema = z.object({
  channel: z
    .enum([CHANNEL_TYPE.AUDIO, CHANNEL_TYPE.VIDEO])
    .optional()
    .catch(error => {
      logger.error('Invalid channel provided', error)
      // fallback to audio channel
      return ROOM_SEARCH_SCHEMA_DEFAULTS.CHANNEL
    }),
  redirect: z
    .enum([
      REDIRECT_PATHS.CALLS,
      REDIRECT_PATHS.CHATS,
      REDIRECT_PATHS.DIRECTORY,
      REDIRECT_PATHS.RECENT,
      REDIRECT_PATHS.ROOMS,
    ])
    .optional()
    .catch(error => {
      logger.error('Invalid redirect path provided', error)
      // fallback to recent path
      return ROOM_SEARCH_SCHEMA_DEFAULTS.REDIRECT
    }),
})

let beforeLoadTimeoutId: NodeJS.Timeout

interface RoomBeforeLoadParams {
  context: string
  name: string
}
// TODO: Remove as a helper and have this function live in the route/router file(s)?
export const roomBeforeLoad = ({ context, name }: RoomBeforeLoadParams) => {
  const routeContext = {
    title: `${context}/${name}`,
  }
  let attempts = 0

  return new Promise<typeof routeContext>((resolve, reject) => {
    const waitForUnload = () => {
      const { memberState } = useRoomStore.getState()
      // Wait for previous room to clear
      if (memberState === 'ready') {
        resolve(routeContext)
      } else {
        attempts++
        if (attempts >= ATTEMPT_COUNT_MAX) {
          reject(
            new Error(
              `The last room did not clear in ${attempts * ATTEMPT_DELAY_MS}ms.`,
            ),
          )
        } else {
          // Schedule next attempt
          beforeLoadTimeoutId = setTimeout(waitForUnload, ATTEMPT_DELAY_MS)
        }
      }
    }

    waitForUnload()
  })
}

const requestDevicePermissions = async (channel: ChannelType) => {
  const audioOnly = ['microphone', 'speaker'] as const
  const video = ['camera', 'microphone', 'speaker'] as const
  const devicesToRefresh = channel === CHANNEL_TYPE.AUDIO ? audioOnly : video

  // refresh and/or request permissions for required devices
  const deviceErrors = await refreshDevices([...devicesToRefresh])

  const rejectDevices = mapRejectedDevicesToErrorType(deviceErrors)

  // TODO: handle all device types requiring permissions for better UX feedback
  const [firstErrorType] = rejectDevices

  return firstErrorType?.errorType
}

interface RoomLoaderParams {
  afterCallLeaveFn?: (() => void) | undefined
  afterCallStartFn?: (() => void) | undefined
  beforeCallLeaveFn?: (() => boolean) | undefined
  channel: ChannelType
  name: string
  redirect: RedirectPath
}
// TODO: Remove as a helper and have this function live in the route/router file(s)?
export const roomLoader = async  ({
  afterCallLeaveFn,
  afterCallStartFn,
  beforeCallLeaveFn,
  channel,
  name,
  redirect: redirectPath,
}: RoomLoaderParams) => {
  // Request or refresh device list prior to joining the room
  const deviceErrorType = await requestDevicePermissions(channel)

  // show device error dialog if needed
  if (deviceErrorType) {
    // TODO: allow the user to retry or join without required device permission
    dispatchDeviceErrorDialog({
      errorType: deviceErrorType,
    })

    // redirect via router
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw redirect({
      to: redirectPath,
    })
  }

  // Fetch the resource by the name to get room's chat conversations
  const { fetchResource } = useResourcesStore.getState().actions
  void fetchResource({ name }).then(({ name, id }) => {
    const { setAddressId, setRoomName } = useRoomStore.getState().actions
    const { listenCallLeft, listenCallStarted, allowLeaveCall} = useRoomSessionStore.getState().actions
    const { getChatMessages, subscribeToChatMessages } =
      useChatStore.getState().actions

    // Set Session Callbacks
    listenCallLeft(() => afterCallLeaveFn?.())
    listenCallStarted(() => afterCallStartFn?.())
    allowLeaveCall(() => beforeCallLeaveFn ? beforeCallLeaveFn() : true)  

    // Set the room info in the store
    setRoomName(name)
    setAddressId(id)

    // subscribe and fetch initial chat messages and page cursors (if exists)
    subscribeToChatMessages({
      addressId: id,
      onMessage: message => console.log('Received new chat message:', message),
    }).catch(error => {
      console.error('Error subscribing to chat messages:', error)
    })

    getChatMessages({ addressId: id }).catch(error => {
      console.error('Error fetching chat messages:', error)
    })
  })
}

export const roomOnLeave = () => {
  const { addressId } = useRoomStore.getState()
  const { leaveRoom } = useRoomStore.getState().actions

  if (beforeLoadTimeoutId) {
    clearTimeout(beforeLoadTimeoutId)
  }

  // Clean up the room
  void leaveRoom().then(() => {
    // Unsubscribe from chat messages
    useChatStore.getState().actions.unsubscribeChatMessages(addressId)
  })
}
