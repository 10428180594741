import { createFileRoute } from '@tanstack/react-router'
import { RoomView } from '@/components/views/RoomView'
import {
  ROOM_SEARCH_SCHEMA_DEFAULTS,
  roomBeforeLoad,
  roomLoader,
  roomOnLeave,
  roomSearchSchema,
} from '@/helpers/room'
import { CardLoading } from '@/components/CardLoading'
import { Feature } from '@/components/Feature'
import { FEATURES } from '@/stores/features'

/* eslint-disable sort-keys */
// Properties must be in order as defined by TanStack Router
// See: https://tanstack.com/router/latest/docs/eslint/create-route-property-order
export const Route = createFileRoute('/_auth/room/$context/$name')({
  // TODO: format the room title
  component: RoomView,
  loaderDeps: ({ search: { channel, redirect } }) => ({
    channel,
    redirect,
  }),
  beforeLoad: ({ params: { context, name } }) => {
    return roomBeforeLoad({ context, name })
  },
  loader: ({
    params: { name },
    deps: {
      channel = ROOM_SEARCH_SCHEMA_DEFAULTS.CHANNEL,
      redirect = ROOM_SEARCH_SCHEMA_DEFAULTS.REDIRECT,
    },
  }) => {
    // TODO: Move this function back from helpers?
    return roomLoader({ channel, name, redirect })
  },
  onLeave: _params => {
    roomOnLeave()
  },
  // show pending component instead of the root default pending component
  pendingComponent: () => (
    <Feature name={FEATURES.ROOM_LOADING_UI}>
      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-primary">
        <div className="flex w-full -translate-y-7 items-center justify-center">
          <CardLoading cardTitle="Connecting" open />
        </div>
      </div>
    </Feature>
  ),
  // minimum time to show the pending component
  pendingMinMs: 0,

  validateSearch: roomSearchSchema,
})
/* eslint-enable sort-keys */
