import { memo } from 'react'
import { RootView } from '@/components/views/RootView'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { logger } from '@/logger/createLogger'

export const Route = createFileRoute('/_auth')({
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: async ({ location }) => {
    logger.debug(`/_auth beforeLoad location: ${location.href}`)
    const { isAuthenticated } = useAuthStore.getState().actions

    // NOTE: `isAuthenticated` also updates the store with the current access token
    const authenticated = await isAuthenticated()

    logger.debug(`/_auth beforeLoad authenticated: ${authenticated}`)

    // If the user is not authenticated, redirect them to the login page
    if (!authenticated) {
      logger.debug(`/_auth beforeLoad redirecting to: '/login' route`)

      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
        to: '/login',
      })
    } else {
      const { actions, client } = useMainStore.getState()

      if (!client) {
        const { startApp } = actions
        // get the latest access token to avoid stale data in case of a re-authentication
        const { accessToken } = useAuthStore.getState()

        // TODO: handle missing access token
        if (!accessToken) {
          throw new Error('Missing access token')
        }

        logger.debug(
          `/_auth beforeLoad is authenticated accessToken: ${accessToken}`,
        )

        // TODO: retry/fail if the SignalWire client fails to start? Disconnect?
        return await startApp(accessToken).catch(error => {
          // Rethrow the error to be caught by the global handler
          throw error
        })
      }
    }
    return null
  },
  // Memoize the root view to prevent title updates
  component: memo(RootView),
})
