import { logger } from '@/logger/createLogger'
import { useAuthStore } from '@/stores/auth'
import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/login')({
  beforeLoad: async () => {
    const { isAuthenticated, startOAuthFlow } = useAuthStore.getState().actions
    const authenticated = await isAuthenticated()
    logger.debug(`/login beforeLoad authenticated: ${authenticated}`)
    
    

    // If the user is already authenticated then redirect else start the oauth flow
    if (authenticated) {
      // TODO: Redirect to the previous state or fallback to /recent
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({ to: '/recent' })
    } else {
      logger.debug(`/login beforeLoad starting OAuth: ${authenticated}`)
      await startOAuthFlow()
    }
    return { title: 'Login' }
  },
})
