import { ToastAction } from '@/components/base/toast'
import { useToast } from '@/hooks/use-toast'
import { INCOMING_CALL_CONTEXT, TOAST_TYPE } from '@/helpers/constants'
import { useNotifyCall } from '@/stores/call'
import { useRouter } from '@tanstack/react-router'
import { useEffect } from 'react'

export const useIncomingCall = () => {
  const { incomingCallDetails, rejectCall } = useNotifyCall()
  const router = useRouter()
  const { createToast } = useToast()

  // TODO: map these snake case keys
  const callerIdName = incomingCallDetails?.caller_id_name
  const callerIdNumber = incomingCallDetails?.caller_id_number
  const callId = incomingCallDetails?.callID

  useEffect(() => {
    if (callId) {
      const handleReject = () => {
        if (!rejectCall) {
          console.error('No reject function found')
          return
        }
        rejectCall().catch(error => {
          console.error('Reject error:', error)
        })
        console.log('XXXX Rejecting call:', callId)
      }

      const handleAnswer = () => {
        // TODO: should we be also answering the call here with acceptCall from useNotifyCall?
        if (callerIdName) {
          void router?.navigate({
            to: `/room/${INCOMING_CALL_CONTEXT}/${callerIdName}`,
          })
        }
      }

      const addIncomingCallToast = () => {
        const action = (
          <ToastAction onClick={handleAnswer} altText={'Answer Call'}>
            Answer
          </ToastAction>
        )
        const titleLabel = <h2>Incoming Call</h2>
        const description = (
          <p className="line-clamp-2 text-xs">
            Caller: {callerIdName} {callerIdNumber}
          </p>
        )

        // TODO: we could abstract the different toast types into a factory to avoid duplication
        const actions = createToast({
          action: action,
          description: description,
          dismissLabel: 'Reject Call',
          duration: Infinity, // FIXME: if call is no longer "answerable" we should dismiss the toast
          onDismiss: handleReject,
          titleLabel: titleLabel,
          toastType: TOAST_TYPE.INCOMING_CALL,
          type: 'background', // Background toasts are announced at the next graceful opportunity
          variant: 'default',
        })
        return actions
      }
      const { id } = addIncomingCallToast()
      console.log('XXXX incoming call toast called:', id, 'callId:', callId)
    }
  }, [callId, callerIdName, callerIdNumber, rejectCall, router, createToast])

  console.info('XXXX incoming call dialog:', incomingCallDetails)

  return incomingCallDetails
}
