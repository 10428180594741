import { cn } from '@/helpers/utils'
import { Icon } from '@/components/common/icons/Icon'

export type AudioMeterType = 'microphone' | 'speaker'

interface AudioMeterProps {
  maxValue: number
  type?: AudioMeterType
  value?: number | undefined
}

export const AudioMeter = ({
  maxValue,
  type = 'microphone',
  value = 0,
}: AudioMeterProps) => {
  if (!maxValue || maxValue <= 0) {
    return null
  }
  const activeValue = Math.min(maxValue, value)
  const meter = []
  const iconTag = type === 'microphone' ? 'mic' : 'headphones'

  for (let i = 0; i < maxValue; i++) {
    meter.push(
      <div
        className={cn(
          'h-6 w-[10px] flex-shrink-0 rounded-[20px]',
          i < activeValue ? 'bg-primary-light' : 'bg-skeleton',
        )}
        key={`${type}-${i}`}
      />,
    )
  }

  return (
    <div className="inline-flex items-center space-x-3">
      <Icon tag={iconTag} size="lg" variant="foreground" />
      {meter}
    </div>
  )
}
