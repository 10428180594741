import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonAddVideoProps = OmittedProps

const ButtonAddVideo = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonAddVideoProps) => {
  const handleSwitchToVideo = () => {
    // TODO: implement the "add/switch to video" functionality for audio-only calls
  }

  return (
    <ButtonRoomControl
      disabled={disabled}
      // TODO: update the isActive prop when switching to video?
      isActive={false}
      isVisible={isVisible}
      onClick={handleSwitchToVideo}
      tag={disabled ? 'videocam-off' : 'videocam'}
      {...props}
    >
      <span className="sr-only">Add</span>
      Video
    </ButtonRoomControl>
  )
}

export { ButtonAddVideo }
