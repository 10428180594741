import { useState } from 'react'
import { SearchButton } from '@/components/SearchButton'
import { SearchEmpty } from '@/components/SearchEmpty'
import { Button } from '@/components/base/button'
import { ScrollArea } from '@/components/base/scroll-area'
import { Icon } from '@/components/common/icons/Icon'
import { useSimpleSearch } from '@/hooks/useSimpleSearch'
import { RoomCard } from '@/components/RoomCard'
import { Feature } from '@/components/Feature'
import { FEATURES } from '@/stores/features'
import { useRoomsSorted } from '@/hooks/useRoomsSorted'
import type { SortOrder } from '@/helpers/types'
import { cn } from '@/helpers/utils'
import { SORT_ORDER } from '@/helpers/constants'
import { CircularProgressIndicator } from '@/components/CircularProgressIndicator'
import { useLoaderData } from '@tanstack/react-router'
import { logger } from '@/logger/createLogger'

export const RoomsView = () => {
  const roomsLoaderData = useLoaderData({ from: '/_auth/rooms' })

  const [sortOrder, setSortOrder] = useState<SortOrder>(SORT_ORDER.ASC)

  const isAsc = sortOrder === SORT_ORDER.ASC

  const {
    fetchSortedRooms,
    loading: sortLoading,
    roomsSorted,
  } = useRoomsSorted({
    sortedIds: roomsLoaderData,
  })

  const {
    handleSearch,
    handleSearchReset,
    searchTerm,
    searchResults: roomsToRender,
  } = useSimpleSearch({
    items: roomsSorted,
    key: 'displayName',
  })

  const handleSortOrder = () => {
    const prevSortOrder = sortOrder
    const nextSortOrder =
      prevSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC
    // Optimistically update the UI
    setSortOrder(nextSortOrder)

    fetchSortedRooms(nextSortOrder).catch(error => {
      logger.error('XXXX: error sorting rooms', error)
      // reset to previous state if error
      setSortOrder(prevSortOrder)

      // TODO: handle error with explicit error message instead of rethrow to global error handler
      throw error
    })
  }

  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <div className="flex flex-col pb-4 pt-2">
        <h2 className="px-6 text-4xl font-semibold leading-normal">Rooms</h2>
        <div className="flex items-center justify-between px-5">
          <div className="flex items-center gap-x-1">
            <h3 className="text-xl font-normal">
              Available Rooms ({roomsToRender.length})
            </h3>
            {/* TODO: add room functionality */}
            <Feature name={FEATURES.ADD_ROOM}>
              <Button
                className="ml-2 flex items-center justify-center space-x-1 pl-3"
                disabled
                variant="link"
              >
                <Icon size="xl" tag="add" />
                <span>Add Room</span>
              </Button>
            </Feature>
            <CircularProgressIndicator loading={sortLoading} size="lg" />
          </div>
          <ul
            aria-label="Room search, sort options"
            className="flex grow items-center justify-end"
          >
            <li className="grow">
              <SearchButton
                buttonLabel="Open search for rooms"
                handleSearch={handleSearch}
                handleSearchReset={handleSearchReset}
                placeholder="Find a room"
              />
            </li>
            <Feature name={FEATURES.ROOMS_SORT}>
              <li className="p-2">
                <Button
                  className="p-0.5"
                  disabled={sortLoading}
                  onClick={handleSortOrder}
                  variant="icon-animated"
                >
                  <span className="sr-only">
                    {/* the next sort state */}
                    {isAsc ? 'Sort descending' : 'Sort ascending'}
                  </span>

                  {/* FIXME: get UX/UI feedback on showing which sort order is active */}
                  <div aria-hidden="true" className="relative">
                    <div className="absolute inset-0 select-none text-center text-2xs">
                      <div
                        className={cn(
                          'pl-0.5 transition-transform duration-75 ease-linear',
                          isAsc
                            ? '-translate-y-1.5'
                            : 'translate-y-[calc(100%-1px)]',
                        )}
                      >
                        {isAsc ? (
                          <>
                            {/* show up caret */}
                            &#9650;
                          </>
                        ) : (
                          <>
                            {/* show down caret */}
                            &#9660;
                          </>
                        )}
                      </div>
                    </div>
                    <Icon size="2xl" tag="sort-by-alpha" />
                  </div>
                </Button>
              </li>
            </Feature>
            <Feature name={FEATURES.ROOMS_MORE_OPTIONS}>
              <li className="p-2">
                <Button className="p-0" variant="icon" disabled>
                  <Icon size="2xl" tag="list" />
                </Button>
              </li>
            </Feature>
          </ul>
        </div>
      </div>
      {roomsToRender.length ? (
        <ScrollArea className="w-full">
          <div className="mx-auto max-w-screen-xl">
            <ul role='list'
              aria-label="List of available rooms"
              className="grid gap-x-3 gap-y-4 px-5 pb-4 md:grid-cols-2 lg:grid-cols-3"
            >
              {roomsToRender?.map((room, index) => (
                <li role='listitem' key={room.id} className="flex flex-col">
                  <RoomCard
                    imgLoad={index > 3 ? 'lazy' : 'eager'}
                    room={room}
                  />
                </li>
              ))}
            </ul>
          </div>
        </ScrollArea>
      ) : null}
      {!roomsToRender.length && searchTerm && (
        <SearchEmpty searchTerm={searchTerm} />
      )}
    </div>
  )
}
