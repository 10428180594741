import { useEffect, useId, useMemo, useState } from 'react'
import { useDevicesStore } from '@/stores/devices'
import { Icon } from '@/components/common/icons/Icon'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { WebRTC } from '@signalwire/js'
import { Feature } from './Feature'
import { FEATURES } from '@/stores/features'
import { useUiStore } from '@/stores/ui'
import { Label } from '@/components/base/label'
import { FieldErrorMessage } from '@/components/FieldErrorMessage'
import { refreshDevices } from '@/helpers/utils'

const CHECK_DEVICE_PERMISSIONS_INTERVAL_MS = 1000

export const UserSettingsDevices = () => {
  const cameraElementId = useId()
  const microphoneElementId = useId()
  const speakerElementId = useId()

  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body

  const cameraIdDefault = useDevicesStore(state => state.cameraIdDefault)
  const cameraList = useDevicesStore(state => state.cameraList)
  const microphoneIdDefault = useDevicesStore(
    state => state.microphoneIdDefault,
  )
  const microphoneList = useDevicesStore(state => state.microphoneList)

  const speakerIdDefault = useDevicesStore(state => state.speakerIdDefault)
  const speakerList = useDevicesStore(state => state.speakerList)

  const {
    getPreferredVideoDeviceId,
    getPreferredMicrophoneDeviceId,
    getPreferredSpeakerDeviceId,
    setPreferredVideoDevice,
    setPreferredMicrophoneDevice,
    setPreferredSpeakerDevice,
  } = useUserSettingsStoreActions()
  const cameraId = getPreferredVideoDeviceId()
  const microphoneId = getPreferredMicrophoneDeviceId()
  const speakerId = getPreferredSpeakerDeviceId()
  const [isCameraPermissionGranted, setCameraPermissionGranted] =
    useState(false)
  const [isMicrophonePermissionGranted, setMicrophonePermissionGranted] =
    useState(false)

  const {
    checkCameraPermissions,
    checkMicrophonePermissions,
    supportsMediaOutput,
  } = WebRTC

  useEffect(() => {
    // local variables to keep track of permissions and prevent unnecessary refreshes
    let cameraGranted = false
    let microphoneGranted = false

    const checkAndRefreshDevices = () => {
      void checkCameraPermissions().then(isGranted => {
        if (isGranted) {
          // refresh the device list once the permission is granted
          if (!cameraGranted) {
            void refreshDevices(['camera'])
            cameraGranted = true
          }
        } else {
          cameraGranted = false
        }
        setCameraPermissionGranted(Boolean(isGranted))
      })

      void checkMicrophonePermissions().then(isGranted => {
        if (isGranted) {
          if (!microphoneGranted) {
            // refresh the device list once the permission is granted
            void refreshDevices(['microphone', 'speaker'])
            microphoneGranted = true
          }
        } else {
          microphoneGranted = false
        }
        setMicrophonePermissionGranted(Boolean(isGranted))
      })
    }

    // run immediately on mount
    checkAndRefreshDevices()

    // then run on interval to check for permission changes
    const intervalTimer = setInterval(
      checkAndRefreshDevices,
      CHECK_DEVICE_PERMISSIONS_INTERVAL_MS,
    )

    // cleanup
    return () => {
      clearInterval(intervalTimer)
    }
  }, [checkCameraPermissions, checkMicrophonePermissions])

  const cameraItems = useMemo(
    () =>
      cameraList.map(camera => ({
        disabled: false,
        label: camera.label,
        value: camera.deviceId,
      })),
    [cameraList],
  )
  const microphoneItems = useMemo(
    () =>
      microphoneList.map(microphone => ({
        disabled: false,
        label: microphone.label,
        value: microphone.deviceId,
      })),
    [microphoneList],
  )
  const speakerItems = useMemo(
    () =>
      speakerList.map(speaker => ({
        disabled: false,
        label: speaker.label,
        value: speaker.deviceId,
      })),
    [speakerList],
  )

  return (
    <Feature name={FEATURES.PROFILE_SETTINGS}>
      <ul className="px-1">
        <li>
          {isCameraPermissionGranted ? (
            <>
              <div className="flex items-center gap-x-3 py-4 pr-2">
                <Icon size="lg" tag="videocam" />
                <Label htmlFor={cameraElementId}>Camera</Label>
              </div>
              <Select
                defaultValue={cameraId || cameraIdDefault}
                onValueChange={deviceId =>
                  setPreferredVideoDevice({ deviceId })
                }
              >
                <SelectTrigger className="text-sm" id={cameraElementId}>
                  <SelectValue placeholder={'Select camera'} />
                </SelectTrigger>
                <SelectContent container={rootElementRef}>
                  {cameraItems.map(item => (
                    <SelectItem
                      className="text-sm"
                      key={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </>
          ) : (
            <FieldErrorMessage className="py-4">
              Missing camera permissions
            </FieldErrorMessage>
          )}
        </li>

        <li>
          {isMicrophonePermissionGranted ? (
            <>
              <div className="flex items-center gap-x-3 py-4 pr-2">
                <Icon size="lg" tag="mic" />
                <Label htmlFor={microphoneElementId}>Microphone</Label>
              </div>

              <Select
                defaultValue={microphoneId || microphoneIdDefault}
                onValueChange={deviceId =>
                  setPreferredMicrophoneDevice({ deviceId })
                }
              >
                <SelectTrigger className="text-sm" id={microphoneElementId}>
                  <SelectValue placeholder={'Select microphone'} />
                </SelectTrigger>
                <SelectContent container={rootElementRef}>
                  {microphoneItems.map(item => (
                    <SelectItem
                      className="text-sm"
                      key={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </>
          ) : (
            <FieldErrorMessage className="py-4">
              Missing microphone permissions
            </FieldErrorMessage>
          )}
        </li>

        <li>
          {supportsMediaOutput() ? (
            <>
              <div className="flex items-center gap-x-3 py-4 pr-6">
                <Icon tag="headphones" size="xl" />
                <Label htmlFor={speakerElementId}>Speakers</Label>
              </div>

              <Select
                defaultValue={
                  !speakerItems.length ? '' : speakerId || speakerIdDefault
                }
                onValueChange={deviceId =>
                  setPreferredSpeakerDevice({ deviceId })
                }
              >
                <SelectTrigger
                  className="text-sm"
                  id={speakerElementId}
                  disabled={!speakerItems.length}
                >
                  <SelectValue placeholder="Select speakers" />
                </SelectTrigger>
                <SelectContent container={rootElementRef}>
                  {speakerItems.map(item => (
                    <SelectItem
                      className="text-sm"
                      key={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {/* TODO: better way to handle this situation cause by mic permissions in some browsers */}
              {speakerItems.length ? null : (
                <FieldErrorMessage className="gap-x-1 py-1 text-2xs" size="sm">
                  Permissions required to select speakers
                </FieldErrorMessage>
              )}
            </>
          ) : (
            <FieldErrorMessage className="py-4">
              Missing speaker support in this browser
            </FieldErrorMessage>
          )}
        </li>
      </ul>
    </Feature>
  )
}
