import { useDevicesStore } from '@/stores/devices'
import { useRoomStore } from '@/stores/room'
import { useResourcesStore } from '@/stores/resources'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { RoomView } from '../components/views/RoomView'
import type { RedirectPaths } from '@/helpers/types'
import { CHANNEL_TYPE } from '@/helpers/constants'
import { useChatStore } from '@/stores/chat'

const REDIRECT_PATHS = {
  CALLS: '/calls',
  CHATS: '/chats',
  DIRECTORY: '/directory',
  RECENT: '/recent',
  ROOMS: '/rooms',
} satisfies RedirectPaths

const roomSearchSchema = z.object({
  channel: z
    .enum([CHANNEL_TYPE.AUDIO, CHANNEL_TYPE.VIDEO])
    .optional()
    .catch(error => {
      console.error('Invalid channel provided', error)
      // fallback to video channel
      return CHANNEL_TYPE.VIDEO
    }),
  redirect: z
    .enum([
      REDIRECT_PATHS.CALLS,
      REDIRECT_PATHS.CHATS,
      REDIRECT_PATHS.DIRECTORY,
      REDIRECT_PATHS.RECENT,
      REDIRECT_PATHS.ROOMS,
    ])
    .optional()
    .catch(error => {
      console.error('Invalid redirect path provided', error)
      // fallback to recent path
      return REDIRECT_PATHS.RECENT
    }),
})

export const Route = createFileRoute('/_auth/room/$context/$name')({
  // TODO: format the room title
  beforeLoad: ({ params: { context, name } }) => {
    console.log('XXXX: loading room')
    return {
      title: `${context}/${name}`,
    }
  },
  component: RoomView,

  // The order of the properties matter due to "type inference"
  // See: https://tanstack.com/router/v1/docs/eslint/create-route-property-order
  loaderDeps: ({ search: { channel } }) => ({ channel }),

  loader: ({ params, deps: { channel } }) => {
    const deviceActions = useDevicesStore.getState().actions

    // Refresh devices but do not refresh the camera list if the channel is 'audio'
    const devicePromises = [
      deviceActions.refreshMicrophoneList(),
      deviceActions.refreshSpeakerList(),
    ]
    if (channel !== CHANNEL_TYPE.AUDIO) {
      devicePromises.push(deviceActions.refreshCameraList())
    }
    void Promise.all(devicePromises)

    // Fetch the resource by the name to get room's chat conversations
    void useResourcesStore
      .getState()
      .actions.fetchResource({ name: params.name })
      .then(({ name, id }) => {
        const { setAddressId, setRoomName } = useRoomStore.getState().actions

        // Set the room name in the store
        setRoomName(name)
        setAddressId(id)

        // subscribe and fetch initial chat messages and page cursors (if exists)
        const { getChatMessages, subscribeToChatMessages } =
          useChatStore.getState().actions

        subscribeToChatMessages({
          addressId: id,
          onMessage: message =>
            console.log('Received new chat message:', message),
        }).catch(error => {
          console.error('Error subscribing to chat messages:', error)
        })

        getChatMessages({ addressId: id }).catch(error => {
          console.error('Error fetching chat messages:', error)
        })
      })
  },
  onLeave: () => {
    const { addressId, actions } = useRoomStore.getState()
    const { leave } = actions

    // Clean up the room and leave the session
    leave()

    // Unsubscribe from chat messages
    useChatStore.getState().actions.unsubscribeChatMessages(addressId)
  },

  // TODO: use suspense pattern for loading
  validateSearch: roomSearchSchema,
})
