import { Feature } from '@/components/Feature'
import { ENV_VARS, INCOMING_CALL_CONTEXT } from '@/helpers/constants'
import { useCallStore } from '@/stores/call'
import { FEATURES } from '@/stores/features'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { useParams, useSearch, useRouter } from '@tanstack/react-router'
import { useCallback, useEffect, useRef } from 'react'
import { MemberOverlays } from '../MemberOverlays'
import { cn } from '@/helpers/utils'
import { AudioCallControls } from '../AudioCallControls'
import { RoomSessionTimer } from '@/components/RoomSessionTimer'
import { CardLoading } from '@/components/CardLoading'
import {
  dispatchHandleRoomJoinErrorDialog,
  dispatchMissingContextErrorDialog,
} from '@/helpers/errorDialogMessages'
import { logger } from '@/logger/createLogger'
import type { ChannelType, RedirectPath } from '@/helpers/types'

interface PathParams {
  context: string
  name: string
}
interface SearchParams {
  channel?: ChannelType
  redirect: RedirectPath
}

export const RoomView = () => {
  const rootMcuRef = useRef<HTMLDivElement>(null)

  // TODO: fix typecasting caused by the C2C router not using the same path
  const { context, name } = useParams({ strict: false }) as PathParams
  const { channel, redirect } = useSearch({ strict: false }) as SearchParams

  const lastRoomName = useRef<string | null>(null)

  const currentNotification = useCallStore(state => state.currentNotification)

  const memberState = useRoomStore(state => state.memberState)
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  const { joinRoom, startRoomSession } = useRoomStoreActions()

  const {
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
  } = useUserSettingsStoreActions()

  const { navigate } = useRouter()

  const joinThisRoom = useCallback(
    async (context: string) => {
      if (rootMcuRef.current === null) {
        return
      }
      if (context === INCOMING_CALL_CONTEXT) {
        currentNotification?.invite
          .accept({
            audio: getPreferredMicrophoneDeviceForDialing(),
            rootElement: rootMcuRef.current,
            video: getPreferredVideoDeviceForDialing(),
          })
          .then(roomSession => {
            void startRoomSession(roomSession)
          })
          .catch(error => {
            dispatchHandleRoomJoinErrorDialog({
              onConfirm: () => {
                void navigate({ to: redirect || ENV_VARS.DEFAULT_NAV_ROUTE })
              },
            })
            logger.error('XXXX error joining room with reattach', error)
          })
      } else {
        await joinRoom({
          channel: channel,
          context: context,
          name: name,
          redirect: redirect,
          rootElement: rootMcuRef.current,
        }).catch(error => {
          dispatchHandleRoomJoinErrorDialog({
            onConfirm: () => {
              void navigate({ to: redirect || ENV_VARS.DEFAULT_NAV_ROUTE })
            },
          })
          logger.error('XXXX error joining room', error)
        })
      }
    },
    [
      channel,
      currentNotification,
      getPreferredMicrophoneDeviceForDialing,
      getPreferredVideoDeviceForDialing,
      joinRoom,
      name,
      redirect,
      rootMcuRef,
      startRoomSession,
      navigate,
    ],
  )

  useEffect(() => {
    // Run only once per room name so that the room is not rejoined
    const initialized = lastRoomName !== null && name === lastRoomName.current
    lastRoomName.current = name

    if (initialized || !rootMcuRef.current || memberState !== 'ready') {
      return
    }

    // TODO: the router schema should prevent this from happening and reject loading the view
    // maybe it can be handled in the beforeLoad or loader functions
    if (!context || !name) {
      console.error('Error: A parameter was missing when entering the Room')

      const missingParam = !context ? 'context' : 'name'
      dispatchMissingContextErrorDialog({
        onConfirm: () => {
          void navigate({ to: redirect || ENV_VARS.DEFAULT_NAV_ROUTE })
        },
        type: missingParam,
      })
      return
    }

    void joinThisRoom(context)
  }, [context, joinThisRoom, memberState, name, redirect, navigate])

  const handleCancel = async () => {
    // FIXME: leave and ensure the room is cleaned up and the room session is ended
  }

  // TODO: is this still necessary? or can it be removed?,
  // maybe it can be handled in the beforeLoad or loader functions
  if (!context?.trim() || !name?.trim()) {
    // TODO: error message displayed in video area?
    return null
  }

  return (
    <>
      <Feature name={FEATURES.ROOM_LOADING_UI}>
        {memberState !== 'joined' && (
          // NOTE: translate the loading card in the same position as router's pendingComponent to avoid layout shift
          <div className="absolute left-0 top-0 z-10 flex h-full w-full -translate-y-7 flex-col items-center justify-center overflow-hidden bg-primary">
            <CardLoading
              cardTitle="Connecting"
              cardDescription={`Joining ${context}/${name}`}
              // TODO: add cancel label after implementing handleCancel
              confirmLabel=""
              onConfirm={handleCancel}
              open
            />
          </div>
        )}
      </Feature>

      {/* Video Call */}
      <div
        className={cn(
          'h-full',
          (isAudioOnly || memberState !== 'joined') && 'hidden',
        )}
      >
        <MemberOverlays />
        <div ref={rootMcuRef} />
      </div>

      {/* Room Audio Call view */}
      {isAudioOnly && (
        <div className="flex h-full w-full flex-col items-center justify-center gap-y-20 bg-primary">
          <RoomSessionTimer startTimer />
          <AudioCallControls redirect={redirect} />
        </div>
      )}
    </>
  )
}
