import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonMicrophoneProps = OmittedProps

const ButtonMicrophone = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonMicrophoneProps) => {
  const audioMuted = useRoomStore(state => state.audioMuted)
  const { audioMuteHandler } = useRoomStoreActions()

  return (
    <ButtonRoomControl
      disabled={disabled}
      isVisible={isVisible}
      isActive={!audioMuted}
      onClick={audioMuteHandler}
      tag={disabled ? 'mic-off-2' : 'mic'}
      {...props}
    >
      Microphone
    </ButtonRoomControl>
  )
}

export { ButtonMicrophone }
