import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'

type ButtonVideoProps = Omit<ButtonRoomControlProps, 'tag'>

const ButtonVideo = ({ disabled, ...props }: ButtonVideoProps) => {
  return (
    <ButtonRoomControl
      disabled={disabled}
      tag={disabled ? 'videocam-off' : 'videocam'}
      {...props}
    >
      Video
    </ButtonRoomControl>
  )
}

export { ButtonVideo }
