// NOTE: recommended to import sentry first
import { sentryInit } from '@/sentry/instrument.ts'
import { globalErrorListeners } from '@/helpers/error.ts'
import { router } from '@/router.tsx'
import { App } from '@/components/App.tsx'
import { ENV_VARS, ROOT_ELEMENT_ID } from '@/helpers/constants'
import { useAuthStore } from '@/stores/auth.ts'
import { useMainStore } from '@/stores/main.ts'
import { FEATURES, isFeatureEnabled } from '@/stores/features.ts'
import { StrictMode } from 'react'
import { i18nInit } from './i18n/index.ts'
import { dispatchUnhandledErrorToast } from '@/helpers/toastMessages.ts'
import { logger } from '@/logger/createLogger.ts'
import ReactDOM from 'react-dom/client'
import './index.css'

// initial global error listeners
if (isFeatureEnabled(FEATURES.GLOBAL_ERROR_LISTENERS)) {
  const { start } = globalErrorListeners({
    onUncaughtException: dispatchUnhandledErrorToast,
    onUnhandledRejection: dispatchUnhandledErrorToast,
  })
  start()
}

if (isFeatureEnabled(FEATURES.SENTRY)) {
  // Initialize Sentry monitoring
  const { sentryTransport } = sentryInit({
    dsn: ENV_VARS.VITE_SENTRY_DSN,
    environment: ENV_VARS.VITE_MODE,
    router: router,
  })
  logger.attachTransport(logObj => sentryTransport.handleTransport(logObj))
}

i18nInit().catch(error => {
  console.error('Error initializing i18n', error)
  throw error
})

export const main = () => {
  // Save the router in the state and so we can call functions (not hooks)
  useAuthStore.setState({ router })

  // NOTE: these are parameters needed for the OAuth flow, some could be passed as params
  useAuthStore.getState().actions.init({
    authUri: ENV_VARS.VITE_OAUTH_AUTH_URI,
    clientId: ENV_VARS.VITE_OAUTH_CLIENT_ID,
    codeChallengeMethod: 'S256',
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'code',
    tokenUri: ENV_VARS.VITE_OAUTH_TOKEN_URI,
  })

  // NOTE: For the prod/staging environments, have different host endpoints
  useMainStore.getState().actions.setHost(ENV_VARS.VITE_RELAY_HOST)

  ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID)!).render(
    <StrictMode>{<App router={router} />}</StrictMode>,
  )
}
