import type { LogObj } from '@/logger/createLogger'
import type { LogLevelValue } from '@/logger/loggerConstants'
import { LOG_LEVEL_TO_VALUE_MAP } from '@/logger/loggerConstants'
import type { TransportSettings } from '@/logger/transports'
import { BaseLoggerTransport } from '@/logger/transports'
import type * as Sentry from '@sentry/react'
import type { SeverityLevel } from '@sentry/types'

// Note sentry uses - "debug" | "error" | "info" | "fatal" | "warning" | "log"
// map logger log levels to Sentry log levels
const SENTRY_LOG_LEVELS_MAP = {
  0: 'log',
  1: 'log',
  2: 'debug',
  3: 'info',
  4: 'warning',
  5: 'error',
  6: 'fatal',
} as const satisfies Record<LogLevelValue, SeverityLevel>

interface SentryTransportEntry {
  extra: {
    error?: Error
    loggerLogLevelName: string
    loggerLogLevelValue: LogLevelValue
  }
  level: SeverityLevel
}

class SentryTransport extends BaseLoggerTransport {
  constructor(
    private readonly sentry: typeof Sentry,
    config: TransportSettings = { minLogLevel: LOG_LEVEL_TO_VALUE_MAP.ERROR },
  ) {
    super(config)
  }

  private mapEntryToSentryPayload = (entry: LogObj): SentryTransportEntry => {
    const sentryLogLevel = SENTRY_LOG_LEVELS_MAP[entry._meta.logLevelId]

    // NOTE: add additional context to the sentry payload if needed
    const sentryPayload: SentryTransportEntry = {
      extra: {
        ...(entry.error && { error: entry.error }),
        loggerLogLevelName: entry._meta.logLevelName,
        loggerLogLevelValue: entry._meta.logLevelId,
      },
      level: sentryLogLevel,
    }

    return sentryPayload
  }

  public handleTransport = (entry: LogObj) => {
    const sentryPayload = this.mapEntryToSentryPayload(entry)
    // filter log levels based on minLogLevel and maxLogLevel
    if (!this.canHandleLogLevel(sentryPayload.extra.loggerLogLevelValue)) {
      return
    }

    if (entry.error) {
      // send error to Sentry
      this.sentry.captureException(entry.error, { ...sentryPayload })
    } else {
      // send message to Sentry
      this.sentry.captureMessage(entry.message, { ...sentryPayload })
    }
  }
}

export { SentryTransport }
export type { SentryTransportEntry }
