import { useResourcesStore } from '@/stores/resources'
import { createFileRoute } from '@tanstack/react-router'
import { RoomsView } from '@/components/views/RoomsView'
import { SORT_ORDER } from '@/helpers/constants'

export const Route = createFileRoute('/_auth/rooms')({
  beforeLoad: () => ({ title: 'Rooms' }),
  component: RoomsView,
  loader: () => useResourcesStore.getState().actions.fetchRooms(SORT_ORDER.ASC),
})
