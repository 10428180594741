import { LOADING_STATE } from '@/helpers/constants'
import type { LoadingState } from '@/helpers/types'
import type { Resource, SortOrder } from '@/helpers/types'
import { useResourcesStoreActions } from '@/stores/resources'
import { useCallback, useMemo, useState } from 'react'

interface UseRoomSortedParams {
  sortedIds: Resource['id'][]
}

export const useRoomsSorted = ({ sortedIds }: UseRoomSortedParams) => {
  const { fetchRooms, getResourceById } = useResourcesStoreActions()

  const [roomIdsSorted, setRoomIdsSorted] =
    useState<Resource['id'][]>(sortedIds)
  const [status, setStatus] = useState<LoadingState>(LOADING_STATE.IDLE)

  const filteredVideoRooms = useMemo(() => {
    return (
      roomIdsSorted
        ?.map(roomId => getResourceById(roomId))
        // FIXME: remove casting in TS ^5.5
        .filter(room => room?.channels.video) as Resource[]
    )
  }, [roomIdsSorted, getResourceById])

  const fetchSortedRooms = useCallback(
    async (sortOrder: SortOrder) => {
      setStatus(LOADING_STATE.LOADING)

      try {
        const roomIds = await fetchRooms(sortOrder)
        setRoomIdsSorted(roomIds)

        setStatus(LOADING_STATE.IDLE)
      } catch (error) {
        setStatus(LOADING_STATE.ERROR)
        // ensure the error is rethrown to be handled by the caller
        throw error
      }
    },
    [fetchRooms],
  )

  return {
    error: status === LOADING_STATE.ERROR,
    fetchSortedRooms: fetchSortedRooms,
    loading: status === LOADING_STATE.LOADING,
    roomsSorted: filteredVideoRooms,
  }
}
