import type { IconProps } from '@/components/common/icons/Icon'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import { forwardRef } from 'react'

type CircularProgressIndicatorProps = Omit<IconProps, 'tag'> &
  React.ComponentPropsWithoutRef<'span'> & {
    loading?: boolean
    srLabel?: string
  }

// FIXME: Better accessibility for role="progressbar" or role="alert"  (live area) is needed
const CircularProgressIndicator = forwardRef<
  React.ElementRef<'svg'>,
  CircularProgressIndicatorProps
>(({ children, className, loading, srLabel }, forwardedRef) => {
  // only show children after loading
  if (!loading) {
    return children
  }

  const spinner = (
    <>
      {srLabel && (
        <span className="sr-only" aria-live="assertive">
          {srLabel}
        </span>
      )}
      <Icon
        ref={forwardedRef}
        className={cn('animate-spin', className)}
        tag="progress-activity"
        variant="foreground"
        size="3xl"
      />
    </>
  )

  // only show spinner
  if (!children) {
    return spinner
  }

  // hide children but show spinner
  return (
    <div className="relative flex items-center justify-center">
      <span aria-hidden="true" className="invisible contents">
        {children}
      </span>
      <div className="absolute inset-0 flex items-center justify-center">
        <span>{spinner}</span>
      </div>
    </div>
  )
})

CircularProgressIndicator.displayName = 'CircularProgressIndicator'

export { CircularProgressIndicator }
export type { CircularProgressIndicatorProps }
