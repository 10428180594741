import type { LogLevelValue } from '@/logger/loggerConstants'

interface Transport {
  canHandleLogLevel?: (level: LogLevelValue) => boolean
}

interface TransportSettings {
  minLogLevel?: LogLevelValue
}

// this base class can be extended to create custom transports (abstract means it can't be instantiated)
abstract class BaseLoggerTransport implements Transport {
  protected constructor(protected readonly config: TransportSettings = {}) {}

  // filter log levels based on minLogLevel and maxLogLevel
  public canHandleLogLevel = (level: LogLevelValue) => {
    const minLogLevelValue = this.config.minLogLevel ?? 0
    const isGreaterOrEqual = level >= minLogLevelValue

    return isGreaterOrEqual
  }
}

export { BaseLoggerTransport }
export type { Transport, TransportSettings }
