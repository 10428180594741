import type {
  TooltipContentProps,
  TooltipPortalProps,
  TooltipProps,
} from '@/components/base/tooltip'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/base/tooltip'
import type { Prettify, RemovePartial } from '@/helpers/types'
import type { PropsWithChildren, ReactNode } from 'react'

// NOTE: serves as the "hover intent" delay duration
const HOVER_INTENT_MS = 700 as const

type DelayDuration = Pick<TooltipProps, 'delayDuration'>
type OnOpenChange = Pick<TooltipProps, 'onOpenChange'>
type Open = Pick<TooltipProps, 'open'>
type OnPointerDownOutside = Pick<TooltipContentProps, 'onPointerDownOutside'>
type OnEscapeKeyDown = Pick<TooltipContentProps, 'onEscapeKeyDown'>

interface CustomTooltipProps {
  withArrow?: boolean
}

interface Trigger {
  trigger: ReactNode
}
interface OnPointerLeave {
  onPointerLeave: () => void
}
interface OnPointerEnter {
  onPointerEnter: () => void
}

type BaseControlled = DelayDuration &
  RemovePartial<OnEscapeKeyDown> &
  RemovePartial<OnOpenChange> &
  RemovePartial<OnPointerDownOutside> &
  RemovePartial<Open>

export type ControlledTooltipProps = PropsWithChildren<
  BaseControlled &
    CustomTooltipProps &
    OnPointerEnter &
    OnPointerLeave &
    TooltipPortalProps &
    Trigger
>

const ControlledTooltip = ({
  children,
  container,
  delayDuration = HOVER_INTENT_MS,
  onEscapeKeyDown,
  onOpenChange,
  onPointerDownOutside,
  onPointerEnter,
  onPointerLeave,
  open,
  trigger,
  withArrow = true,
}: ControlledTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip
        delayDuration={delayDuration}
        open={open}
        onOpenChange={onOpenChange}
      >
        <TooltipTrigger
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          asChild
        >
          {trigger}
        </TooltipTrigger>
        <TooltipPortal container={container}>
          <TooltipContent
            className="min-h-6 rounded-sm bg-primary px-2 py-1 font-montserrat text-xs font-normal text-background"
            onEscapeKeyDown={onEscapeKeyDown}
            onPointerDownOutside={onPointerDownOutside}
          >
            {children}
            {withArrow && <TooltipArrow className="fill-primary" />}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

type UncontrolledTooltipProps = PropsWithChildren<
  CustomTooltipProps & Prettify<DelayDuration & Trigger>
>

const UncontrolledTooltip = ({
  children,
  delayDuration = HOVER_INTENT_MS,
  trigger,
  withArrow = true,
}: UncontrolledTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={delayDuration}>
        <TooltipTrigger asChild>{trigger}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent className="min-h-6 rounded-sm bg-primary px-2 py-1 font-montserrat text-xs font-normal text-background">
            {children}
            {withArrow && <TooltipArrow className="fill-primary" />}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

export { ControlledTooltip, UncontrolledTooltip }
