import { Button } from '@/components/base/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/base/dialog'
import { Input } from '@/components/base/input'
import { Label } from '@/components/base/label'
import { TOAST_TYPE } from '@/helpers/constants'
import { useToast } from '@/hooks/use-toast'
import { useCallStore } from '@/stores/call'
import { useUiStoreActions } from '@/stores/ui'
import { useCallback, useState } from 'react'

export const ToastDevDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [errorToastDuration, setErrorToastDuration] = useState(Infinity)

  const { hideErrorDialog, showErrorDialog, setErrorDialogContent } =
    useUiStoreActions()

  const handleErrorDialog = useCallback(() => {
    const closeAndNavigate = () => {
      hideErrorDialog()
    }

    // set the error dialog content
    setErrorDialogContent({
      confirmLabel: 'OK',
      description:
        'There was a problem when joining the room. Please try again.',
      onConfirm: closeAndNavigate,
      title: 'Error',
    })

    // show the error dialog
    showErrorDialog()
  }, [setErrorDialogContent, hideErrorDialog, showErrorDialog])

  const { createToast } = useToast()

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleCreateErrorToast = () => {
    createToast({
      description:
        'There was a problem when joining the room. Please try again.',
      duration: errorToastDuration,
      titleLabel: 'Error',
      toastType: TOAST_TYPE.ERROR,
      variant: 'destructive',
    })
  }

  const handleSimulateCall = () => {
    const fakeCall = {
      callID: 'some-call-id',
      // eslint-disable-next-line camelcase
      caller_id_name: 'John Doe',
      // eslint-disable-next-line camelcase
      caller_id_number: 'some-call-id-number',
    }

    useCallStore.setState(prev => {
      const newMap = new Map(prev.notificationsMap)
      newMap.set(fakeCall.callID, {
        invite: {
          // @ts-expect-error - we don't need to implement the full invite object
          accept: () => Promise.resolve(),
          // @ts-expect-error - we don't need to implement the full invite object
          details: {
            ...fakeCall,
          },
          reject: () => Promise.resolve(),
        },
      })

      const currentNotification = newMap.get(fakeCall.callID)

      return {
        ...prev,
        currentNotification,
        notificationsMap: newMap,
      }
    })
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger className="hover:gradient fixed bottom-10 left-2 z-60 rounded border border-border bg-foreground p-2 text-sm text-background">
        Toast Dialog
      </DialogTrigger>
      <DialogContent
        className="max-h-svh overflow-hidden sm:max-w-[557px]"
        onPointerDownOutside={e => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="text-[22px] font-normal">
            Toast Dev Tools
          </DialogTitle>
        </DialogHeader>
        {dialogOpen ? (
          <>
            <div className="flex flex-col gap-2 py-2">
              <div className="flex">
                <div className="flex flex-col items-center justify-center gap-y-2">
                  <Label>Error Toast Duration (ms)</Label>
                  <Input
                    type="number"
                    onChange={e =>
                      setErrorToastDuration(parseInt(e.target.value, 10))
                    }
                    value={errorToastDuration}
                    min={0}
                    max={Infinity}
                    step={100}
                    className="pl-4"
                  />
                </div>
              </div>

              <Button
                className=""
                onClick={handleSimulateCall}
                variant="outline"
              >
                Stimulate Call
              </Button>
              <Button
                className="text-destructive"
                onClick={handleCreateErrorToast}
                variant="outline"
              >
                Error Toast
              </Button>
              <Button onClick={handleErrorDialog} variant="gradient">
                Show Error Dialog
              </Button>
            </div>
            <DialogFooter>
              <Button
                className="m-auto max-w-[328px]"
                onClick={handleClose}
                variant="destructive"
              >
                Close Dialog
              </Button>
            </DialogFooter>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}
