import { useUiStore } from '@/stores/ui'

const GUTTER_SPACE_PX = 96 * 2

const FOOTER_BUTTON_MIN_WIDTH_PX = 94

/**
 * calculate how many buttons can fit in the footer
 * @returns the number of buttons that can fit in the footer
 */
export const useFooterButtonCapacity = () => {
  // TODO: should be replaced with the actual footer width?
  const rootElementWidth = useUiStore(state => state.rootElementWidth)

  if (!rootElementWidth) {
    throw new Error('rootElementWidth is not defined')
  }

  // Calculate total available space for all controls
  const availableSpace = rootElementWidth - GUTTER_SPACE_PX

  const maxAllowedControls = Math.floor(
    availableSpace / FOOTER_BUTTON_MIN_WIDTH_PX,
  )

  // only return positive integer values
  return maxAllowedControls < 0 ? 0 : maxAllowedControls
}
