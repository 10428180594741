import { useCapabilitiesStore } from '@/stores/capabilities'
import { useRoomStore } from '@/stores/room'

/**
 * returns whether the corresponding button should be visible and enabled
 * based on the capabilities and the current state
 * - button `visible` if one of the `capabilities` is enabled
 * - button `enabled` if the current state matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useVideoButtonState = () => {
  const videoMuted = useRoomStore(state => state.videoMuted)
  const hasVideoCapabilityOff = useCapabilitiesStore(
    state => state.hasVideoCapabilityOff,
  )
  const hasVideoCapabilityOn = useCapabilitiesStore(
    state => state.hasVideoCapabilityOn,
  )

  // button visible if one of the capabilities is enabled
  const isVideoButtonVisible = hasVideoCapabilityOn || hasVideoCapabilityOff

  // muted and can unmute
  const canUnmute = videoMuted && hasVideoCapabilityOn
  // unmuted and can mute
  const canMute = !videoMuted && hasVideoCapabilityOff

  // check if button can perform the necessary next action
  const isVideoButtonEnabled = canUnmute || canMute

  return { isVideoButtonEnabled, isVideoButtonVisible }
}
