import { useMemo, useEffect } from 'react'

// Stores
import { useResourcesStore, useResourcesStoreActions } from '@/stores/resources'
import { useUiStoreActions } from '@/stores/ui'
import { useRoomStore } from '@/stores/room'

// Hooks
import { useSimpleSearch } from '@/hooks/useSimpleSearch'

// Components
import { Search } from '@/components/Search'
import { SearchEmpty } from '@/components/SearchEmpty'
import { ScrollArea } from '@/components/base/scroll-area'
import { RoomCard } from '@/components/RoomCard'

// Types
import type { Resource } from '@/helpers/types'

export const PanelRoomNavigator = () => {
  const { setRoomPanelTitle } = useUiStoreActions()
  const { fetchResources, getResourcesFromMap } = useResourcesStoreActions()

  const roomName = useRoomStore(state => state.roomName)
  const resourcesByIdMap = useResourcesStore(state => state.resourcesByIdMap)
  const resources = getResourcesFromMap(resourcesByIdMap)

  // Get the video rooms and filter out the current room
  const rooms = resources.filter(
    room =>
      room.type === 'room' && room.channels.video && room.name !== roomName,
  )

  useEffect(() => {
    // NOTE: There is a bug, if the room is loaded without
    // viewing the RoomsView, only the loaded room
    // will show up in the list.
    // TODO: This can be modified later to auto refresh every
    // few X MS via setInterval. Ideally the search would be
    // done via API Request
    // TODO: We might be able to use the logic in RoomsView.tsx
    if (rooms.length <= 1) {
      void fetchResources()
    }
  }, [resources, rooms, fetchResources])

  const roomsSortedByDisplayName = useMemo(() => {
    return rooms
      .map(room => ({
        ...room,
        displayNameSortable: room.displayName.toLowerCase(),
      }))
      .sort((a, b) =>
        a.displayNameSortable.localeCompare(b.displayNameSortable),
      )
  }, [rooms])

  const { handleSearch, searchTerm, handleSearchReset, searchResults } =
    useSimpleSearch({
      items: roomsSortedByDisplayName,
      key: 'displayNameSortable',
    })
  const roomCount = searchTerm ? searchResults.length : rooms.length

  useEffect(() => {
    // Update the navigator panel title with the room count
    setRoomPanelTitle(`Change Rooms (${roomCount})`)
  }, [setRoomPanelTitle, roomCount])

  // TODO: Implement this when transfers are available in the SDK
  // const transferHandler = () => {
  //   console.log('transferHandler')
  // }

  return (
    <>
      <Search
        formDescription="Search for a room"
        onChange={handleSearch}
        onSubmit={handleSearch}
        onReset={handleSearchReset}
        placeholder="Find a room"
      />
      <ScrollArea className="w-full">
        <ul
          aria-label="List of available rooms"
          className="grid grid-cols-1 gap-x-3 gap-y-4"
        >
          <RenderRoomCards
            rooms={searchTerm ? searchResults : roomsSortedByDisplayName}
            searchTerm={searchTerm}
          />
        </ul>
      </ScrollArea>
    </>
  )
}

interface RenderRoomCardsProps {
  rooms: Resource[]
  searchTerm?: string
}
const RenderRoomCards = ({ rooms, searchTerm }: RenderRoomCardsProps) => {
  if (!rooms?.length) {
    return (
      <li>
        <SearchEmpty searchTerm={searchTerm} showIcon={false} />
      </li>
    )
  }
  return rooms?.map((room, index) => (
    <li key={room.id} className="flex flex-col">
      <RoomCard
        // onTransfer={transferHandler}
        imgLoad={index > 3 ? 'lazy' : 'eager'}
        room={room}
      />
    </li>
  ))
}
