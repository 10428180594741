import type { Device, DeviceErrorType, DeviceType } from '@/helpers/types'
import { useDevicesStore } from '@/stores/devices'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import {
  CURRENT_USER_COLOR,
  OTHER_USER_COLORS,
  RESIZE_DEBOUNCE_MS,
  SDK_ATTACHED_SESSION_KEYS,
} from '@/helpers/constants'

// className merge function wrapper for tailwind-merge, used by base components
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// debounce a function to be called only once every timeout
export const debounce = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFunc extends (...args: any) => any,
  TArgs extends Parameters<TFunc>,
>(
  func: TFunc,
  timeout = 250,
) => {
  let timer!: ReturnType<typeof setTimeout>

  return (...args: TArgs) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

/**
 * debounce a function to be called only once every timeout
 * - returns optional clearTimer function to cancel the debounce
 */
export const debounceWithCancel = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFunc extends (...args: any) => any,
  TArgs extends Parameters<TFunc>,
>(
  func: TFunc,
  timeout = 250,
) => {
  let timer: ReturnType<typeof setTimeout> | null = null

  const clearTimer = () => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  }

  return (...args: TArgs) => {
    clearTimer()
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)

    return clearTimer
  }
}

// throttle a function to be called only once every timeout
/* eslint-disable @typescript-eslint/no-explicit-any */
export const throttle = (func: (...args: any[]) => any, timeout = 250) => {
  let ready = true
  let retVal: unknown = null

  return (...args: any[]) => {
    if (ready) {
      retVal = func.apply(this, args)
      ready = false
      setTimeout(() => {
        ready = true
      }, timeout)
    }

    return retVal
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

// truncate a string to a given length and add ellipsis if needed
export const truncate = (text: string, length: number, ellipsis = false) => {
  const strLen = text?.length
  if (text === undefined || strLen === 0) {
    return ''
  }

  const addEllipsis = ellipsis && length > 3 && length < strLen
  const subStrLen = addEllipsis ? length - 3 : length

  if (subStrLen <= 0 || strLen <= subStrLen) {
    return text
  }
  return `${text.substring(0, subStrLen)}${addEllipsis ? '...' : ''}`
}

export const typeSafeFilterObject = <
  T extends Record<string, unknown>,
  K extends keyof T,
  V extends keyof T[keyof T],
>(
  obj: T,
  predicate: (key: K, value: V) => boolean,
) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) =>
      predicate(key as K, value as V),
    ),
  ) as Pick<T, K>
}

/** Find option in a list of options by key and value */
export const findOption = <
  U extends Record<string, unknown>[],
  T extends keyof U[number],
>(
  options: U,
  key: T extends string ? T : never,
  value: U[number][T],
): U[number] => {
  const option = options.find(option => {
    return key in option && option[key] === value
  })
  if (!option) {
    throw new Error(`Could not find option with id ${key}`)
  }
  return option
}

export const intlDateTimeFormat = (locales: string[] | string | undefined) => {
  const dtfTimeOnly = new Intl.DateTimeFormat(locales, {
    hour: 'numeric',
    minute: 'numeric',
  })
  const dtfDateOnly = new Intl.DateTimeFormat(locales, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })

  const isToday = (intlDate: string) => {
    const today = new Date()
    const intlToday = dtfDateOnly.format(today)

    return intlDate === intlToday
  }

  const getFormattedTime = (timestamp: number) => {
    const timestampDate = new Date(timestamp)

    const intlDate = dtfDateOnly.format(timestampDate)

    if (isToday(intlDate)) {
      // if today only return time ex: 12:00 PM
      return dtfTimeOnly.format(timestampDate)
    } else {
      // if not today return date ex: 12/12/2021
      return intlDate
    }
  }

  return getFormattedTime
}

// Remove empty entries and clean up label (used for display)
export const cleanDeviceList = (devices: Device[]): Device[] => {
  return devices.reduce((accList: Device[], curDevice: Device) => {
    if (curDevice.deviceId) {
      accList.push({
        deviceId: curDevice.deviceId,
        label: stripHexDeviceId(curDevice.label),
      })
    }
    return accList
  }, [])
}

// Strip hex id from device label, e.g. (04f2:b6cb)
export const stripHexDeviceId = (deviceLabel: string): string => {
  let cleanLabel = deviceLabel

  const regEx = /(.+) (\([0-9a-f]+:[0-9a-f]+\))+/
  const labelParts = regEx.exec(deviceLabel)
  if (labelParts?.[1]) {
    cleanLabel = labelParts[1]
  }

  return cleanLabel
}

export const simpleSearchAndFilter = <T extends Record<string, unknown>>({
  items,
  key,
  search,
}: {
  items: T[]
  key: keyof T
  search: string
}) => {
  const trimmedLowSearch = search.trim().toLocaleLowerCase()
  // if no search return all items
  if (!trimmedLowSearch) {
    return items
  }

  // if search return items that match search using includes and tolowercase
  return items.filter(item =>
    item[key]?.toString().toLocaleLowerCase().includes(trimmedLowSearch),
  )
}

// Is an element's content overflowing its width or is truncated?
export const isElementTruncated = (element: HTMLElement | null) => {
  return element == null ? false : element.offsetWidth < element.scrollWidth
}

// Is a textarea element's content overflowing its height?
export const isTextAreaOverflowing = (element: HTMLTextAreaElement | null) => {
  return element == null ? false : element.offsetHeight < element.scrollHeight
}

export const buildRoomPath = (
  context: string,
  name: string,
  channel?: string,
  redirect?: string,
): string => {
  let path = `/${context.trim()}/${name.trim()}`
  const queryParams = new URLSearchParams()
  if (channel) queryParams.append('channel', channel.trim())
  if (redirect) queryParams.append('redirect', redirect.trim())
  const queryParamsString = queryParams.toString()
  if (queryParamsString.length) path += `?${queryParamsString}`
  return path
}

// Get current time in seconds
export const getCurrentTimeSec = () => {
  return Math.floor(Date.now() / 1000)
}

/**
 * Sorts an array with strings, numbers, or objects with strings or numbers
 * - returns a copy of the array
 * - sorts strings alphabetically
 * - sorts numbers numerically
 */
export const sortBy = <T>(
  array: T[],
  sortOrder: 'asc' | 'desc',
  property?: T extends object ? keyof T : never,
) => {
  return array.toSorted((x, y) => {
    const xProp = property ? x[property] : x
    const yProp = property ? y[property] : y

    if (typeof xProp === 'string' && typeof yProp === 'string') {
      return sortOrder === 'asc'
        ? xProp.toLowerCase().localeCompare(yProp.toLowerCase())
        : yProp.toLowerCase().localeCompare(xProp.toLowerCase())
    }

    if (typeof xProp === 'number' && typeof yProp === 'number') {
      return sortOrder === 'asc' ? xProp - yProp : yProp - xProp
    }

    throw new Error(
      `sort fn can only sort strings & numbers, but got ${typeof xProp} and ${typeof yProp}`,
    )
  })
}

/**
 * simple dedupe for primitive arrays
 */
export const dedupePrimitiveArray = <T>(arr: T[]) => Array.from(new Set(arr))

/*
 * Scroll down to view the container's last child element
 */
export const scrollToLastChild = (
  container: HTMLElement | null,
  smooth = false,
) => {
  if (container?.children.length) {
    const lastElement = container?.lastElementChild as HTMLElement

    lastElement?.scrollIntoView({
      behavior: smooth ? 'smooth' : 'auto',
      block: 'end',
      inline: 'nearest',
    })
  }
}

/*
 * Is this container scrolled to the bottom of (within a margin)?
 */
const BOTTOM_SCROLL_MARGIN = 20
export const isScrolledToBottom = (
  container: HTMLElement | null,
  margin: number = BOTTOM_SCROLL_MARGIN,
) => {
  return container
    ? container.scrollTop >
        container.scrollHeight - container.clientHeight - margin
    : false
}

export const isString = (value: unknown): value is string =>
  typeof value === 'string'

export const detachSdkSessionState = () =>
  SDK_ATTACHED_SESSION_KEYS.forEach(key => sessionStorage.removeItem(key))

export const createUserToColor = (
  options?: Partial<{
    currentUserColor: typeof CURRENT_USER_COLOR
    otherUserColors: typeof OTHER_USER_COLORS
  }>,
) => {
  const {
    currentUserColor = CURRENT_USER_COLOR,
    otherUserColors = OTHER_USER_COLORS,
  } = options ?? {}

  return {
    getCurrentUserColor: () => {
      return currentUserColor
    },
    getUserColor: (userId: string) => {
      // Convert the UUID to a numeric value by summing the char codes
      // this should provide an pretty even distribution of colors and deterministic output
      const numericValue = userId
        .split('')
        .reduce((acc, char) => acc + char.charCodeAt(0), 0)

      const colorIndex = numericValue % otherUserColors.length

      return otherUserColors[colorIndex] as (typeof otherUserColors)[number]
    },
  }
}

export const userToColor = createUserToColor()

export const createResizeObserver = (
  elementToObserve: HTMLElement,
  onResized: (elementHeight: number, elementWidth: number) => void,
) => {
  // Set up observer to adjust dimensions on resize
  const observer = new ResizeObserver(
    debounce((entries: ResizeObserverEntry[]) => {
      entries?.forEach(entry => {
        if (
          entry.borderBoxSize?.length > 0 &&
          entry.borderBoxSize[0]?.inlineSize
        ) {
          const elementHeight = entry.borderBoxSize[0]?.blockSize ?? 0
          const elementWidth = entry.borderBoxSize[0]?.inlineSize ?? 0
          onResized(elementHeight, elementWidth)
        }
      })
    }, RESIZE_DEBOUNCE_MS),
  )

  return {
    disconnect: () => observer.disconnect(),
    start: () => observer.observe(elementToObserve),
    stop: () => observer.unobserve(elementToObserve),
  }
}

export const delay = (ms: number) =>
  new Promise<void>(resolve => setTimeout(resolve, ms))

export const promisesWithMinDelay = <T>(
  promises: Promise<T>[],
  minDelayMs: number,
) => {
  return Promise.all([...promises, delay(minDelayMs)])
}

/**
 * Capitalizes the first letter of the input string and converts the rest to lowercase.
 *
 * @param {string} str - The input string to capitalize.
 * @returns {string} - The formatted string.
 */
export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

/**
 * Converts a string to "Start Case", where each word is capitalized and separated by spaces.
 * Words can be separated by underscores, dashes, or spaces in the input string.
 *
 * @param {string} str - The input string to convert to Start Case.
 * @returns {string} - The formatted string.
 */
export const startCase = (str: string): string => {
  return str.replace(/[_-]+/g, ' ').split(/\s+/).map(capitalize).join(' ')
}

/**
 *  - pad number with zero if between 0 and 9
 * - positive integers only
 * - i.e 1 => 01, 10 => 10
 */
const paddedWithZero = (num: number) => num.toString().padStart(2, '0')

/**
 * Format a timer in seconds to HH:MM:SS or MM:SS format
 */
export const formatTimerFromSeconds = (seconds: number) => {
  const hoursElapsed = Math.floor(seconds / 3600)
  const minutesElapsed = Math.floor((seconds % 3600) / 60)
  const secondsElapsed = seconds % 60

  const paddedMinutes = paddedWithZero(minutesElapsed)
  const paddedSeconds = paddedWithZero(secondsElapsed)

  // as HH:MM:SS or MM:SS
  if (hoursElapsed > 0) {
    return `${paddedWithZero(hoursElapsed)}:${paddedMinutes}:${paddedSeconds}`
  } else {
    return `${paddedMinutes}:${paddedSeconds}`
  }
}

/**
 * Refresh devices list
 * - note does not throw errors, returns a list of rejected promises
 */
type RejectedDevice = PromiseRejectedResult & { type: DeviceType }
export const refreshDevices = async (
  devices: DeviceType[] = ['camera', 'microphone', 'speaker'],
) => {
  const { refreshCameraList, refreshMicrophoneList, refreshSpeakerList } =
    useDevicesStore.getState().actions

  // Refresh devices but do not refresh the camera list if the channel is 'audio'
  const devicePromises = []
  const promiseOrder: DeviceType[] = []

  if (devices.includes('camera')) {
    devicePromises.push(refreshCameraList())
    promiseOrder.push('camera')
  }
  if (devices.includes('microphone')) {
    devicePromises.push(refreshMicrophoneList())
    promiseOrder.push('microphone')
  }
  if (devices.includes('speaker')) {
    devicePromises.push(refreshSpeakerList())
    promiseOrder.push('speaker')
  }
  // return a list of the rejected promises by type
  const settledPromises = await Promise.allSettled(devicePromises)

  const mapToDeviceType = settledPromises.map((promise, index) => ({
    ...promise,
    type: promiseOrder[index],
  }))

  const rejectedDevices = mapToDeviceType.filter(
    ({ status }) => status === 'rejected',
  ) as RejectedDevice[]

  return rejectedDevices
}

export const mapRejectedDevicesToErrorType = (
  rejectedDevices: RejectedDevice[],
) => {
  return rejectedDevices.map(({ type, reason }) => ({
    errorType: getDevicePermissionErrorType(reason),
    type,
  }))
}

export const getDevicePermissionErrorType = (reason: unknown) => {
  let reasonAsString = ''
  let errorType: DeviceErrorType = 'unknown'

  // check if the error is a known error type
  if (reason instanceof Error) {
    reasonAsString = reason.name
  } else if (typeof reason === 'string') {
    reasonAsString = reason
  }

  const reasonLower = reasonAsString.toLowerCase()
  // errors thrown by the browser when the user denies permissions
  if (
    reasonLower.includes('notallowederror') ||
    reasonLower.includes('permissiondeniederror')
  ) {
    errorType = 'denied'
  } else if (
    // errors thrown by the browser when the device is not found
    reasonLower.includes('notfounderror') ||
    reasonLower.includes('devicesnotfounderror')
  ) {
    errorType = 'not_found'
  }

  return errorType
}
