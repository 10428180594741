// Store for resources (directory)
import { create, type StateCreator } from 'zustand'
import { useMainStore } from './main'
import type {
  GetAddressesParams,
  GetAddressResponse,
  PaginatedResult,
  ResourceType,
} from '@signalwire/js'
import type { Resource, SortBy, SortOrder } from '@/helpers/types'
import { RESOURCE_TYPE, SORT_BY, SORT_ORDER } from '@/helpers/constants'

const MAX_RESOURCES_COUNT = 500 // 10 pages

type NextPageFn = () => Promise<PaginatedResult<GetAddressResponse> | undefined>
type PrevPageFn = () => Promise<PaginatedResult<GetAddressResponse> | undefined>

type FetchResourcesParams = {
  sortBy?: SortBy
  sortOrder?: SortOrder
  type?: ResourceType
} & (
  | {
      maxResources: number
      paginate?: true // TODO: remove once pagination is implemented
    }
  | {
      maxResources?: never
      paginate?: false // TODO: remove once pagination is implemented
    }
)

interface PaginationParams {
  hasNextPage: boolean
  hasPrevPage: boolean
  nextPage: NextPageFn | undefined
  prevPage: PrevPageFn | undefined
}

interface Actions {
  actions: {
    _setPaginationCursors: (params: PaginationParams) => void
    _upsertManyResourcesInStore: (newResource: Resource[]) => void
    _upsertResourceInStore: (newResource: Resource) => void
    fetchNextResourcePage: () => Promise<void>
    fetchResource: (
      params: { id: string } | { name: string },
    ) => Promise<Resource>
    fetchResources: (params?: FetchResourcesParams) => Promise<Resource['id'][]>
    fetchRooms: (
      sortOrder?: FetchResourcesParams['sortOrder'],
    ) => Promise<Resource['id'][]>
    getResourceById: (resourceId: string) => Resource | undefined
    getResourceByName: (resourceId: string) => Resource | undefined
    getResourcesFromMap: (resourcesByIdMap: Map<string, Resource>) => Resource[]
  }
}

interface State {
  hasNextPage: boolean
  hasPrevPage: boolean
  nextPage: NextPageFn | undefined
  prevPage: PrevPageFn | undefined
  resourcesByIdMap: Map<string, Resource>
}

type Store = Actions & State

const initialState: State = {
  hasNextPage: false,
  hasPrevPage: false,
  nextPage: undefined,
  prevPage: undefined,
  resourcesByIdMap: new Map(),
}

const convertAddressToResource = (address: GetAddressResponse): Resource => {
  return {
    channels: {
      ...(address.channels.audio && {
        audio: address.channels.audio,
      }),
      ...(address.channels.messaging && {
        messaging: address.channels.messaging,
      }),
      ...(address.channels.video && { video: address.channels.video }),
    },
    createdAt: address.created_at,
    ...(address.cover_url && { coverUrl: address.cover_url }),
    displayName: address.display_name,
    id: address.id,
    locked: address.locked,
    name: address.name,
    ...(address.preview_url && { previewUrl: address.preview_url }),
    resourceId: address.resource_id,
    type: address.type,
  } satisfies Resource
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    _setPaginationCursors: ({
      hasNextPage,
      hasPrevPage,
      nextPage,
      prevPage,
    }) => {
      set({
        hasNextPage,
        hasPrevPage,
        nextPage,
        prevPage,
      })
    },
    _upsertManyResourcesInStore: newResources => {
      // update the resources by id map
      const { resourcesByIdMap } = get()
      const updatedResourcesByIdMap = new Map(resourcesByIdMap)
      newResources.forEach(newResource => {
        updatedResourcesByIdMap.set(newResource.id, newResource)
      })

      set({
        resourcesByIdMap: updatedResourcesByIdMap,
      })
    },
    _upsertResourceInStore: newResource => {
      const { _upsertManyResourcesInStore } = get().actions
      _upsertManyResourcesInStore([newResource])
    },
    fetchNextResourcePage: async () => {
      const { hasNextPage, nextPage: nextPageFn, actions } = get()
      const { _setPaginationCursors, _upsertManyResourcesInStore } = actions

      if (!nextPageFn || !hasNextPage) {
        return
      }

      try {
        const addressData = await nextPageFn()
        if (!addressData) {
          // FIXME: handle the issue where there is no data in the response
          return
        }

        const {
          data,
          hasNext,
          hasPrev,
          // eslint-disable-next-line @typescript-eslint/unbound-method
          nextPage,
          // eslint-disable-next-line @typescript-eslint/unbound-method
          prevPage,
        } = addressData

        // FIXME: should fetching resources overwrite the existing list or upsert?
        const newResources = data.map(convertAddressToResource)
        _upsertManyResourcesInStore(newResources)

        _setPaginationCursors({
          hasNextPage: hasNext,
          hasPrevPage: hasPrev,
          nextPage: hasNext ? nextPage : undefined,
          prevPage: hasPrev ? prevPage : undefined,
        })
      } catch (error) {
        console.error('Unable to fetch next page of addresses', error)
      }
    },
    fetchResource: async params => {
      const client = useMainStore.getState().client
      if (!client) {
        throw new Error('Client not initialized')
      }

      const { _upsertResourceInStore } = get().actions

      try {
        const address = await client.address.getAddress(params)
        if (!address) {
          throw new Error('Unable to fetch address with params')
        }
        const resource = convertAddressToResource(address)
        _upsertResourceInStore(resource)
        return resource
      } catch (error) {
        console.error('Unable to fetch address', error)
        throw error
      }
    },
    fetchResources: async (params = {}) => {
      const {
        maxResources = MAX_RESOURCES_COUNT,
        paginate = true,
        sortBy = SORT_BY.NAME,
        sortOrder = SORT_ORDER.ASC,
        type = undefined,
      } = params as FetchResourcesParams

      const client = useMainStore.getState().client
      if (!client) {
        throw new Error('Client not initialized')
      }

      const {
        _setPaginationCursors,
        _upsertManyResourcesInStore,
        fetchNextResourcePage,
      } = get().actions

      try {
        const addressData = await client.address.getAddresses({
          sortBy,
          sortOrder,
          type,
        } as GetAddressesParams)

        // eslint-disable-next-line @typescript-eslint/unbound-method
        const { data, hasNext, hasPrev, nextPage, prevPage } = addressData

        // FIXME: should fetching resources overwrite the existing list or upsert?
        // set({ resources: mapAddressesToResources(data) })
        const newResources = data.map(convertAddressToResource)
        _upsertManyResourcesInStore(newResources)

        _setPaginationCursors({
          hasNextPage: hasNext,
          hasPrevPage: hasPrev,
          nextPage: hasNext ? nextPage : undefined,
          prevPage: hasPrev ? prevPage : undefined,
        })

        // TODO this is a work around to load all resources in the Directory list
        if (paginate) {
          while (
            get().hasNextPage &&
            get().resourcesByIdMap.size <= maxResources
          ) {
            await fetchNextResourcePage()
          }
        }
        return data.map(address => address.id)
      } catch (error) {
        console.error('Unable to fetch addresses', error)
        throw error
      }
    },
    fetchRooms: async sortOrder => {
      try {
        const { fetchResources } = get().actions

        const idsInOrder = await fetchResources({
          type: RESOURCE_TYPE.ROOM,
          ...(sortOrder && { sortOrder }),
        })
        return idsInOrder
      } catch (error) {
        console.error('Unable to fetch rooms', error)
        throw error
      }
    },
    getResourceById: (resourceId: string) => {
      const { resourcesByIdMap } = get()
      return resourcesByIdMap.get(resourceId)
    },
    getResourceByName: (name: string) => {
      const { resourcesByIdMap, actions } = get()
      const { getResourcesFromMap } = actions
      const resources = getResourcesFromMap(resourcesByIdMap)
      return resources.find(resource => resource.name === name)
    },
    getResourcesFromMap: resourcesByIdMap => {
      return Array.from(resourcesByIdMap.values())
    },
  },
})

export const useResourcesStore = create<Store>()(stateCreatorFn)
export const useResourcesStoreActions = () =>
  useResourcesStore.getState().actions

export type { State as ResourcesStoreState }
export type { Actions as ResourcesStoreActions }
export type { Store as ResourcesStore }

// Expose the store to be used from the console
window.__resourcesStore = useResourcesStore
