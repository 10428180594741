import type { SubscriberProfile } from '@/helpers/types'
import { useMainStore } from '@/stores/main'
import type { GetSubscriberInfoResponse } from '@signalwire/js'
import { useEffect, useState } from 'react'

type Status = 'error' | 'idle' | 'loading' | 'success'

/**
 * FIXME: use the `userStore` once it's available instead of fetching the subscriber info directly
 * - add skeleton loading if needed
 * - add error handling
 * - ensure this matches the design spec
 */

const mapToSubscriberProfile = (
  subscriberProfile: GetSubscriberInfoResponse,
) => {
  // TODO: fix syntax of conditionally adding properties to satisfy TS
  return {
    ...(subscriberProfile.app_settings && {
      appSettings: {
        displayName: subscriberProfile.app_settings.display_name,
        scopes: subscriberProfile.app_settings.scopes,
      },
    }),
    ...(subscriberProfile.company_name && {
      companyName: subscriberProfile.company_name,
    }),
    ...(subscriberProfile.display_name && {
      displayName: subscriberProfile.display_name,
    }),
    fabricAddresses: subscriberProfile.fabric_addresses,
    ...(subscriberProfile.first_name && {
      firstName: subscriberProfile.first_name,
    }),
    ...(subscriberProfile.job_title && {
      jobTitle: subscriberProfile.job_title,
    }),
    ...(subscriberProfile.last_name && {
      lastName: subscriberProfile.last_name,
    }),
    email: subscriberProfile.email,
    id: subscriberProfile.id,
    pushNotificationKey: subscriberProfile.push_notification_key,
    ...(subscriberProfile.company_name && {
      companyName: subscriberProfile.company_name,
    }),
    ...(subscriberProfile.region && { region: subscriberProfile.region }),
    ...(subscriberProfile.time_zone && {
      timeZone: subscriberProfile.time_zone,
    }),
  } satisfies SubscriberProfile
}

export const UserProfile = () => {
  // TODO: use the `userStore` once it's available , do not use Partial type
  const [subscriberProfile, setSubscriberProfile] = useState<
    Partial<SubscriberProfile>
  >({})
  const [status, setStatus] = useState<Status>('idle')
  const client = useMainStore(state => state.client)

  useEffect(() => {
    if (!client) {
      return
    }

    setStatus('loading')
    client
      ?.getSubscriberInfo()
      .then(profile => {
        setSubscriberProfile(mapToSubscriberProfile(profile))
        setStatus('success')
      })
      .catch(error => {
        setStatus('error')
        console.error('Failed to get subscriber profile', error)
      })
  }, [client])

  const getDisplayName = () => {
    const { displayName, email, firstName, lastName } = subscriberProfile

    // Note: `displayName`, `firstName`, and `lastName` are not guaranteed to be present
    const displayTrimmed = displayName?.trim()
    if (displayTrimmed) {
      return displayTrimmed
    }

    const firstNameTrimmed = firstName?.trim()
    const lastNameTrimmed = lastName?.trim()
    if (firstNameTrimmed || lastNameTrimmed) {
      return `${firstNameTrimmed} ${lastNameTrimmed}`.trim()
    }
    // TODO: fallback to user email
    return email?.trim()
  }

  const displayName = getDisplayName()

  return (
    <div>
      {status === 'loading' && (
        <div className="animate-pulse">
          <p className="text-sm text-skeleton">Loading subscriber</p>
        </div>
      )}
      {/* TODO: remove or move error handling */}
      {status === 'error' && (
        <p className="text-sm text-negative-light">Error fetching subscriber</p>
      )}
      {status === 'success' && (
        <p className="line-clamp-2 text-sm">{displayName}</p>
      )}
    </div>
  )
}
