import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonDnDProps = OmittedProps
const ButtonDnD = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonDnDProps) => {
  //TODO: add DND state and handler once implemented

  return (
    <ButtonRoomControl
      // TODO: update isActive state once implemented
      isActive={false}
      // TODO: once implemented remove disable by default
      disabled={disabled}
      isVisible={isVisible}
      // TODO: add disabled tag for DnD if needed
      tag={disabled ? 'block' : 'block'}
      {...props}
    >
      {/* TODO: add descriptive aria/sr-only label */}
      DND
    </ButtonRoomControl>
  )
}

export { ButtonDnD }
