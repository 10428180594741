/* eslint-disable sort-keys */
const LOG_LEVELS_DEBUG = {
  SILLY: 'SILLY',
  TRACE: 'TRACE',
  DEBUG: 'DEBUG',
  INFO: 'INFO',
} as const

const LOG_LEVELS_ERROR = {
  WARN: 'WARN',
  ERROR: 'ERROR',
  FATAL: 'FATAL',
} as const

const LOG_LEVELS = {
  ...LOG_LEVELS_ERROR,
  ...LOG_LEVELS_DEBUG,
} as const

// Note: the log levels are based on tslog's log levels
const LOG_LEVEL_TO_VALUE_MAP = {
  SILLY: 0,
  TRACE: 1,
  DEBUG: 2,
  INFO: 3,
  WARN: 4,
  ERROR: 5,
  FATAL: 6,
} as const satisfies Record<LogLevel, number>

/* eslint-enable sort-keys */

type LogLevelError = keyof typeof LOG_LEVELS_ERROR
type LogLevel = keyof typeof LOG_LEVELS
type LogLevelValue = (typeof LOG_LEVEL_TO_VALUE_MAP)[LogLevel]

export { LOG_LEVELS, LOG_LEVEL_TO_VALUE_MAP }
export type { LogLevel, LogLevelError, LogLevelValue }
