import { CHANNEL_TYPE } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import type { ChannelType } from '@/helpers/types'
import { Link } from '@tanstack/react-router'
import type { ReactNode, PropsWithChildren } from 'react'

export interface RoomLinkProps {
  address: string
  children?: PropsWithChildren<ReactNode>
  className?: string | undefined
  disabled?: boolean
  id?: string
  redirect?: RedirectPath
}

interface ParsedRoomAddressType {
  channel?: ChannelType
  context: string
  name: string
}
const parsedRoomAddress: ParsedRoomAddressType = {
  context: '',
  name: '',
}

const parseRoomAddress = (path: string | undefined): ParsedRoomAddressType => {
  const parsedAddress = { ...parsedRoomAddress }
  if (!path) return parsedAddress

  const fullURL = new URL(`${document.location.origin}${path}`)
  // Base Path
  const splitBaseAddress = (fullURL.pathname || '').split('/')
  parsedAddress.context += splitBaseAddress[1]
  parsedAddress.name += splitBaseAddress[2]
  // Query Params
  const channelParam = fullURL.searchParams.get('channel') as ChannelType
  if (
    channelParam &&
    [CHANNEL_TYPE.AUDIO, CHANNEL_TYPE.VIDEO].includes(channelParam)
  ) {
    parsedAddress.channel = channelParam
  }

  return parsedAddress
}

export const RoomLink = ({
  address,
  children,
  className,
  disabled = false,
  id,
  redirect,
}: RoomLinkProps) => {
  const { context, name, channel } = parseRoomAddress(address)

  return (
    <Link
      id={id}
      className={className}
      to="/room/$context/$name"
      params={{
        context: context,
        name: name,
      }}
      search={{
        ...(channel && { channel }),
        ...(redirect && { redirect }),
      }}
      disabled={disabled}
    >
      {children}
    </Link>
  )
}
