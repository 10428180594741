import { useIncomingCall } from '@/hooks/useIncomingCall'
import { LayoutMain } from '@/components/LayoutMain'
import { LayoutRoom } from '@/components/LayoutRoom'
import { Sidebar } from '@/components/Sidebar'
import { useUiStoreActions } from '@/stores/ui'
import { Outlet, useMatch } from '@tanstack/react-router'
import { useEffect, useRef } from 'react'
import { ROOT_ELEMENT_ID } from '@/helpers/constants'

export const RootView = () => {
  const roomView = Boolean(
    useMatch({ from: '/_auth/room/$context/$name', shouldThrow: false }),
  )

  const {
    createRootElementResizeObserver,
    setRootElementRef,
    setViewElementRef,
  } = useUiStoreActions()

  useIncomingCall()

  const viewElementRef = useRef(null)
  useEffect(() => {
    setViewElementRef(viewElementRef)
  }, [setViewElementRef, viewElementRef])

  useEffect(() => {
    let cleanup: (() => void) | undefined = undefined
    // Create a resize observer for the main root element of app
    const rootElement = document.getElementById(ROOT_ELEMENT_ID)

    if (rootElement) {
      // Set the root element ref in the store
      setRootElementRef(rootElement)
      const { start, stop } = createRootElementResizeObserver(rootElement)

      // Start the resize observer
      start()

      cleanup = () => {
        stop()
        setRootElementRef(null)
      }
    } else {
      // TODO: Should be a fatal error if the root element is not found?
      console.error(`Element with id ${ROOT_ELEMENT_ID} not found`)
    }

    return () => {
      // clean up the resize observer when the component is unmounted
      cleanup?.()
    }
  }, [createRootElementResizeObserver, setRootElementRef])

  return (
    <>
      {roomView ? (
        <LayoutRoom>
          <Outlet />
        </LayoutRoom>
      ) : (
        <LayoutMain>
          <Sidebar />
          <div ref={viewElementRef} className="relative w-full">
            <Outlet />
          </div>
        </LayoutMain>
      )}
    </>
  )
}
