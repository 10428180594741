import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { cn } from '@/helpers/utils'

const TooltipProvider = TooltipPrimitive.Provider
type TooltipProviderProps = React.ComponentProps<typeof TooltipProvider>

const Tooltip = TooltipPrimitive.Root
type TooltipProps = React.ComponentProps<typeof Tooltip>

const TooltipTrigger = TooltipPrimitive.Trigger
type TooltipTriggerProps = React.ComponentProps<typeof TooltipTrigger>

const TooltipArrow = TooltipPrimitive.Arrow
type TooltipArrowProps = React.ComponentProps<typeof TooltipArrow>

const TooltipPortal = TooltipPrimitive.Portal
type TooltipPortalProps = React.ComponentProps<typeof TooltipPortal>

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
    TooltipPrimitive.TooltipContentProps
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName
type TooltipContentProps = React.ComponentPropsWithRef<typeof TooltipContent>

export {
  Tooltip,
  // NOTE: added TooltipArrow manually
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
}

// NOTE: added types for TooltipArrowProps, TooltipContentProps, TooltipProps, TooltipProviderProps
export type {
  TooltipArrowProps,
  TooltipContentProps,
  TooltipPortalProps,
  TooltipProps,
  TooltipProviderProps,
  TooltipTriggerProps,
}
