import { logger } from '@/logger/createLogger'

interface GlobalErrorListenerParams {
  onRejectionHandled?: (event: PromiseRejectionEvent) => void
  onUncaughtException?: (event: ErrorEvent) => void
  onUnhandledRejection?: (event: PromiseRejectionEvent) => void
}

/**
 * listen and report global errors (unhandled promise rejections, uncaught exceptions)
 */
const globalErrorListeners = ({
  onRejectionHandled,
  onUncaughtException,
  onUnhandledRejection,
}: GlobalErrorListenerParams) => {
  // listen for promise rejections that are handled after the fact
  const rejectionHandledHandler = (event: PromiseRejectionEvent) => {
    logger.debug({
      context: { event },
      message: 'rejectionHandled',
    })
    onRejectionHandled?.(event)
  }

  // listen for unhandled promise rejections
  const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
    logger.error({
      context: { event },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      errorReason: event.reason,
      message: 'unhandledRejection',
    })
    onUnhandledRejection?.(event)
  }

  // listen for uncaught exceptions
  const uncaughtExceptionHandler = (event: ErrorEvent) => {
    logger.error({
      context: { event },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: event.error,
      message: 'uncaughtException',
    })
    onUncaughtException?.(event)
  }

  return {
    end: () => {
      window.removeEventListener('rejectionhandled', rejectionHandledHandler)
      window.removeEventListener(
        'unhandledrejection',
        unhandledRejectionHandler,
      )
      window.removeEventListener('error', uncaughtExceptionHandler)
      logger.debug({
        message: 'globalErrorListeners stopped listening',
      })
    },
    start: () => {
      window.addEventListener('rejectionhandled', rejectionHandledHandler)
      window.addEventListener('unhandledrejection', unhandledRejectionHandler)
      window.addEventListener('error', uncaughtExceptionHandler)
      logger.debug({
        message: 'globalErrorListeners started listening',
      })
    },
  }
}

export { globalErrorListeners }
export type { GlobalErrorListenerParams }
