import type { PropsWithChildren } from 'react'
import { useUiStore, useUiStoreActions } from '@/stores/ui'
import { FEATURES } from '@/stores/features'
import { useRoomStore } from '@/stores/room'
import { useResourcesStoreActions } from '@/stores/resources'
import { cn } from '@/helpers/utils'
import { TEST_IDS } from '@/helpers/constants'
import type { ButtonProps } from '@/components/base/button'
import { Button } from '@/components/base/button'
import type { IconTagName } from '@/components/common/icons/Icon'
import { Icon } from '@/components/common/icons/Icon'
import { Feature } from '@/components/Feature'

export const RoomHeader = () => {
  const displayRoomPanel = useUiStore(state => state.displayRoomPanel)
  const roomPanelType = useUiStore(state => state.roomPanelType)
  const memberState = useRoomStore(state => state.memberState)

  const { setRoomPanelType, toggleRoomPanel } = useUiStoreActions()

  const memberJoined = memberState === 'joined'

  return (
    <div
      className="flex w-full items-center justify-between gap-x-8 bg-primary px-5 py-1 md:px-9"
      data-testid={TEST_IDS.ROOM_HEADER}
    >
      <RoomDisplayName />
      <div
        className="inline-flex gap-x-1 md:gap-x-2"
        data-testid={TEST_IDS.ROOM_HEADER_CONTROLS}
      >
        <HeaderButton
          disabled
          isActive={displayRoomPanel && roomPanelType === 'participants'}
          onClick={() => {
            setRoomPanelType('participants')
            toggleRoomPanel()
          }}
          tag="person-pin"
        >
          Participants
        </HeaderButton>
        <Feature name={FEATURES.ROOM_CHAT}>
          <HeaderButton
            disabled={!memberJoined}
            isActive={displayRoomPanel && roomPanelType === 'chat'}
            onClick={() => {
              setRoomPanelType('chat')
              toggleRoomPanel()
            }}
            tag="chat"
          >
            Chat
          </HeaderButton>
        </Feature>
        <HeaderButton
          disabled={!memberJoined}
          isActive={displayRoomPanel && roomPanelType === 'options'}
          onClick={() => {
            setRoomPanelType('options')
            toggleRoomPanel()
          }}
          tag="settings"
        >
          Settings
        </HeaderButton>
      </div>
    </div>
  )
}

type HeaderButtonProps = Omit<ButtonProps, 'className'> &
  PropsWithChildren<{
    isActive?: boolean
    tag: IconTagName
  }>

const HeaderButton = ({
  children,
  isActive,
  tag,
  ...props
}: HeaderButtonProps) => {
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  if (isAudioOnly && children !== 'Settings') {
    return null
  }

  // CSS delay adds hover intent
  return (
    <Button
      className="group m-0 flex h-auto flex-col rounded-none px-0 py-1 text-xs font-semibold text-neutral-light"
      {...props}
    >
      <div
        className={cn(
          'rounded-full p-1 transition-colors',
          isActive
            ? 'gradient p-[8px]'
            : 'border-2 border-transparent p-[6px] delay-button group-hover:border-negative-light group-hover:bg-background',
        )}
      >
        <Icon
          className={cn(
            !isActive && 'delay-button group-hover:fill-negative-light',
          )}
          tag={tag}
          size="xl"
        />
      </div>
      <span className="sr-only">{children}</span>
    </Button>
  )
}

const RoomDisplayName = () => {
  const roomName = useRoomStore(state => state.roomName)
  const roomLocked = useRoomStore(state => state.roomLocked)
  const { getResourceByName } = useResourcesStoreActions()
  const roomResource = getResourceByName(roomName)

  if (!roomResource) {
    return null
  }

  const roomLabel =
    roomResource.displayName || roomResource.name || roomResource.id

  return (
    <div
      className="flex min-w-0 items-center gap-x-1 transition-all ease-linear md:gap-x-2"
      data-testid={TEST_IDS.ROOM_NAME}
    >
      <h2 className="truncate text-2xl font-semibold text-neutral-light">
        {roomLabel}
      </h2>
      <Icon
        size="lg"
        tag="lock"
        variant="neutral"
        className={cn('flex-shrink-0 duration-300', {
          'opacity-0 fade-out-0': !roomLocked,
          'opacity-100 fade-in-100': roomLocked,
        })}
        aria-label={roomLocked ? 'Room locked' : 'Room unlocked'}
        aria-hidden={false}
      />
    </div>
  )
}
